import React from "react";
import "./../../../styles/administration/Home.css"; // Asegúrate de importar tus estilos personalizados aquí
import Footer from "./Footer";
import slide1 from "../../../images/slides/slide1";
import slide2 from "../../../images/slides/slide2";
import slide3 from "../../../images/slides/slide3";
import Presentacion1 from "../home/Presentacion1";

export default function Home() {
	return (
		<>
			

			<Presentacion1 />
{/* 			<Footer />
 */}		</>
	);
}
