import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_configuration_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const customStyles = {
	control: (base) => ({
		...base,
		justifyContent: "Left",
		height: 60,
		minHeight: 35,
	}),
	option: (base) => ({
		...base,
		border: `1px dotted blue`,
	}),
};

const FormasPago = () => {
	const { getToken,inputStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [servicios, setServicios] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const inputServicio = useRef(null);

	const handleSubmit = (values) => {
		saveService(values);
	};
	const saveService = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_configuration_base + "/formaspago",
				json,
				config
			);
			const successMessage = "Forma de pago creada exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getServicios();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getServicios = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getAllFormas/${establishment.id}`,
			config
		);
		setServicios(result.data);
	};
	const handleServiceEdit = (serviceId) => {
		const instructorDet = servicios.find((p) => p.id === serviceId);
		if (formikRef.current) {
			formikRef.current.setValues(instructorDet);
			inputServicio.current.focus();
			inputServicio.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Eliminar forma de pago?",
			text: "La forma de pago no estará disponible!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "La forma de pago ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getServicios();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la forma de pago",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const handleActive = (servicioId) => {
		Swal.fire({
			title: "Reactivar forma de pago?",
			text: "La forma de pago  estará disponible!",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "La forma de pago ha sido reactivada",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getServicios();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la forma de pago",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		let values = {
			id: servicioId,
			active: status,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_configuration_base + "/formas/deactive",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};
	useEffect(() => {
		getServicios();
	}, []);

	const validationSchema = Yup.object().shape({
		forma: Yup.string().required("Ingrese el nombre de la forma de pago"),
	});

	const initialValues = {
		id: "",
		forma: "",
		active: true,
		idEstablishment: establishment.id,
	};
	return (
		<div className="containerFormUser">
			<h1>Formas de pago</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-12  ">
								<div className="form-floating mb-2  ">
									<Field
										style={{ ...inputStyles, textAlign: "right" }}
										innerRef={inputServicio}
										type="text"
										name="forma"
										id="forma"
										className="form-control"
										placeholder="Forma de pago"
									/>
									<label htmlFor="forma" className="form-label">
										Forma de pago
									</label>
									<ErrorMessage
										name="forma"
										component="div"
										className="error"
									/>
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
						&nbsp;
						<button type="reset" ref={btnCancel} className="btn btn-danger">
							Cancelar
						</button>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de formas</h1>
			<table
				style={{ width: "100%" }}
				id="tableUsers"
				className="table table-striped table-hover"
			>
				<thead>
					<tr>
						<th>Forma</th>
						<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
					</tr>
				</thead>
				<tbody>
					{servicios.map((servicio) => (
						<tr key={servicio.id}>
							<td>{servicio.forma}</td>
							<td style={{ width: "20%", textAlign: "right" }}>
								{servicio.active ? (
									<>
										<button
											className="btnEdit"
											type="button"
											onClick={(e) => handleServiceEdit(servicio.id)}
										>
											<i className="fas fa-pen"></i>
										</button>
										&nbsp;&nbsp;
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(servicio.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</>
								) : (
									<>
										<button
											className="btn btn-success"
											type="button"
											onClick={() => handleActive(servicio.id)}
										>
											<i class="fas fa-check"></i>
										</button>
									</>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default FormasPago;
