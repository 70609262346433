import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import {
	route_roles_getByEstablishment,
	route_roles_save,
	route_roles_delete,
} from "../../../api-routes/RoutesLogin";
import { show_alert } from "../../../functions";
import Swal from "sweetalert2";

const MyForm = () => {
	const { establishment, getToken } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [Roles, setRoles] = useState([]);
	const [btnCarga, setBtnCarga] = useState(0);

	const initialValues = {
		name: "",
		description: "",
		idEstablishment: establishment.id,
	};

	const handleSubmit = (values, actions) => {
		setBtnCarga(1);
		registrarRol(values);
	};

	const validateForm = (values) => {
		const errors = {};

		// Validación para campos requeridos
		if (!values.name) {
			errors.name = "El nombre es requerido";
		}

		if (!values.description) {
			errors.description = "La descripción es requerida";
		}

		return errors;
	};

	const loadRoles = () => {
		setLoading(true);
		axios
			.get(route_roles_getByEstablishment + establishment.id, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setRoles(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const registrarRol = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_roles_save, json, config)
			.then((response) => {
				console.log(response.data.errorMessage);
				if (!response.data) {
					show_alert("Error en registro de rol", "error");
					return false;
				} else {
					Swal.fire({
						icon: "success",
						title: "Rol creado exitosamente",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						loadRoles();
						document.getElementById("formRol").reset();
						setBtnCarga(0)
					});
					//show_alert("Usuario creado exitosamente", "success");
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const handleDelete = (userId) => {
		Swal.fire({
			title: "Eliminar Rol?",
			text: "El rol no debe estar asignado a ningun usuario!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteRol(userId)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El rol ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									loadRoles();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Error",
									text: "El rol se encuentra asociado a un usuario",
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteRol = async (userId) => {
		return axios
			.delete(route_roles_delete + userId, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			})

			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	useEffect(() => {
		loadRoles();
	  }, []);
	  

	return (
		<div className="containerFormUser">
			<h1>Roles</h1>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validateForm}
			>
				{({ isSubmitting }) => (
					<Form id="">
						<div className="row">
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										type="text"
										name="name"
										id="name"
										className="form-control"
										placeholder="Nombre"
									/>
									<label htmlFor="name" className="form-label">
										Nombre
									</label>
									<ErrorMessage name="name" component="div" className="error" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										type="text"
										name="description"
										id="description"
										className="form-control"
										placeholder="Descripcion"
									/>
									<label htmlFor="description" className="form-label">
										Descripcion
									</label>
									<ErrorMessage
										name="description"
										component="div"
										className="error"
									/>
								</div>
							</div>
						</div>
						<Field type="hidden" name="idEstablishment" />
						<>
							{btnCarga === 0 ? (
								<button type="submit" className="btn btn-primary">
									Guardar
								</button>
							) : (
								/* spinner carga */
								<button className="btn btn-primary" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										aria-hidden="true"
									></span>
									<span role="status"> Guardando...</span>
								</button>
								/*--------------- */
							)}
							&nbsp;
							<button type="reset" className="btn btn-danger">
								Cancelar
							</button>
						</>
					</Form>
				)}
			</Formik>
			<br />
			<h1>Listado de roles</h1>
			{loading == true ? (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
			) : (
				/*--------------- */
				<table id="tableUsers" className="table table-striped table-hover">
					<thead>
						<tr>
							<th style={{ width: "40%", textAlign: "center" }}>Nombre</th>
							<th style={{ width: "40%" }}>Descripción</th>
							<th>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{Roles.map((rol) => (
							<tr key={rol.id}>
								<td>{rol.name}</td>
								<td>{rol.description}</td>
								<td>
									<button
										className="btnDelete"
										type="button"
										onClick={() => handleDelete(rol.id)}
									>
										<i className="fas fa-trash-alt"></i>
									</button>{" "}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default MyForm;
