import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { GrOrderedList } from "react-icons/gr";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";

export default function ConfPresupuesto() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoContabilidad");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="crear-puc">
									<li className="navInfoAdmin">
										<GrOrderedList /> <span>P.U.C</span>
									</li>
								</NavLink>
								
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
