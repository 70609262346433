import React, { useState } from "react";
import logo from "../../../images/logoCaf.jpeg";
import {
	PDFViewer,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from "@react-pdf/renderer";

function SuscripcionPdf({ establishment, cabecera, plan, estructura }) {
	//const [naturaleza, setNaturaleza] = useState(null);
	const show = (row, rowIndex) => {
		let rowAnterior = null;
		{
			rowIndex == 0 ? (rowAnterior = row) : (rowAnterior = plan[rowIndex - 1]);
		}

		return row.naturaleza != rowAnterior.naturaleza;
	};
	const styles = StyleSheet.create({
		page: {
			padding: 20,
		},
		title: {
			marginTop: 10,
			fontSize: 24,
			marginBottom: 20,
			textAlign: "center",
		},
		tableContainer: {
			fontSize: 9,
			marginBottom: 20,
			overflow: "hidden", // Evita que los elementos desborden el contenedor
		},
		tableRow: {
			display: "flex",
			flexDirection: "row",
		},
		tableEncab: {
			display: "flex",
			flexDirection: "row",
			marginTop: "50px",
		},
		tableTitle: {
			flex: 1,
			padding: 5,
			backgroundColor: "#F5F5F5", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
			fontWeight: "bold",
			//minWidth: "15%",
		},
		tableTitleCenter: {
			flex: 1,
			padding: 5,
			backgroundColor: "#F5F5F5", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
			fontWeight: "bold",
			textAlign: "center",
		},
		tableCell: {
			flex: 1,
			padding: 5,
			//backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		tableCellNumber: {
			flex: 1,
			textAlign: "right",
			padding: 5,
			//backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		cabecera: {
			fontSize: 20,
			fontWeight: "bold",
			textAlign: "center",
			marginBottom: "10px",
			marginTop: "10px",
		},
		footer: {
			position: "absolute",
			bottom: -5,
			left: 0,
			right: 0,
			textAlign: "left",
			fontSize: 10,
			paddingTop: 10,
			paddingBottom: 10,
		},
		headerContainer: {
			flexDirection: "row",
			alignItems: "center",
		},
		logo: {
			width: 50,
			height: 50,
			marginRight: 10,
		},
		headerTextContainer: {
			flex: 1,
			marginBottom: 10,
		},
		title: {
			textAlign: "center",
			fontSize: 12,
			marginBottom: 5,
		},
		header: {
			textAlign: "center",
			fontSize: 9,
		},
	});

	return (
		<PDFViewer
			style={{ width: "100%", height: "100vh" }}
			className="pdf-viewer"
		>
			<Document>
				<Page size="A4" style={styles.page}>
					<View>
						{/* Cabecera */}
						<View style={styles.headerContainer}>
							<Image
								style={styles.logo}
								//src={`data:image/png;base64,${establishment.image}`}
								src={
									establishment && establishment.image
										? establishment.image
										: "data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJQAxgMBIgACEQEDEQH/xAAWAAEBAQAAAAAAAAAAAAAAAAAAAQf/xAAVEAEBAAAAAAAAAAAAAAAAAAAAAf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASKAAAAAAAAAAAAAAAAAAigAAAAAACRQAAAAAAAAAAAAAAAAAAAAAAAASKAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP//Z"
								}
							/>
							<View style={styles.headerTextContainer}>
								<Text style={styles.title}>{establishment.name}</Text>
								<Text style={styles.header}>
									{establishment.address}-{establishment.phone}
								</Text>
								<Text style={styles.header}>{establishment.email}</Text>
							</View>
						</View>
						{/* inicio */}
						<Text style={styles.title}>Proyección de plan presupuestal</Text>
						<Text style={styles.title}>{cabecera.nombre}</Text>
						{/* Tabla */}
						<View style={styles.tableContainer}>
							{/* seccion de cabecera */}

							<View id="Encab" style={styles.tableRow}>
								{/* Cabecera */}
								<View style={styles.tableTitle}>
									<Text>Código</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Nombre</Text>
								</View>
								
								<View style={styles.tableTitle}>
									<Text>Apropiación</Text>
								</View>
							</View>

							{/* Listado de cuentas */}
							{plan.map((row, rowIndex) => {
								const encab = show(row, rowIndex);
								return (
									<>
										{encab  ? (
											<View id="Encab" style={styles.tableEncab}>
												{/* Cabecera */}
												<View style={styles.tableTitle}>
													<Text>Código {row.codigo} </Text>
												</View>
												<View style={styles.tableTitle}>
													<Text>Nombre</Text>
												</View>
												
												<View style={styles.tableTitle}>
													<Text>Apropiación</Text>
												</View>
											</View>
										) : null}

										<View
											style={{
												display: "flex",
												flexDirection: "row",
												backgroundColor:
													row.tipo === 1 &&
													estructura[row.codigo.split(".").length - 1]
														? estructura[row.codigo.split(".").length - 1].color
														: "white",
												opacity: row.tipo === 1 ? 0.8 : 1,

												textDecoration: row.tipo === 1 ? "underline" : "",
											}}
											key={rowIndex}
										>
											<View style={styles.tableCell} key={rowIndex}>
												<Text style={{}}>{row.codigo}</Text>
											</View>
											<View style={styles.tableCell} key={rowIndex}>
												<Text>{row.nombre}</Text>
											</View>
											
											<View style={styles.tableCellNumber} key={rowIndex}>
												<Text>{row.apropiacion.toLocaleString()}</Text>
											</View>
										</View>
									</>
								);
							})}
						</View>
						<View style={styles.footer}>
							<Text>{`Fecha y hora de expedición: ${new Date().toLocaleString()}`}</Text>
						</View>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
}

export default SuscripcionPdf;
