import React, { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../context/LogContext";
import "../../styles/administration/Dashboard.css";
import { route_gym_suscripciones } from "../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";

export default function DashboardAdmin() {
	const { establishment, getToken, FechaYMD } = useContext(AuthContext);
	const [info, setInfo] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const chartRef1 = useRef(null); // Referencia para el primer gráfico
	const chartRef2 = useRef(null); // Referencia para el segundo gráfico
	const [suscripcionesV, setSuscripcionesV] = useState(null);

	useEffect(() => {
		verificarVigencias(establishment.id);
		loadInfo();
	}, []);

	useEffect(() => {
		suscripcionesvencidas();
		if (dataLoaded) {
			// Crear el primer gráfico
			console.log(FechaYMD);
			var color1 = generateRandomColor();
			var color2 = generateRandomColor();
			var color3 = generateRandomColor();
			var color4 = generateRandomColor();
			var color5 = generateRandomColor();
			var color6 = generateRandomColor();
			var color7 = generateRandomColor();
			var color8 = generateRandomColor();
			var color9 = generateRandomColor();
			var color10 = generateRandomColor();
			var color11 = generateRandomColor();
			var color12 = generateRandomColor();
			createPieChart(
				chartRef1,
				"Estado Ingresos",
				[
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				],

				[
					info.enero,
					info.febrero,
					info.marzo,
					info.abril,
					info.mayo,
					info.junio,
					info.julio,
					info.agosto,
					info.septiembre,
					info.octubre,
					info.noviembre,
					info.diciembre,
				],
				[
					color1,
					color2,
					color3,
					color4,
					color5,
					color6,
					color7,
					color8,
					color9,
					color10,
					color11,
					color12,
				],
				"line"
			);
		}
	}, [dataLoaded && verificarVigencias]);

	const loadInfo = async () => {
		try {
			const response = await axios.get(route_gym_suscripciones + "/susxmeses", {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			});
			setInfo(response.data);
			setDataLoaded(true); // Se establece que los datos están cargados
		} catch (error) {
			console.log(error);
			// Manejo de errores
		}
	};
	const suscripcionesvencidas = async () => {
		if (FechaYMD != null) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_gym_suscripciones}/informeVencimiento/${establishment.id},${FechaYMD}`,
				config
			);
			console.log(result.data);
			setSuscripcionesV(result.data);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Seleccione una fecha",
				showConfirmButton: true,
			});
		}
	};
	function generateRandomColor() {
		// Generar un valor hexadecimal aleatorio
		const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
		return randomColor;
	}
	const verificarVigencias = async (idE) => {
		let values = {
			idEstablishment: idE,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_gym_suscripciones + "/vencidas",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			console.log(response);
			return response;
		} catch (error) {
			throw error;
		}
	};

	// Función para crear un gráfico de torta
	const createPieChart = (
		chartRef,
		title,
		labels,
		data,
		backgroundColor,
		type
	) => {
		const ctx = chartRef.current.getContext("2d");
		new Chart(ctx, {
			type: type,
			data: {
				labels: labels,
				datasets: [
					{
						data: data,
						backgroundColor: backgroundColor,
					},
				],
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: "",
					},
					title: {
						display: false,
						text: title,
					},
				},
				scales: {
					y: {
						type: "linear",
						ticks: {
							stepSize: 1, // Define el tamaño del paso como 1
							precision: 0, // Define la precisión para números enteros
						},
					},
				},
			},
		});
	};

	return (
		<div className="containerFormUser">
			<div
				className="row"
				style={{ display: "flex", justifyContent: "center" }}
			>
				<div className="col-5 containerDashboard">
					<label className="titleDash">Usuarios por mes</label>
					<div className="">
						<div className="">
							<canvas className="canvasContainer" ref={chartRef1} />
						</div>
					</div>
				</div>
				<div className="col-5 containerDashboard">
					<label className="titleDash">Vencimientos a {FechaYMD}</label>
					<div className="InfoDashboard">
						{suscripcionesV && (
							<table
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th>Usuario</th>
										<th>Vencimiento</th>
									</tr>
								</thead>
								<tbody>
									{suscripcionesV.map((usu) => (
										<tr key={usu.id}>
											<td>{usu.tercero}</td>
											<td>{usu.fechaFin}</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
