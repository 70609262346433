import React from "react";
import logo from '../../../images/logoCaf.jpeg'
//import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';
import {
	PDFViewer,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from "@react-pdf/renderer";

function SuscripcionPdf({ establishment, suscripcion }) {
	const styles = StyleSheet.create({
		page: {
			padding: 20,
		},
		title: {
			fontSize: 24,
			marginBottom: 20,
			textAlign: "center",
		},
		tableContainer: {
			fontSize: 12,
			marginBottom: 20,
			overflow: "hidden", // Evita que los elementos desborden el contenedor
		},
		tableRow: {
			display: "flex",
			flexDirection: "row",
		},
		tableTitle: {
			flex: 1,
			padding: 5,
			backgroundColor: "#F5F5F5", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
			fontWeight: "bold",
			//minWidth: "15%",
		},
		tableTitleCenter: {
			flex: 1,
			padding: 5,
			backgroundColor: "#F5F5F5", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
			fontWeight: "bold",
			textAlign: "center",
		},
		tableCell: {
			flex: 1,
			padding: 5,
			backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		tableCellNumber: {
			flex: 1,
			textAlign: "right",
			padding: 5,
			backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		cabecera: {
			fontSize: 20,
			fontWeight: "bold",
			textAlign: "center",
			marginBottom: "10px",
			marginTop: "10px",
		},
		footer: {
			position: "absolute",
			bottom: -5,
			left: 0,
			right: 0,
			textAlign: "left",
			fontSize: 10,
			paddingTop: 10,
			paddingBottom: 10,
		},
		headerContainer: {
			flexDirection: "row",
			alignItems: "center",
		},
		logo: {
			position: "relative",
			top: 20, // Ajusta este valor según sea necesario
			left: 20, // Ajusta este valor según sea necesario
			width: 100, // Ajusta el ancho de la imagen según sea necesario
			height: 100, // Ajusta la altura de la imagen según sea necesario
		},
		headerTextContainer: {
			flex: 1,
		},
		title: {
			textAlign: "center",
			fontSize: 12,
			marginBottom: 5,
		},
		header: {
			textAlign: "center",
			fontSize: 9,
		},
	});

	const tableData = [
		["Celda 1", "Celda 2", "Celda 3", "Celda 4"],
		["Celda 4", "Celda 5", "Celda 6"],
	];
	return (
		<PDFViewer
			style={{ width: "100%", height: "100vh" }}
			className="pdf-viewer"
		>
			<Document>
				<Page size="A4" style={styles.page}>
					<View>
					
						{/* Cabecera */}
						<View style={styles.headerContainer}>
						<Image
						style={styles.logo}
						//src={`data:image/png;base64,${establishment.image}`}
						src={establishment.image}
					/>
							<View style={styles.headerTextContainer}>
								<Text style={styles.title}>{establishment.name}</Text>
								<Text style={styles.header}>
									{establishment.address}-{establishment.phone}
								</Text>
								<Text style={styles.header}>{establishment.email}</Text>
							</View>
						</View>
						{/* inicio */}
						<Text style={styles.title}>Comprobante de suscripción</Text>
						<Text>No. de suscripción: {suscripcion.id}</Text>

						{/* Tabla */}
						<View style={styles.tableContainer}>
							<View style={styles.cabecera}>
								<Text>Usuario</Text>
							</View>

							{/* seccion de usuarios */}
							<View style={styles.tableRow}>
								{/* identificacion */}
								<View style={styles.tableTitle}>
									<Text>Tipo Id.</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject &&
										suscripcion.terceroObject.identificationType
											? suscripcion.terceroObject.identificationType.name
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Identificación</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject &&
										suscripcion.terceroObject.identification
											? suscripcion.terceroObject.identification
											: ""}
									</Text>
								</View>
							</View>
							{/* ---- nombres */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Nombres</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject
											? suscripcion.terceroObject.name1 +
											  " " +
											  suscripcion.terceroObject.name2
											: ""}
									</Text>
								</View>
								{/* --- apellidos --- */}
								<View style={styles.tableTitle}>
									<Text>Apellidos</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject
											? suscripcion.terceroObject.lastName1 +
											  " " +
											  suscripcion.terceroObject.lastName2
											: ""}
									</Text>
								</View>
							</View>
							{/*------ Ubicacion---------- */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Pais</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject &&
										suscripcion.terceroObject.paisUbicacion
											? suscripcion.terceroObject.paisUbicacion.descripcion
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Depto</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject &&
										suscripcion.terceroObject.departamentoUbicacion
											? suscripcion.terceroObject.departamentoUbicacion
													.descripcion
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Mupio</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject &&
										suscripcion.terceroObject.municipioUbicacion
											? suscripcion.terceroObject.municipioUbicacion.descripcion
											: ""}
									</Text>
								</View>
							</View>

							{/* Email y tels */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Email</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject
											? suscripcion.terceroObject.email1
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Teléfono 1</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.terceroObject
											? suscripcion.terceroObject.phone1
											: ""}
									</Text>
								</View>
							</View>
							{/* medidas corporales */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Peso</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.peso
											? suscripcion.peso.toLocaleString()
											: "--"}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Estatura</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.estatura
											? suscripcion.estatura.toLocaleString()
											: "--"}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>IMC</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.imc ? suscripcion.imc.toLocaleString() : "--"}
									</Text>
								</View>
							</View>
							{/* observaciones */}
							<View style={styles.tableRow}>
								<View
									style={{
										//flex: 1,
										padding: 5,
										backgroundColor: "#F5F5F5", // Fondo blanco para las celdas
										borderWidth: 1,
										borderColor: "#000000", // Color del borde de las celdas
										fontWeight: "bold",
										width: "25%",
									}}
								>
									<Text>Observaciones</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>{suscripcion.observaciones}</Text>
								</View>
							</View>

							{/* Bloque suscripcion */}

							<View style={styles.cabecera}>
								<Text>Suscripción</Text>
							</View>

							{/* --- plan---- */}

							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>plan</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.planObject
											? suscripcion.planObject.plan
											: "--"}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Descripción</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>
										{suscripcion.planObject
											? suscripcion.planObject.descripcion
											: "--"}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Dias</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.planObject
											? suscripcion.planObject.dias
											: "--"}
									</Text>
								</View>
							</View>

							{/* Servicios contratados */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitleCenter}>
									<Text>Servicios contrataos</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Servicio</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Descripción</Text>
								</View>
							</View>
							{suscripcion.planObject &&
								suscripcion.planObject.serviciosxplan &&
								suscripcion.planObject.serviciosxplan.map((row, rowIndex) => (
									<View style={styles.tableRow} key={rowIndex}>
										<View style={styles.tableCell} key={rowIndex}>
											<Text>
												{row.servicoDet ? row.servicoDet.servicio : ""}
											</Text>
										</View>
										<View style={styles.tableCell} key={rowIndex}>
											<Text>
												{row.servicoDet ? row.servicoDet.descripcion : ""}
											</Text>
										</View>
									</View>
								))}

							{/* fechas */}

							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Fecha Inicio</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>{suscripcion.fechaIni}</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Fecha Fin</Text>
								</View>
								<View style={styles.tableCell}>
									<Text>{suscripcion.fechaFin}</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Estado</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.estado === 1
											? "ACTIVO"
											: suscripcion.estado === 2
											? "VENCIDO"
											: "EN PAUSA"}
									</Text>
								</View>
							</View>

							{/* Instructores */}

							<View style={styles.tableRow}>
								<View style={styles.tableTitleCenter}>
									<Text>Instructores asignados</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Identificacion</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Nombre</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Telefono</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Email</Text>
								</View>
							</View>
							{suscripcion.instructorxsuscripcions &&
								suscripcion.instructorxsuscripcions.map((row, rowIndex) => (
									<View style={styles.tableRow} key={rowIndex}>
										<View style={styles.tableCell} key={rowIndex}>
											<Text>
												{row.instructorDet
													? row.instructorDet.identificacion
													: ""}
											</Text>
										</View>
										<View style={styles.tableCell} key={rowIndex}>
											<Text>
												{row.instructorDet
													? row.instructorDet.nombre1 +
													  " " +
													  row.instructorDet.nombre2 +
													  " " +
													  row.instructorDet.apellido1 +
													  " " +
													  row.instructorDet.apellido2
													: ""}
											</Text>
										</View>
										<View style={styles.tableCell} key={rowIndex}>
											<Text>
												{row.instructorDet ? row.instructorDet.email : ""}
											</Text>
										</View>
										<View style={styles.tableCell} key={rowIndex}>
											<Text>
												{row.instructorDet ? row.instructorDet.telefono : ""}
											</Text>
										</View>
									</View>
								))}

							{/*Costos */}

							<View style={styles.cabecera}>
								<Text>Costos</Text>
							</View>

							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Valor del plan</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.valorPlan
											? suscripcion.valorPlan.toLocaleString()
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Valor pago</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.valorPago
											? suscripcion.valorPago.toLocaleString()
											: ""}
									</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Saldo</Text>
								</View>
								<View style={styles.tableCellNumber}>
									<Text>
										{suscripcion.saldo
											? suscripcion.saldo.toLocaleString()
											: ""}
									</Text>
								</View>
							</View>

							{/* Detalle de pagos */}

							<View style={styles.tableRow}>
								<View style={styles.tableTitleCenter}>
									<Text>Detalle de pagos</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={styles.tableTitle}>
									<Text>Fecha</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Forma de pago</Text>
								</View>
								<View style={styles.tableTitle}>
									<Text>Valor</Text>
								</View>
							</View>

							{suscripcion.pagosxSuscripcions &&
								suscripcion.pagosxSuscripcions.map((row, rowIndex) =>
									row.active === true ? (
										<View style={styles.tableRow} key={rowIndex}>
											<View style={styles.tableCell} key={rowIndex}>
												<Text>{row.fecha}</Text>
											</View>
											<View style={styles.tableCell} key={rowIndex}>
												<Text>
													{row.formaPObject ? row.formaPObject.forma : ""}
												</Text>
											</View>
											<View style={styles.tableCellNumber} key={rowIndex}>
												<Text>
													{row.valor ? row.valor.toLocaleString() : ""}
												</Text>
											</View>
										</View>
									) : null
								)}
						</View>
						<View style={styles.footer}>
							<Text>{`Fecha y hora de expedición: ${new Date().toLocaleString()}`}</Text>
						</View>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
}

export default SuscripcionPdf;
