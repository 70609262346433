import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import {
	route_configuration_base,
	route_confiPresupuesto_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";

const Estructuras = () => {
	const { getToken } = useContext(AuthContext);
	const { establishment, customStyles, inputStyles } = useContext(AuthContext);


	const btnCancel = useRef(null);
	const formikRef = useRef(null);

	const handleSubmit = (values) => {
		
	};
	
	

	
	


	useEffect(() => {
		
	}, []);




	
	return (
		<div className="containerFormUser">
			<h1>Plan Único de Cuentas</h1>
			
			
			
		</div>
	);
};

export default Estructuras;
