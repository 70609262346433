import React,{useEffect} from "react";
import { NavLink, Outlet,useNavigate } from "react-router-dom";
import { GiStrong } from "react-icons/gi";
import { ImUserCheck } from "react-icons/im";




export default function SuscripcionesGym() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoGym");
	}, []);

	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="register-userGym">
									<li className="navInfoAdmin">
                                   <ImUserCheck/> <span> Inscripción</span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="search-suscripciones">
									<li className="navInfoAdmin">
										<i className="fas fa-search"></i> <span> Busqueda</span>
									</li>
								</NavLink>
                                
                                
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}