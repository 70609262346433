import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { BsCalendar3 } from "react-icons/bs";


export default function Register() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoAdmin");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								{/* <NavLink className="navInfo" to="crear-anios">
									<li className="navInfoAdmin">
										<BsCalendar3 /> <span> Años </span>
									</li>
								</NavLink> */}
								
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
