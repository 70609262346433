import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import "../../../styles/administration/Dashboard.css";
import {
	route_roles_getByEstablishment,
	route_user_list,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";

export default function DashboardAdmin() {
	const { establishment, getToken } = useContext(AuthContext);
	const [roles, setRoles] = useState(null);
	const [users, setUsers] = useState(null);

	useEffect(() => {
		loadRoles();
		loadUsers();
	}, []);

	const loadRoles = () => {
		axios
			.get(route_roles_getByEstablishment + establishment.id, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setRoles(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const loadUsers = () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		axios
			.get(route_user_list + establishment.id + "," + true, config)
			.then((response) => {
				setUsers(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className="containerFormUser">
			<div
				className="row"
				style={{ display: "flex", justifyContent: "center" }}
			>
				<div className="col-5 containerDashboard">
					<>
						<label className="titleDash">Roles</label>

						{roles ? (
							<div className="">
								<table
									id="tableUsers"
									className="table table-striped table-hover"
								>
									<thead>
										<tr>
											<th style={{ textAlign: "center" }}>Rol</th>
											<th style={{ textAlign: "center" }}>Descripción</th>
										</tr>
									</thead>
									<tbody>
										{roles &&
											roles.map((rol) => (
												<tr key={rol.id}>
													<td style={{ textAlign: "left" }}>{rol.name}</td>
													<td style={{ textAlign: "left" }}>
														{rol.description}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						) : (
							/*spinner carga*/
							<button class="btn btn-primary" type="button" disabled>
								<span
									class="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
							/*------------*/
						)}
					</>
				</div>
				<div className="col-5 containerDashboard">
					<>
						<label className="titleDash">Usuarios</label>

						<div className="Admin">
							{users ? (
								<table
									id="tableUsers"
									className="table table-striped table-hover"
								>
									<thead>
										<tr>
											<th style={{ textAlign: "center" }}>Nombre</th>
											<th style={{ textAlign: "center" }}>Rol</th>
										</tr>
									</thead>
									<tbody>
										{users.map((userAct) => (
											<tr key={userAct.id}>
												<td style={{ textAlign: "left" }}>
													{userAct.name} {userAct.lastName}
												</td>
												<td style={{ textAlign: "left" }}>
													{userAct.roles.map((rol, index) => (
														<span key={index}>
															{rol.rolesxrol.name}
															{index !== userAct.roles.length - 1 && ", "}
														</span>
													))}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								/* Spinner de carga */
								<button className="btn btn-primary" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										aria-hidden="true"
									></span>
									<span role="status"> Cargando...</span>
								</button>
							)}
						</div>
					</>
				</div>
			</div>
		</div>
	);
}
