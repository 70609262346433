import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { GrOrderedList } from "react-icons/gr";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";

export default function ConfPresupuesto() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoPresupuesto");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="crear-estructura">
									<li className="navInfoAdmin">
										<GrOrderedList /> <span> Crear Estructura </span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="configurar-estructura">
									<li className="navInfoAdmin">
										<VscGitPullRequestCreate /> <span> Conf. Estructura </span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="crear-planP">
									<li className="navInfoAdmin">
										<GrOrderedList /> <span> Crear Plan P. </span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="proyectar-planP">
									<li className="navInfoAdmin">
										<AiOutlineFundProjectionScreen />{" "}
										<span> Proyectar Plan P. </span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="planpxPlano">
									<li className="navInfoAdmin">
									<GrDocumentUpdate />{" "}
										<span> Cargar Plan P. </span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="conceptos-planP">
									<li className="navInfoAdmin">
										<MdOutlinePayments /> <span> Deducciones </span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="conceptosinfo-planP">
									<li className="navInfoAdmin">
										<FaInfoCircle /> <span> Conceptos </span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
