import React from "react";
import Footer from "./Footer";
import ImgVission from "../../../images/home/vision.png";
import Barra1 from "../home/Barra1";

export default function Vision() {
	return (
		<>
			<Barra1 />
			<section>
				<div className="image-container">
					<img src={ImgVission} className="slidesImage" alt="Mi imagen" />
				</div>
			</section>

			<Footer />
		</>
	);
}
