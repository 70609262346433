import React from "react";
import { NavBarAdmin } from "./NavBarAdmin";

export default function HomeAdmin() {
	return (
		<>
			<div>
				<NavBarAdmin module={1} />
			</div>
		
		</>
	);
}
