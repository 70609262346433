import React, { useEffect, useState } from "react";

import {
	PDFViewer,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
	logo: {
		position: "absolute",
		//top: 20, // Ajusta este valor según sea necesario
		left: 20, // Ajusta este valor según sea necesario
		width: 100, // Ajusta el ancho de la imagen según sea necesario
		height: 100, // Ajusta la altura de la imagen según sea necesario
	},
	page: {
		padding: 30,
		//marginRight: 50,
		//marginTop:30,
		//marginBottom: 30,
	},
	headerContainer: {
		marginBottom: 30,
		textAlign: "center",
	},
	title: {
		fontSize: 13,
		marginBottom: 5,
		textAlign: "center",
		fontFamily: "Helvetica",
	},
	title2: {
		fontFamily: "Helvetica",
		fontSize: 10,
		textAlign: "center",
		lineHeight: 1.5 /* Puedes ajustar este valor según tu preferencia */,
	},
	title3: {
		marginBottom: "10px",
		marginTop: "10px",
		fontFamily: "Helvetica",
		fontSize: 14,
		fontWeight: 900,
		textAlign: "center",
	},
	title4: {
		fontFamily: "Helvetica",
		fontSize: 7,
		textAlign: "left",
		lineHeight: 1,
		marginLeft: 150 /* Puedes ajustar este valor según tu preferencia */,
	},
	justifiedTextContainer: {
		textAlign: "justify",
		wordWrap: "break-word",
	},
	text: {
		fontSize: 12,
		lineHeight: 1.5,
		marginRight: 25,
		marginLeft: 25,
	},
	footer: {
		fontSize: 10,
		textAlign: "center",
		position: "absolute",
		bottom: 20,
		left: 20,
		right: 20,
	},
	cabeceraFirmas: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "wrap",
	},
	firma: {
		width: "45%",
		marginBottom: 10,
		flexDirection: "column",
		alignItems: "center",
	},
	lineaFirma: {
		borderBottom: 1,
		width: "100%",
		marginBottom: 5,
		marginTop: 50, // Espacio vertical arriba de la línea de firma
	},
	titleArticulos: {
		textAlign: "left", // Alinea a la izquierda
		fontSize: 16, // Ajusta según tus necesidades
		marginBottom: 8, // Espacio entre elementos
	},
	titleArticulos2: {
		textAlign: "left", // Alinea a la izquierda
		fontSize: 14, // Ajusta según tus necesidades
		marginBottom: 8, // Espacio entre elementos */
	},
	textoArticulos: {
		textAlign: "justify", // Justifica el texto
		fontSize: 12, // Ajusta según tus necesidades
		marginBottom: 8, // Espacio entre elementos */
	},
	articuloContainer: {
		flexDirection: "row",
		alignItems: "flex-start",
		marginBottom: 8, //pacio entre elementos // Espacio entre elementos
	},
	tableRow: {
		flexDirection: "row",
		alignItems: "center",
		borderBottom: 1, // Borde inferior de la fila
		borderColor: "#000", // Color del borde inferior (ajústalo según tus necesidades)
		marginBottom: 0,
		marginLeft: 20,
		marginRight: 20,
	},

	cell20: {
		flex: 0.2, // Ocupa el 20% del ancho total (3 columnas en total)
		fontSize: 10,
		borderRight:1,
		//borderWidth: 1, // Borde de la celda
		borderColor: "#000", // Color del borde de la celda (ajústalo según tus necesidades)
		padding: 8, // Espaciado interno de la celda
		textAlign:"right"
	},
	cell30: {
		flex: 0.3, // Ocupa el 20% del ancho total (3 columnas en total)
		fontSize: 10,
		borderLeft:1,
		//borderWidth: 1, // Borde de la celda
		borderColor: "#000", // Color del borde de la celda (ajústalo según tus necesidades)
		padding: 8, // Espaciado interno de la celda
	},
	cell50: {
		flex: 0.5, // Ocupa el 20% del ancho total (3 columnas en total)
		fontSize: 8,
		borderLeft:1,
		borderRight:1,
		//borderWidth: 1, // Borde de la celda
		borderColor: "#000", // Color del borde de la celda (ajústalo según tus necesidades)
		padding: 8, // Espaciado interno de la celda
	},

	cellT20: {
		fontWeight: 900,
		flex: 0.2, // Ocupa el 20% del ancho total (3 columnas en total)
		fontSize: 10,
		borderWidth: 1, // Borde de la celda
		borderColor: "#000",
		backgroundColor: "#a6aec1", // Color del borde de la celda (ajústalo según tus necesidades)
		padding: 8, // Espaciado interno de la celda
	},
	cellT30: {
		fontWeight: "bold",
		flex: 0.3, // Ocupa el 20% del ancho total (3 columnas en total)
		fontSize: 10,
		borderWidth: 1, // Borde de la celda
		borderColor: "#000", // Color del borde de la celda (ajústalo según tus necesidades)
		backgroundColor: "#a6aec1",
		padding: 8, // Espaciado interno de la celda
	},
	cellT50: {
		fontWeight: "bold",
		flex: 0.5, // Ocupa el 20% del ancho total (3 columnas en total)
		fontSize: 10,
		borderWidth: 1, // Borde de la celda
		borderColor: "#000", // Color del borde de la celda (ajústalo según tus necesidades)
		backgroundColor: "#a6aec1",
		padding: 8, // Espaciado interno de la celda
	},
});

const MyPDFDocument = ({ firmas, acta, articulos, cuentas }) => {
	const firmasPares = [];
	for (let i = 0; i < firmas.length; i += 2) {
		const firma1 = firmas[i];
		const firma2 = i + 1 < firmas.length ? firmas[i + 1] : null;

		firmasPares.push(
			<View style={styles.firma} key={i}>
				<View style={styles.lineaFirma}></View>
				<Text style={styles.text}>{firma1.nombre}</Text>
				<Text style={styles.text}>{firma1.cargo}</Text>
				{firma2 && (
					<>
						<View style={styles.lineaFirma}></View>
						<Text style={styles.text}>{firma2.nombre}</Text>
						<Text style={styles.text}>{firma2.cargo}</Text>
					</>
				)}
			</View>
		);
	}
	const findDescendants = (madreId) => {
		const result = [];
		const madre = cuentas.filter((item) => item.id === madreId);
		console.log(madre)
		result.push(madre[0])
		const findChildren = (parentId) => {
			const children = cuentas.filter((item) => item.madre === parentId);
			children.forEach((child) => {
				result.push(child);
				findChildren(child.id);
			});
		};
		findChildren(madreId);
		console.log(result);
		return result;
	};
	useEffect(() => {
		// Código para manejar cambios en firmas, acta o articulos
		// Puedes imprimir las propiedades aquí para asegurarte de que se están actualizando
		console.log("firmas:", firmas);
		console.log("acta:", acta);
		console.log("articulos:", articulos);
		console.log(cuentas);
		//console.log(findDescendants(cuentas))
		//setForceUpdate(prevState => prevState + 1);
	}, [firmas, acta, articulos]);

	return (
		<>
			<PDFViewer
				style={{ width: "100%", height: "100vh" }}
				className="pdf-viewer"
			>
				<Document>
					<Page size="A4" style={styles.page} wrap>
						<View>
							<View style={styles.headerContainer}>
								<Image
									style={styles.logo}
									//src={`data:image/png;base64,${establishment.image}`}
									src={
										acta && acta.logo
											? acta.logo
											: "data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJQAxgMBIgACEQEDEQH/xAAWAAEBAQAAAAAAAAAAAAAAAAAAAQf/xAAVEAEBAAAAAAAAAAAAAAAAAAAAAf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASKAAAAAAAAAAAAAAAAAAigAAAAAACRQAAAAAAAAAAAAAAAAAAAAAAAASKAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP//Z"
									}
								/>
								<Text style={styles.title}>{acta ? acta.titulo1 : ""}</Text>
								<Text style={styles.title2}>{acta ? acta.titulo2 : ""}</Text>
								<Text style={styles.title4}>{acta ? acta.texto1 : ""}</Text>
							</View>
							<Text style={styles.title2}>{acta ? acta.titulo3 : ""}</Text>
							<Text style={styles.title3}>CONSIDERANDO</Text>

							<View style={styles.justifiedTextContainer}>
								<Text style={styles.text}>{acta ? acta.texto2 : ""}</Text>
							</View>

							<Text style={styles.title3}>ACUERDA</Text>
							{articulos
								.sort((a, b) => a.titulo.localeCompare(b.titulo))
								.map((art) => (
									<>
										<View style={{ marginBottom: 30 }}>
											<View key={art.id} style={styles.articuloContainer}>
												<View style={styles.justifiedTextContainer}>
													<Text style={styles.text}>
														<Text style={styles.titleArticulos2}>
															{art ? art.titulo : ""}
														</Text>{" "}
														:{" "}
														<Text style={styles.text}>
															{art ? art.texto : ""}
														</Text>
													</Text>
												</View>
											</View>
											{art.listCtas.length > 0 ? (
												<View style={styles.tableRow}>
													<Text style={styles.cellT30}>RUBRO PRESUPUESTAL</Text>
													<Text style={styles.cellT50}>
														DENOMINACION PRESUPUESTAL
													</Text>
													<Text style={styles.cellT20}>VALOR</Text>
												</View>
											) : null}

											{art.listCtas.map((cta) => {
												let ctas = findDescendants(cta.cta.id);
												return ctas.map((ctah) => (
													 <View key={ctah.codigo} style={styles.tableRow}>
														<Text style={styles.cell30}>{ctah.codigo}</Text>
														<Text style={styles.cell50}>{ctah.nombre}</Text>
														<Text style={styles.cell20}>
															{ctah.apropiacion.toLocaleString()}
														</Text>
													</View> 
												));
											})}
										</View>
									</>
								))}
							<Text style={styles.title2}>{acta ? acta.pie : ""}</Text>

							<View style={styles.cabeceraFirmas}>{firmasPares}</View>
						</View>
						<Text
							style={styles.footer}
						>{`Fecha y hora de expedición: ${new Date().toLocaleString()}`}</Text>
					</Page>
				</Document>
			</PDFViewer>
		</>
	);
};

export default MyPDFDocument;
