import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import {
	route_roles_getByEstablishment,
	route_modules_list,
	route_options_list,
	route_permission_save,
	route_permission_permissionsByRole,
} from "../../../api-routes/RoutesLogin";
import { AuthContext } from "../../context/LogContext";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { show_alert } from "../../../functions";

export default function Permissions() {
	const [modules, setModules] = useState([]);
	const [options, setOptions] = useState([]);
	const [roles, setRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState("");
	const { establishment, getToken } = useContext(AuthContext);
	const [array, setArray] = useState([]);
	const [arrayOptions, setArrayOptions] = useState([]);
	const [btnCarga, setBtnCarga] = useState(0);
	const validationSchema = Yup.object().shape({
		role: Yup.string().required("Este campo es obligatorio"),
	});

	const loadEstablishments = () => {
		axios
			.get(route_roles_getByEstablishment + establishment.id, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setRoles(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	//Cargar permisos establecidos a rol
	const loadPermissions = (role, values) => {
		setArray([]);
		setArrayOptions([]);
		if (role) {
			axios
				.get(route_permission_permissionsByRole + role, {
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				})
				.then((response) => {
					const checkboxes = document.querySelectorAll(".form-check-input");
					checkboxes.forEach((checkbox) => {
						checkbox.checked = false;
					});
					setSelectedRole(role);
					values.role = role;
					response.data.modules.forEach((module) => {
						setArray((prevArray) => [...prevArray, module]);
						var mod = document.getElementById(module);
						if (mod) {
							mod.checked = true;
						}
					});
					response.data.options.forEach((option) => {
						setArrayOptions((prevArray1) => [...prevArray1, option]);
						var mod1 = document.getElementById("O" + option);
						if (mod1) {
							mod1.checked = true;
						}
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	// funcion para obtener lista de modulos
	const getModules = () => {
		axios
			.get(route_modules_list, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setModules(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	//------------------------------------------
	// funcion para obtener lista de opciones
	const getOptions = () => {
		axios
			.get(route_options_list, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setOptions(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	//-funcion almacena permisos
	const registrarUsuario = async (json) => {
		setBtnCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_permission_save, json, config)
			.then((response) => {
				if (response.data == null) {
					show_alert("Error", "error");
					return false;
				} else {
					Swal.fire({
						icon: "success",
						title: "Permisos asignados exitosamente",
						text: "Debe loguearse nuevamente para ver los cambios realizados en los roles del usuario actual",
						showConfirmButton: true,
					}).then(() => {
						setBtnCarga(0)
					});
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const addModule = (id, event) => {
		if (event.target.checked) {
			array.push(id);
		} else {
			const index = array.indexOf(id);
			if (index !== -1) {
				array.splice(index, 1);
			}
		}
	};

	const addOption = (id, event) => {
		if (event.target.checked) {
			arrayOptions.push(id);
		} else {
			const index = arrayOptions.indexOf(id);
			if (index !== -1) {
				arrayOptions.splice(index, 1);
			}
		}
	};

	useEffect(() => {
		loadEstablishments();
		getModules();
		getOptions();
	}, []);
	return (
		<div className="containerFormUser">
			<h1>Permisos</h1>
			<Formik
				initialValues={{
					role: "",
				}}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					const jsonString = JSON.stringify(array);
					const jsonStringOptions = JSON.stringify(arrayOptions);
					values.modules = jsonString;
					values.options = jsonStringOptions;
					const role = Number(values.role);
					const modules = JSON.parse(values.modules);
					const options = JSON.parse(values.options);

					const updatedValues = {
						role,
						modules,
						options,
						user: localStorage.getItem("idUser"),
					};

					const jsonString1 = JSON.stringify(updatedValues);
					registrarUsuario(jsonString1);
				}}
			>
				{({ isSubmitting, setFieldValue, values }) => (
					<Form>
						<div className="table-container">
							<table id="tableInfo">
								<tbody>
									<tr>
										<th style={{ textAlign: "center" }}>Rol:</th>
										<td>
											<Field
												as="select"
												id="role"
												name="role"
												className="form-select"
												onChange={(e) => {
													if (e) {
														loadPermissions(e.target.value, values);
													}
												}}
												value={selectedRole}
											>
												<option value="" label="Seleccionar un rol" />
												{roles.map((rol) => (
													<option key={rol.id} value={rol.id}>
														{rol.name}{" "}
													</option>
												))}
											</Field>
											<ErrorMessage
												name="role"
												component="div"
												className="error"
											/>
										</td>
									</tr>

									{modules ? (
										modules.map((module) => (
											<>
												<tr>
													<th colSpan={2}>
														<div className="form-check form-switch">
															<label
																className="form-check-label checkModules"
																htmlFor={module.id}
															>
																{module.module}
															</label>

															<input
																className="form-check-input"
																type="checkbox"
																role="switch"
																name={module.id}
																id={module.id}
																onChange={(event) =>
																	addModule(module.id, event)
																}
															></input>
														</div>
													</th>
												</tr>

												{options.map((option) => {
													return option.module.id === module.id ? (
														<tr key={option.id}>
															<td colSpan={2} style={{ paddingLeft: "50px" }}>
																<div className="form-check form-switch ">
																	<label
																		className="form-check-label"
																		htmlFor={"O" + option.id}
																	>
																		{option.option}
																	</label>
																	<input
																		className="form-check-input checkOptions"
																		type="checkbox"
																		role="switch"
																		name={"O" + option.id}
																		id={"O" + option.id}
																		onChange={(event) =>
																			addOption(option.id, event)
																		}
																	></input>
																</div>
															</td>
														</tr>
													) : null;
												})}
											</>
										))
									) : (
										<h3>Cargando</h3>
									)}
								</tbody>
							</table>
						</div>
						{btnCarga === 0 ? (
							<button type="submit" className="btn btn-primary">
								Autorizar
							</button>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Guardando...</span>
							</button>
							/*--------------- */
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
}
