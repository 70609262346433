import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_informesPresupuesto,
	route_confiPresupuesto_base,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";

import TableIngresos from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const { naturaleza } = useParams();
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [planp, setPlanp] = useState(null);
	const [year, setYear] = useState(null);
	const { establishment, getToken, customStyles, dateStyles, FechaYMDHMS } =
		useContext(AuthContext);
	const [data, setData] = useState([]);
	const [years, setYears] = useState([]);
	const [columnas, setColumnas] = useState([]);
	const [desde, setDesde] = useState("");
	const [hasta, setHasta] = useState("");
	const [titulo, setTitulo] = useState("");
	const [banCarga, setBanCarga] = useState(0);

	const sumFields = [
		"apropiacioni",
		"adiciones",
		"disminuciones",
		"apropiacionf",
		"reconocimientos",
		"reconocimientossa",
		"ingresos",
		"saldo",
	];
	useEffect(() => {
		console.log(establishment);
		let columns = [];
		if (naturaleza == 1) {
			setTitulo("Ejecución de ingresos");
			columns = [
				{ Header: "Código", accessor: "codigo" },
				{ Header: "Nombre", accessor: "nombre" },
				{ Header: "Apropiación Inicial", accessor: "apropiacioni" },
				{ Header: "Adiciones", accessor: "adiciones" },
				{ Header: "Disminuciones ", accessor: "disminuciones" },
				{ Header: "Apropiación Final", accessor: "apropiacionf" },
				{ Header: "Reconocimientos", accessor: "reconocimientofinal" },
				{
					Header: "Reconocimientos sin afectar",
					accessor: "reconocimientossa",
				},
				{ Header: "Ingresos", accessor: "ingresos" },
				{ Header: "Saldo", accessor: "saldo" },
				{ Header: "Tipo", accessor: "tipo" },
				// ...otras columnas
			];
		} else {
			setTitulo("Ejecución de gastos");
			columns = [
				{ Header: "Código", accessor: "codigo" },
				{ Header: "Nombre", accessor: "nombre" },
				{ Header: "Apropiación Inicial", accessor: "apropiacioni" },
				{ Header: "Adiciones", accessor: "adiciones" },
				{ Header: "Disminuciones ", accessor: "disminuciones" },
				{ Header: "Apropiación Final", accessor: "apropiacionf" },
				{ Header: "Disponibilidades", accessor: "disponibilidadfinal" },
				{
					Header: "Disponibilidades sin afectar",
					accessor: "disponibilidadessa",
				},
				{ Header: "Registros", accessor: "registrosfinal" },
				{ Header: "Registros sin afectar", accessor: "registrossa" },
				{ Header: "Obligaciones", accessor: "obligaciones" },
				{ Header: "Obligaciones sin afectar", accessor: "obligacionessa" },
				{ Header: "Pagos", accessor: "pagos" },
				{ Header: "Saldo", accessor: "saldo" },
				{ Header: "Tipo", accessor: "tipo" },
				// ...otras columnas
			];
		}
		setColumnas(columns);
		getCabecerasP(0);
		getYears();
	}, [naturaleza]);

	const visualizarInforme = async () => {
		setBanCarga(1);
		if (planp != null && desde != "" && hasta != "") {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_informesPresupuesto}/ejecuciones/${establishment.id},${planp},${naturaleza},1,${desde},${hasta}`,
				config
			);
			console.log(result.data)
			const resultados = actualizarMadres(result.data);
			setData(resultados);
			//setData(result.data);
			setBanCarga(0);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los datos",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0);
		}
	};

	function actualizarMadres(data) {
		//console.log(data);
		const filteredData = data.filter((item) => item.tipo === "Detalle");
		filteredData.map((obj) => {
			console.log(obj)
			// acumular adiciones //
			acumularMadres(obj, data, obj.adiciones, "adiciones");
			//acumular diminucinoes //
			acumularMadres(obj, data, obj.disminuciones, "disminuciones");
			//acumular reconocimientos //
			acumularMadres(obj, data, obj.reconocimientos, "reconocimientos");
			//acumular reconocimeitos final
			acumularMadres(obj, data, obj.reconocimientofinal, "reconocimientofinal");
			//acumular reconocimientos sin afectar//
			acumularMadres(obj, data, obj.reconocimientossa, "reconocimientossa");
			//acumular reconocimientos sin afecta//
			acumularMadres(obj, data, obj.ingresos, "ingresos");
			//acumular disponibilidades
			acumularMadres(obj, data, obj.disponibilidades, "disponibilidades");
			//dipsponibilidades fnal
			acumularMadres(obj, data, obj.disponibilidadfinal, "disponibilidadfinal");
     		//acumular disponibilidades sin afectar
			acumularMadres(obj, data, obj.disponibilidadessa, "disponibilidadessa");
			//acumular registros
			acumularMadres(obj, data, obj.registros, "registros");
			//acumularr registros final
			acumularMadres(obj, data, obj.registrosfinal, "registrosfinal");
			//acumular registros sin afectar
			acumularMadres(obj, data, obj.registrossa, "registrossa");
			//acumular obligaciones
			acumularMadres(obj, data, obj.obligaciones, "obligaciones");
			//acumular obligaciones sin afectar
			acumularMadres(obj, data, obj.obligacionessa, "obligacionessa");
			//acumular pagos
			acumularMadres(obj, data, obj.pagos, "pagos");
			//acumular pagos sin afectar
			acumularMadres(obj, data, obj.pagossa, "pagossa");
			

		});
		return data;
	}
	function acumularMadres(objeto, data, valor, campo) {
		if (objeto.madre !== null) {
			var madre = data.find((item) => item.id === objeto.madre);
			if (madre) {
				madre[campo] += valor;
				acumularMadres(madre, data, valor, campo);
			}
		}
		return data; // Retornar data actualizado
	}

	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			console.log(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`
			);
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));
			console.log(formattedOptions);

			setCabecerasPlanP(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de ejecuciones</h1>

				{/* seccion de filtros */}
				<div className="row"></div>
				<div style={{ marginTop: "10px" }} className="row">
					<div className="col-md-5">
						<Select
							styles={customStyles}
							isSearchable="true"
							placeholder="Plan presupuestal"
							isClearable="true"
							options={cabecerasPlanP}
							onChange={(e) => {
								if (e) {
									setPlanp(e.value);
								}
							}}
						></Select>
					</div>
					<div className="col-md-3">
						<div className="form-floating  mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="desde"
								id="desde"
								value={desde}
								className="form-control"
								placeholder="Desde "
								onChange={(e) => {
									if (e) {
										setDesde(e.target.value);
									} else {
										setDesde("");
									}
								}}
							/>
							<label
								style={{ fontSize: "15px", zIndex: "0" }}
								htmlFor="desde"
								className="form-label"
							>
								Desde
							</label>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="hasta"
								id="hasta"
								value={hasta}
								className="form-control"
								placeholder="Hasta "
								onChange={(e) => {
									if (e) {
										setHasta(e.target.value);
									} else {
										setHasta("");
									}
								}}
							/>
							<label
								style={{ zIndex: "0" }}
								htmlFor="hasta"
								className="form-label"
							>
								Hasta
							</label>
						</div>
					</div>
					<div className="col-md-1">
						<button
							style={{ height: "45px" }}
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={30} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
							data={data}
							columns={columnas}
							encabezado={{
								empresa: establishment.name,
								nit: establishment.identification,
								titulo: titulo,
								imagen: establishment.image,
								fechas: "Periodo: " + desde + " hasta: " + hasta,
								generado: "Fecha generación: " + FechaYMDHMS,
							}}
						/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
}
