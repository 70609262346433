import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { GiChart } from "react-icons/gi";
import { RiFileUserFill } from "react-icons/ri";
import { MdOutlineTableChart } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";

export default function BaseInfomresGym() {
	const navigate = useNavigate();
	const [usuariosOptionsVisible, setUsuariosOptionsVisible] = useState(false);
	const [financierosOptionsVisible, setFinancierosOptionsVisible] = useState(
		false
	);

	const toggleUsuariosOptions = () => {
		setUsuariosOptionsVisible(!usuariosOptionsVisible);
	};
	const toggleFinancierosOptions = () => {
		setFinancierosOptionsVisible(!financierosOptionsVisible);
	};

	useEffect(() => {
		navigate("/menu/hefestoGym");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							{/* <ul>
								<NavLink className="navInfo" to="register-pagoGym">
									<li className="navInfoAdmin">
										<GiChart /> <span> Dashboard</span>
									</li>
								</NavLink>
							</ul> */}
							<ul>
								<NavLink className="navInfo" to="infoUsuarios-Gym">
									<li className="navInfoAdmin">
										<RiFileUserFill /> <span> Usuarios</span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="#">
									<li className="navInfoAdmin" onClick={toggleUsuariosOptions}>
										<MdOutlineTableChart />{" "}
										<span>
											Suscripciones&nbsp;
											{usuariosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}{" "}
										</span>
									</li>
								</NavLink>
								{usuariosOptionsVisible && (
									<ul>
										<NavLink className="navInfo" to="infoSuscripciones-Gym">
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i> <span> General</span>
											</li>
										</NavLink>

										<NavLink className="navInfo" to="infoVencimientos-Gym">
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i>{" "}
												<span> Vencimientos</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>

							<ul>
								<NavLink className="navInfo" to="#">
									<li
										className="navInfoAdmin"
										onClick={toggleFinancierosOptions}
									>
										<FaFileInvoiceDollar />
										<span>
											Financieros&nbsp;
											{financierosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}
										</span>
									</li>
								</NavLink>
								{financierosOptionsVisible && (
									<ul>
										<NavLink className="navInfo" to="infoIngresos-Gym">
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i> <span> Ingresos</span>
											</li>
										</NavLink>

										<NavLink className="navInfo" to="infoCartera-Gym">
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i> <span> Cartera</span>
											</li>
										</NavLink>
										<NavLink className="navInfo" to="infoAnulaciones-Gym">
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i>{" "}
												<span> Anulaciones</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
