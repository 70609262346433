import React from "react";
import "../../../styles/administration/NavBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../../images/logo.png";

export default function Barra1() {
	return (
		<div>
			<nav className="navbar" id="barra1">
				<NavLink to="/home" href="#">
					<img id="logo" src={logo} alt="logo" />
				</NavLink>
				<div className="opcionesBar" >
				 <ul>
					<li>
						<a href="#">
							<i className="fas fa-home"></i>
							Nosotros
						</a>
						<ul>
							<li>
								<NavLink to="/home/us">Quiénes somos</NavLink>
							</li>
							<li>
								<NavLink to="/home/mission">Misión</NavLink>
							</li>
							<li>
								<NavLink to="/home/vision">Visión</NavLink>
							</li>
						</ul>
					</li>
					<li>
						<NavLink to="/home/services">
							<i className="fas fa-handshake"></i>
							Servicios
						</NavLink>
					</li>
					<li>
						<NavLink to="/home/contacts">
							<i className="fas fa-phone-volume"></i>
							Contactos
						</NavLink>
					</li>
				</ul>
				</div> 
			</nav>
		</div>
	);
}
