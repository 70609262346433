import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_base,
	route_modules_list,
	route_options_listByModule,
	route_user_byEstab,
	
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import TableIngresos from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const { naturaleza } = useParams();
	const [modulos, setModulos] = useState([]);
	const [opciones, setOpciones] = useState([]);
	const [usuarios, setUsuarios] = useState([]);
	const [moduloSel, setmoduloSel] = useState(0);
	const [optionSel, setOptionSel] = useState(0);
	const [accionSel, setAccionSel] = useState(0);
	const [usuarioSel, setUsuarioSel] = useState(0);
	const [desde, setDesde] = useState(null);
	const [hasta, setHasta] = useState(null);
	const [btnCarga, setBtnCarga] = useState(0);

	const {
		establishment,
		getToken,
		customStyles,
		dateStyles,
		FechaYMDHMS,
	} = useContext(AuthContext);
	const [data, setData] = useState([]);

	const [titulo, setTitulo] = useState("");

	useEffect(() => {
		getModulos();
		getUsers();
	}, [naturaleza]);

	const columns = [
		{ Header: "Modulo", accessor: "modulo" },
		{ Header: "Opción", accessor: "opcion" },
		{ Header: "Función", accessor: "funcion" },
		{ Header: "Acción", accessor: "accion" },
		{ Header: "Fecha", accessor: "fecha" },
		{ Header: "Usuario", accessor: "usuario" },
		{ Header: "Detalle", accessor: "descripcion" },
		{ Header: "Justificación", accessor: "justificacion" },
	];
	const visualizarInforme = async () => {
		setBtnCarga(1)
		console.log(desde);
		console.log(hasta);
		var fdesde = "1000-01-01";
		var fhasta = "3000-01-01";
		if (desde != null && desde!="") {
			fdesde = desde;
		}
		if (hasta != null && hasta!="" ) {
			fhasta = hasta;
		}
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		
		const result = await axios.get(
			`${route_base}/configuration/logs/${moduloSel},${optionSel},${accionSel},${usuarioSel},${fdesde},${fhasta}`,
			config
		);
		console.log(result.data);
		setData(result.data);
		setTitulo("Informe de logs");
		setBtnCarga(0)
	};
	const getModulos = async () => {
		console.log("modulos");
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(`${route_modules_list}`, config);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.module,
			}));
			setModulos(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getOptions = async (idM) => {
		console.log("modulos");
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_options_listByModule}/${idM}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.option,
			}));

			setOpciones(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getUsers = async (idM) => {
		console.log("modulos");
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_user_byEstab}/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.name + " " + item.lastName,
			}));

			setUsuarios(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};

	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de logs</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-4">
						<Select
							styles={customStyles}
							isSearchable="true"
							placeholder="Modulo"
							isClearable="true"
							options={modulos}
							onChange={(e) => {
								if (e) {
									setmoduloSel(e.value);
									getOptions(e.value);
								} else {
									setmoduloSel(0);
									setOpciones([]);
								}
							}}
						></Select>
					</div>
					<div className="col-md-4">
						<Select
							styles={customStyles}
							isSearchable={true}
							placeholder="Opciones"
							isClearable={true}
							options={opciones}
							onChange={(e) => {
								if (e) {
									setOptionSel(e.value);
								} else {
									setOptionSel(0);
								}
							}}
						/>
					</div>
					<div className="col-md-4">
						<Select
							styles={customStyles}
							placeholder="Acción"
							isClearable={true}
							onChange={(e) => {
								if (e) {
									setAccionSel(e.value);
								} else{
									setAccionSel(0);
								}
							}}
							options={[
								{ label: "Creación", value: 1 },
								{ label: "Edición", value: 2 },
								{ label: "Anulación", value: 3 },
							]}
							classNamePrefix="select"
						/>
					</div>
				</div>
				<div style={{ marginTop: "10px" }} className="row">
					<div className="col-md-4  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="desde"
								id="desde"
								value={desde}
								className="form-control"
								placeholder="Desde "
								isClearable={true}
								onChange={(e) => {
									if (e) {
										setDesde(e.target.value);
									}
								}}
							/>
							<label htmlFor="Desde" className="form-label">
								Desde
							</label>
						</div>
					</div>
					<div className="col-md-4  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="hasta"
								id="hasta"
								value={hasta}
								className="form-control"
								placeholder="Hasta "
								isClearable={true}
								onChange={(e) => {
									if (e) {
										setHasta(e.target.value);
									}
								}}
							/>
							<label htmlFor="hasta" className="form-label">
								Hasta
							</label>
						</div>
					</div>

					<div className="col-md-3">
						<Select
							styles={customStyles}
							placeholder="Usuario"
							isClearable={true}
							onChange={(e) => {
								if (e) {
									setUsuarioSel(e.value);
								} else {
									setUsuarioSel(0);
								}
							}}
							options={usuarios}
							classNamePrefix="select"
						/>
					</div>

					<div className="col-md-1">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>

				<div className="table-container-reacTable">
					{btnCarga==1?
					/* spinner carga */
					<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando información...</span>
				</button>
				/*--------------- */
				:
				<TableIngresos
						data={data}
						columns={columns}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: titulo,
							imagen: establishment.image,
							fechas: "Informacion desde: " + desde + " hasta: " + hasta,
							generado: "Informe generado: " + FechaYMDHMS,
						}}
					/>}
					
				</div>
			</div>
		</>
	);
}
