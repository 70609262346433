import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_informesPresupuesto,
	route_confiPresupuesto_base,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";

import TableIngresos from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const { naturaleza } = useParams();
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [planp, setPlanp] = useState(null);
	const [year, setYear] = useState(null);
	const [trimestre, setTrimestre] = useState(null);
	const { establishment, getToken,customStyles,FechaYMDHMS } = useContext(AuthContext);
	const [data, setData] = useState([]);
	const [years, setYears] = useState([]);
	const [columnas, setColumnas] = useState([]);
	const [banCarga, setBanCarga] = useState(0);
	const [tituloInforme,setTituloInforme] = useState("");
	

	const sumFields = [
		"apropiacioni",
		"adiciones",
		"disminuciones",
		"apropiacionf",
		"reconocimientos",
		"reconocimientossa",
		"ingresos",
		"saldo",
	];
	useEffect(() => {
		let columns = [];
		setData([]);
		if (naturaleza == 1) {
			setTituloInforme("Ingresos");
			columns = [
				{ Header: "Código establecimiento", accessor: "codInstitucion" },
				{ Header: "Año", accessor: "anio" },
				{ Header: "Trimestre", accessor: "trimestre" },
				{ Header: "Fuente de ingreso", accessor: "fingresocod" },
				{ Header: "Nombre fuente de ingreso ", accessor: "fingresonom" },
				{ Header: "Código cuenta: ", accessor: "ctacod" },
				{ Header: "Nombre cuenta", accessor: "ctanom" },
				{ Header: "Presupuesto inicial", accessor: "apropiacion" },
				{ Header: "Presupuesto definitivo", accessor: "apropiacionFinal" },
				{ Header: "Monto recaudado", accessor: "recaudo" },
			];
		} else {
			setTituloInforme("Gastos");
			columns = [
				{ Header: "Código establecimiento", accessor: "codInstitucion" },
				{ Header: "Año", accessor: "anio" },
				{ Header: "Trimestre", accessor: "trimestre" },
				{ Header: "Fuente de ingreso", accessor: "fingresocod" },
				{ Header: "Nombre fuente de ingreso ", accessor: "fingresonom" },
				{ Header: "Código item detalle", accessor: "sifsedetcod" },
				{ Header: "Nombre item detalle", accessor: "sifsedetnom" },
				{ Header: "Código cuenta: ", accessor: "ctacod" },
				{ Header: "Nombre cuenta", accessor: "ctanom" },
				{ Header: "Presupuesto inicial", accessor: "apropiacion" },
				{ Header: "Presupuesto definitivo", accessor: "apropiacionFinal" },
				{ Header: "Compromisos", accessor: "registro" },
				{ Header: "Obligaciones", accessor: "obligacion" },
				{ Header: "Pago", accessor: "pago" },
			];
		}
		setColumnas(columns);
		getCabecerasP(0);
		getYears();
	}, [naturaleza]);
	const visualizarInforme = async () => {
		setBanCarga(1)
		if (planp != null && year != null && trimestre != null ) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			
			const result = await axios.get(
				`${route_informesPresupuesto}/ejecucionsifse/${planp},${year},${trimestre},${naturaleza}`,
				config
			);
			setData(result.data);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los datos",
				showConfirmButton: false,
				timer: 1500,
			});
		}
		setBanCarga(0)
	};
	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			console.log(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`
			);
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));
			console.log(formattedOptions);

			setCabecerasPlanP(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de ejecuciones SIFSE {"("+tituloInforme+")"} </h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-4">
						<Select
							styles={customStyles}
							isSearchable="true"
							placeholder="Plan presupuestal"
							isClearable="true"
							options={cabecerasPlanP}
							onChange={(e) => {
								if (e) {
									setPlanp(e.value);
								}
							}}
						></Select>
					</div>
					<div className="col-md-3">
						<Select
							styles={customStyles}
							placeholder="Año"
							onChange={(e) => {
								if (e) {
									setYear(e.label);
								}
							}}
							options={years.map((rol) => ({
								label: rol.year,
								value: rol.id,
							}))}
							classNamePrefix="select"
						/>
					</div>
					<div className="col-md-3">
						<Select
							styles={customStyles}
							placeholder="Trimestre"
							onChange={(e) => {
								if (e) {
									setTrimestre(e.value);
								}
							}}
							options={[{value:1,label:"Trimestre 1 (Ene - Mar)"},
								{value:2,label:"Trimestre 2 (Abr - Jun)"},
								{value:3,label:"Trimestre 3 (Jul - Sep)"},
								{value:4,label:"Trimestre 4 (Oct - Dic)"}
							]}
							classNamePrefix="select"
						/>
					</div>
					<div className="col-md-2">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
						data={data}
						columns={columnas}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: tituloInforme,
							imagen: establishment.image,
							fechas: "Trimestre: " + trimestre,
							generado: "Fecha generación: " + FechaYMDHMS,
						}}
					/>
					
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
}
