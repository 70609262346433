import React from "react";
import Footer from "./Footer";
import ImgMission from "../../../images/home/mission.png";
import Barra1 from "../home/Barra1";

export default function Mission() {
	return (
		<>
			<Barra1 />
			<section>
				<div className="image-container">
					<img src={ImgMission} className="slidesImage" alt="Mi imagen" />
				</div>
			</section>

			<Footer />
		</>
	);
}
