import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
	route_user_list,
	route_user_deactive,
} from "../../../api-routes/RoutesLogin";
import { AuthContext } from "../../context/LogContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function SearchUser() {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState("");
	const [userDetail, setUserDetail] = useState({});
	const { establishment,getToken } = useContext(AuthContext);
	const searcher = (e) => {
		setSearch(e.target.value);
	};
	const navigate = useNavigate();

	//metodo de filtrado 2
	const results = !search
		? users
		: users.filter(
				(dato) =>
					(dato.name + " " + dato.lastName)
						.toLowerCase()
						.trim()
						.includes(search.toLocaleLowerCase()) ||
					dato.email
						.toLowerCase()
						.trim()
						.includes(search.toLocaleLowerCase())
		  );

	const handleUserDetail = (userId) => {
		const userDet = users.find((p) => p.id === userId);
		setUserDetail(userDet);
	};

	const handleUserEdit = (userId) => {
		navigate("/menu/hefestoAdmin/register-users/" + userId);
	};

	const handleDelete = (userId) => {
		Swal.fire({
			title: "Eliminar Usuario?",
			text: "El usuario no tendrá acceso al sistema!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteUser(userId)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El usuario ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									loadUsers();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el usuario",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteUser = (userId) => {
		let values = {
			id: userId,
			active: false,
			user:parseInt(localStorage.getItem("idUser"))
		};
		let dataJson = JSON.stringify(values);
		return axios
			.post(route_user_deactive, dataJson, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			})

			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				throw error;
			});
	};

	const loadUsers=()=>{
		setLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		axios
			.get(route_user_list+establishment.id+","+true, config)
			.then((response) => {
				setUsers(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(true);
			});
	}

	useEffect(() => {
		loadUsers();
	
	}, []);

	return (
		<div className="containerFormUser">
			<h1>Lista de usuarios</h1>
			<input
				className="form-control"
				value={search}
				onChange={searcher}
				placeholder="Busqueda de usuarios"
			></input>

			<div>
				{loading ? (
					<div className="spinner-border" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				) : (
					<table id="tableUsers" className="table table-striped table-hover">
						<thead>
							<tr>
								<th style={{ width: "40%", textAlign: "center" }}>Nombre</th>
								<th style={{ width: "40%" }}>Email</th>

								<th></th>
							</tr>
						</thead>
						<tbody>
							{results.map((user) => (
								<tr key={user.id} >
									<td style={{ width: "40%", textAlign: "center" }}>
										{user.name} {user.lastName}
									</td>
									<td style={{ width: "40%", textAlign: "center" }}>
										{user.email}
									</td>
									<td style={{ width: "40%", textAlign: "right" }}>
										<button
											className="btnDetail"
											type="button"
											data-bs-toggle="modal"
											data-bs-target="#staticBackdrop"
											onClick={() => handleUserDetail(user.id)}
										>
											<i className="fas fa-info-circle"></i>
										</button>
										&nbsp;&nbsp;
										<button
											className="btnEdit"
											type="button"
											onClick={() => handleUserEdit(user.id)}
										>
											<i className="fas fa-pen"></i>
										</button>
										&nbsp;&nbsp;
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(user.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			{/* Modal de informacin de usuarios */}
			<div
				className="modal fade"
				id="staticBackdrop"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<center>Detalle de usuario</center>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							{userDetail ? (
								userDetail.image ? (
									<img
										className="avatar"
										src={`data:image/jpg;base64,${userDetail.image}`}
										alt="Avatar"
									/>
								) : (
									<i
										className="fas fa-user"
										style={{ fontSize: "120px", color: "#729fcf" }}
									></i>
								)
							) : (
								""
							)}
							<h2 className="name">
								{userDetail.name} {userDetail.lastName}{" "}
							</h2>
							<div className="table-container">
								<table id="tableInfo">
									<tr>
										<th colSpan={2} style={{ textAlign: "center" }}>
											INFORMACIÓN
										</th>
									</tr>
									<tr>
										<td>Email</td>
										<td>{userDetail.email}</td>
									</tr>
									<tr>
										<td>Telefóno</td>
										<td>{userDetail.phoneNumber}</td>
									</tr>
									<tr>
										<th colSpan={2} style={{ textAlign: "center" }}>
											ROLES
										</th>
									</tr>
									{userDetail.roles
										? userDetail.roles.map((role) => (
												<tr>
													<td colSpan={2} style={{ textAlign: "center" }}>
														{role.rolesxrol.name} - {role.rolesxrol.description}
													</td>
												</tr>
										  ))
										: " "}
									<tr>
										<th colSpan={2} style={{ textAlign: "center" }}>
											ESTABLECIMIENTOS
										</th>
									</tr>
									{userDetail.estabs
										? userDetail.estabs.map((estab) => (
												<tr>
													<td colSpan={2} style={{ textAlign: "center" }}>
														{estab.establishmentxuser.identification}{" "}
														{estab.establishmentxuser.name}
													</td>
												</tr>
										  ))
										: " "}
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* ------------------------- */}
		</div>
	);
}
