import React, { useContext } from "react";import { AuthContext } from "../../context/LogContext";


export default function Footer() {
    const { establishment } = useContext(AuthContext);
	return (
		<>
			<footer className="site-footerEstab">
				<p>&copy; <bold>Entidad Registrada:</bold> {establishment.name} <bold> NIT:</bold> {establishment.identification} <bold> Licencia valida :</bold> {establishment.vencimiento}.</p>
			</footer>
		</>
	);
}
