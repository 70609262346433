import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/LogContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate, NavLink } from "react-router-dom";

export default function InfoLogin({ user }) {
	const { logout, setEstablishment, setBanVisualizar } = useContext(
		AuthContext
	);
	const navigate = useNavigate();

	const functionLogout = () => {
		logout();
		setEstablishment(null);
		close();
		navigate("/");
	};
	const close = () => {
		var btn = document.getElementById("btnCloseLogInfo");
		btn.click();
	};

	const functioncta = () => {
		var btn = document.getElementById("btnCloseLogInfo");
		btn.click();
	};
	return (
		<div>
			<div className="profile">
				<center>
					{user.image ? (
						<img src={user.image} alt="Avatar" />
					) : (
						<i
							className="fas fa-user"
							style={{ fontSize: "100px", color: "#729fcf" }}
						></i>
					)}
				</center>
				<div className="">
					<h2>{user.name + " " + user.lastName} </h2>
					<p>{user.email}</p>
				</div>
			</div>
			<div className="divMenuLog">
				<ul>
					<NavLink
						className="navInfo"
						onClick={() => {
							close(); 
							setBanVisualizar(1);
						}}
						to="menu"
					>
						<li>
							<i className="fas fa-list"></i> Inicio
						</li>
					</NavLink>

					<NavLink
						className="navInfo"
						onClick={close}
						data-bs-toggle="modal"
						data-bs-target="#staticBackdrop"
					>
						<li>
							<i className="fas fa-user-edit"></i> Gestion de cuenta
						</li>
					</NavLink>
				</ul>
			</div>
			<button className="logout-button" onClick={functionLogout}>
				<i className="fa-solid fa-right-from-bracket"></i> Cerrar sesion
			</button>
			{/* 			//modal manejo de cuenta */}
		</div>
	);
}
