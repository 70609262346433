import React from "react";
import Footer from "./Footer";
import ImgServices from "../../../images/home/services.png";
import Barra1 from '../home/Barra1'


export default function Services() {
	return (
		<>
			<Barra1 />
			<section>
				<div className="image-container">
					<img src={ImgServices} className="slidesImage" alt="Mi imagen" />
				</div>
			</section>

			<Footer />
		</>
	);
}
