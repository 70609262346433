import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import {
	route_confiGym_servicios,
	route_confiGym_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const customStyles = {
	control: (base) => ({
		...base,
		justifyContent: "Left",
		height: 60,
		minHeight: 35,
	}),
	option: (base) => ({
		...base,
		border: `1px dotted blue`,
	}),
};

const RegisterService = () => {
	const { getToken,inputStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [servicios, setServicios] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const inputServicio = useRef(null);

	const handleSubmit = (values) => {
		saveService(values);
	};
	const saveService = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(route_confiGym_servicios, json, config);
			const successMessage = "Servicio creado exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getServicios();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						"Verifique que el número de identificación no se encuentre registrado",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getServicios = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiGym_base}/getAllServicios/${establishment.id}`,
			config
		);
		setServicios(result.data);
	};
	const handleServiceEdit = (serviceId) => {
		const instructorDet = servicios.find((p) => p.id === serviceId);
		if (formikRef.current) {
			formikRef.current.setValues(instructorDet);
			inputServicio.current.focus();
			inputServicio.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Eliminar Servicio?",
			text: "El servicio no estará disponible!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El servicio ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getServicios();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el servicio",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const handleActive = (servicioId) => {
		Swal.fire({
			title: "Reactivar servicio?",
			text: "El servicio  estará disponible!",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El servicio ha sido reactivado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getServicios();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el servicio",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		let values = {
			id: servicioId,
			active: status,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_confiGym_servicios + "/deactive",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};
	useEffect(() => {
		getServicios();
	}, []);

	const validationSchema = Yup.object().shape({
		servicio: Yup.string().required("Ingrese el nombre del servicio"),
		descripcion: Yup.string().required("Ingrese la descripción"),
	});

	const initialValues = {
		id: "",
		servicio: "",
		descripcion: "",
		active: true,
		idEstablishment: establishment.id,
	};
	return (
		<div className="containerFormUser">
			<h1>Registro de servicios</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										innerRef={inputServicio}
										type="text"
										name="servicio"
										id="servicio"
										className="form-control"
										placeholder="Servicio"
									/>
									<label htmlFor="name" className="form-label">
										Servicio
									</label>
									<ErrorMessage
										name="servicio"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="descripcion"
										id="descripcion"
										className="form-control"
										placeholder="Descripción"
									/>
									<label htmlFor="name" className="form-label">
										Descripcion
									</label>
									<ErrorMessage
										name="descripcion"
										component="div"
										className="error"
									/>
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
						&nbsp;
						<button type="reset" ref={btnCancel} className="btn btn-danger">
							Cancelar
						</button>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de servicios</h1>
			<table
				style={{ width: "100%" }}
				id="tableUsers"
				className="table table-striped table-hover"
			>
				<thead>
					<tr>
						<th>Servicio</th>
						<th>Descripciòn</th>
						<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
					</tr>
				</thead>
				<tbody>
					{servicios.map((servicio) => (
						<tr key={servicio.id}>
							<td>{servicio.servicio}</td>
							<td>{servicio.descripcion}</td>

							<td style={{ width: "20%", textAlign: "right" }}>
								{servicio.active ? (
									<>
										<button
											className="btnEdit"
											type="button"
											onClick={(e) => handleServiceEdit(servicio.id)}
										>
											<i className="fas fa-pen"></i>
										</button>
										&nbsp;&nbsp;
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(servicio.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</>
								) : (
									<>
										<button
											className="btn btn-success"
											type="button"
											onClick={() => handleActive(servicio.id)}
										>
											<i class="fas fa-check"></i>
										</button>
									</>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default RegisterService;
