import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_configuration_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { FaLock, FaLockOpen } from "react-icons/fa";
import Select from "react-select";

const Meses = () => {
	const { modulo } = useParams();
	const { getToken, customStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [years, setYears] = useState([]);
	const [year, setYear] = useState([]);
	const [meses, setMeses] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);

	const handleSubmit = (values) => {
		values.modulo = modulo;
		saveYear(values);
	};
	const saveYear = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_configuration_base + "/saveYear",
				json,
				config
			);
			const successMessage = "Año creado correctamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getYears();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "El año ya existe",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};

	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		console.log(
			`${route_configuration_base}/getYears/${establishment.id},${modulo}`
		);
		const result = await axios.get(
			`${route_configuration_base}/getActiveYears/${establishment.id},${modulo}`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};

	const getMonths = async (year) => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		console.log(`${route_configuration_base}/getMonths/${year}`);
		const result = await axios.get(
			`${route_configuration_base}/getMonths/${year}`,
			config
		);
		result.data.sort((a, b) => a.numero - b.numero);
		setMeses(result.data);
		setBanCarga(0)
	};

	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Desea cerrar el mes?",
			text: "No se permitirá ejecutar movimientos en este mes",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Cerrando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El mes ha sido cerrado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getMonths(year);
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al cerrar el mes",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const handleActive = (servicioId) => {
		Swal.fire({
			title: "Abrir mes?",
			text: "Los movimientos estaràn disponibles",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El mes ha sido abierto",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getMonths(year);
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al abrir el año",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		let values = {
			id: servicioId,
			status: status,
			user: localStorage.getItem("idUser"),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_configuration_base + "/months/status",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};
	useEffect(() => {
		getYears();
	}, []);

	const validationSchema = Yup.object().shape({
		year: Yup.number().required("Ingrese el año a crear"),
	});

	const initialValues = {
		id: "",
		year: "",
		status: true,
		establishmentId: establishment.id,
		user: localStorage.getItem("idUser"),
	};
	return (
		<div className="containerFormUser">
			<h1>Gestión Meses</h1>

			<div className="row">
				{/* BLOQUE1 */}
				<div className="col-md-12">
					<Select
						styles={customStyles}
						placeholder="Año"
						onChange={(e) => {
							if (e) {
								setYear(e.value);
								getMonths(e.value);
							}
						}}
						options={years.map((rol) => ({
							label: rol.year,
							value: rol.id,
						}))}
						classNamePrefix="select"
					/>
				</div>
			</div>

			<br></br>
			<h1>Meses</h1>
			{banCarga === 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Número</th>
							<th>Mes</th>
							<th>Estado</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{meses.map((servicio) => (
							<tr key={servicio.id}>
								<td>{servicio.numero}</td>
								<td>{servicio.nombre}</td>
								<td>
									{servicio.status ? (
										<>
											<h5 style={{ color: "green" }}>Abierto</h5>
										</>
									) : (
										<>
											<h5 style={{ color: "red" }}>Cerrado</h5>
										</>
									)}
								</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									{servicio.status ? (
										<>
											<button
												title="Cerrar Año"
												className="btnDelete"
												type="button"
												onClick={() => handleDelete(servicio.id)}
											>
												<FaLock />{" "}
											</button>
										</>
									) : (
										<>
											<button
												title="Abrir año"
												className="btn btn-success"
												type="button"
												onClick={() => handleActive(servicio.id)}
											>
												<FaLockOpen />
											</button>
										</>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
		</div>
	);
};

export default Meses;
