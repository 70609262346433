import React from "react";
//import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';
import {
	PDFViewer,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from "@react-pdf/renderer";

function ComprobantePagoPDF({ establishment, suscripcion, pago }) {
	const styles = StyleSheet.create({
		page: {
			padding: 20,
		},
		title: {
			fontSize: 24,
			marginBottom: 5,
			textAlign: "center",
		},
		tableContainer: {
			fontSize: 14,
			marginTop: 10,
			marginBottom: 10,
			overflow: "hidden", // Evita que los elementos desborden el contenedor
		},
		tableRow: {
			display: "flex",
			flexDirection: "row",
		},
		tableTitle: {
			flex: 1,
			padding: 5,
			backgroundColor: "#F5F5F5", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
			fontWeight: "bold",
			maxWidth: "30%",
		},
		header: {
			textAlign: "center",
			fontSize: 9,
		},

		tableCell: {
			flex: 1,
			padding: 5,
			backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		tableCellNumber: {
			flex: 1,
			textAlign: "right",
			padding: 5,
			backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		cabecera: {
			fontSize: 20,
			fontWeight: "bold",
			textAlign: "center",
			marginBottom: "10px",
			marginTop: "10px",
		},
		footer: {
			bottom: -5,
			left: 0,
			right: 0,
			textAlign: "left",
			fontSize: 10,
			paddingTop: 10,
			paddingBottom: 10,
		},
		rowFirma: {
			flex: 1,
			padding: 5,
			backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
			height: "80px",
		},
		containerTabNo: {
			alignItems: "flex-end",
			textAlign: "right",
		},
		tableNo: {
			width: "150px",
			textAlign: "center",
			padding: 5,
			backgroundColor: "#FFFFFF", // Fondo blanco para las celdas
			borderWidth: 1,
			borderColor: "#000000", // Color del borde de las celdas
		},
		anulado: {
			position: "absolute",
			top: "20%",
			textAlign: "center",
			display: "inline-block",
			color: "rgba(255,0,0,0.5)",
			transform: "rotate(-45deg)",
			fontSize: "100",
		},
		txtAnula:{
			color: "rgba(255,0,0,0.5)",
			fontSize: "15",
		},
		logo: {
			position: "absolute",
			top: 20, // Ajusta este valor según sea necesario
			left: 20, // Ajusta este valor según sea necesario
			width: 100, // Ajusta el ancho de la imagen según sea necesario
			height: 100, // Ajusta la altura de la imagen según sea necesario
		},
	});

	return (
		<PDFViewer
			style={{ width: "100%", height: "100vh" }}
			className="pdf-viewer"
		>
			<Document>
				<Page size="A4" style={styles.page}>
					{/* Cabecera */}
					<Image
						style={styles.logo}
						//src={`data:image/png;base64,${establishment.image}`}
						src={establishment.image}
					/>
					<Text style={styles.title}>{establishment.name}</Text>
					<Text style={styles.header}>
						{establishment.address}-{establishment.phone}{" "}
					</Text>
					<Text style={styles.header}>{establishment.email}</Text>
					{/* seccion de usuarios */}
					<View style={styles.containerTabNo}>
						<View style={styles.tableNo}>
							<Text>Recibo No: {pago.id}</Text>
						</View>
					</View>

					{/* ---- nombres */}
					<View style={styles.tableContainer}>
						<View style={styles.tableRow}>
							{/* --- apellidos --- */}
							<View style={styles.tableTitle}>
								<Text>Fecha pago</Text>
							</View>
							<View style={styles.tableCell}>
								<Text>{pago.fecha}</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							{/* --- apellidos --- */}
							<View style={styles.tableTitle}>
								<Text>Recibi de:</Text>
							</View>
							<View style={styles.tableCell}>
								<Text>
									{suscripcion.terceroObject
										? suscripcion.terceroObject.name1 +
										  " " +
										  suscripcion.terceroObject.name2 +
										  " " +
										  suscripcion.terceroObject.lastName1 +
										  " " +
										  suscripcion.terceroObject.lastName2
										: ""}
								</Text>
							</View>
						</View>

						<View style={styles.tableRow}>
							<View style={styles.tableTitle}>
								<Text>No. de identificaión:</Text>
							</View>
							<View style={styles.tableCell}>
								<Text>
									{suscripcion.terceroObject
										? suscripcion.terceroObject.identification
										: ""}
								</Text>
							</View>
						</View>

						<View style={styles.tableRow}>
							<View style={styles.tableTitle}>
								<Text>La suma de:</Text>
							</View>
							<View style={styles.tableCellNumber}>
								<Text>{pago.valor ? pago.valor.toLocaleString() : ""}</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableTitle}>
								<Text>Por concepto de:</Text>
							</View>
							<View style={styles.tableCell}>
								<Text>
									{suscripcion.planObject ? suscripcion.planObject.plan : "--"}
								</Text>
							</View>
						</View>

						<View style={styles.tableRow}>
							<View style={styles.tableTitle}>
								<Text>Método de pago:</Text>
							</View>
							<View style={styles.tableCell}>
								<Text>{pago.formaPObject ? pago.formaPObject.forma : ""}</Text>
							</View>
						</View>
						<View style={styles.tableRow}>
							<View style={styles.tableTitle}>
								<Text>TOTAL:</Text>
							</View>
							<View style={styles.tableCellNumber}>
								<Text>{pago.valor ? pago.valor.toLocaleString() : ""}</Text>
							</View>
						</View>
					</View>
					<View style={styles.tableContainer}>
						<View style={styles.tableRow}>
							<View style={styles.rowFirma}>
								<Text>Firma recibe</Text>
							</View>
							<View style={styles.rowFirma}>
								<Text>Firma entrega</Text>
							</View>
						</View>
					</View>
					<View style={styles.footer}>
						<Text>{`Fecha y hora de expedición: ${new Date().toLocaleString()}`}</Text>
					</View>
					<View style={styles.anulado}>
						<Text>{pago.active === false ? "ANULADO" : ""} </Text>
					</View>
					<View style={styles.txtAnula}>
						<Text>{pago.active === false ? "Motivo: "+pago.motivoAnula : ""} </Text>
						<Text>{pago.active === false  ? "Fecha: "+pago.fechaAnula : ""} </Text>

					</View>

				</Page>
			</Document>
		</PDFViewer>
	);
}

export default ComprobantePagoPDF;
