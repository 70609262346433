import React, { useState, useEffect } from "react";
import {
	useTable,
	useSortBy,
	useGroupBy,
	usePagination,
	useFilters,
} from "react-table";
import { BsFiletypePdf } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // Importa saveAs desde file-saver
import { MdCurrencyExchange } from "react-icons/md";
import { FaSheetPlastic } from "react-icons/fa6";
import { MdOutlineFindInPage } from "react-icons/md";
import { FaFolderOpen } from "react-icons/fa6";
import { FaFolderClosed } from "react-icons/fa6";
import Select from "react-select";

const MyTable = ({
	data,
	columns,
	encabezado,
	onClickButton,
	onClickPagination,
	numPages,
	numPagesO,
	disabled,
	onClickDelete,
}) => {
	
	const [expandedRows, setExpandedRows] = useState([]);
	const [estadosM, setEstadosM] = useState([]);
	const exportToExcel = async () => {
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		const result = data.map((item) => {
			const row = {};
			columns.forEach((header) => {
				const fieldName = header.accessor;
				const headerName = header.Header;
				row[headerName] = item[fieldName];
			});
			return row;
		});

		// Crear un nuevo libro de Excel
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("Sheet 1");

		// Establecer el título en la celda A1
		const titleCell = worksheet.getCell("D2");
		titleCell.value = encabezado.empresa;
		titleCell.font = { size: 12, bold: true };
		titleCell.alignment = { vertical: "middle", horizontal: "left" };

		// Establecer otras celdas de encabezado
		const nitCell = worksheet.getCell("D3");
		nitCell.value = encabezado.nit;
		nitCell.font = { size: 9, bold: false };

		const informeCell = worksheet.getCell("D4");
		informeCell.value =  encabezado.titulo;
		informeCell.font = { size: 12, bold: true };

		const fechasCell = worksheet.getCell("D5");
		fechasCell.value = encabezado.fechas;
		fechasCell.font = { size: 9, bold: false };

		const generadoCell = worksheet.getCell("D6");
		generadoCell.value = encabezado.generado;
		generadoCell.font = { size: 9, bold: false };

		// Insertar la imagen (si informe.imagen es una URL válida)
		if (encabezado.imagen) {
			const imageId = workbook.addImage({
				base64: encabezado.imagen,
				extension: "png",
			});

			worksheet.addImage(imageId, "A1:B6"); // Ajusta el rango según tu necesidad
		}
		// generamos dos filas de espaio en blanco
		const espacioCell = worksheet.getCell("D7");
		//generadoCell.value = "";
		const espacioCell2 = worksheet.getCell("D8");
		//generadoCell.value = "";

		columns.forEach((column, index) => {
			worksheet.getColumn(index + 1).width = 12; // Index + 1 porque el índice de las columnas comienza en 1
		});

		// Insertar el encabezado de las columnas en la fila 8 (después de las celdas de encabezado)
		const headerRow = worksheet.addRow(headers);
		// Establecer bordes delgados y fondo de color azul claro para las celdas de encabezado
		headerRow.eachCell((cell) => {
			cell.width = 50;
			cell.font = { size: 10, bold: true };
			cell.alignment = { vertical: "middle", horizontal: "center" };
			cell.border = {
				top: { style: "thin" },
				left: { style: "thin" },
				bottom: { style: "thin" },
				right: { style: "thin" },
			};
			cell.fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "83a9f0" }, // Código de color para azul claro
			};
		});

		// Insertar los datos a partir de la fila 9
		result.forEach((row) => worksheet.addRow(Object.values(row)));

		// Guardar el libro de Excel como un archivo
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		saveAs(blob, encabezado.titulo + ".xlsx");
	};

	const exportToPDF = () => {
		const unit = "pt";
		const size = "legal";
		const orientation = "landscape";

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(12);

		// Agregar la imagen al encabezado
		if (encabezado.imagen) {
			doc.addImage(encabezado.imagen, "png", marginLeft, 30, 100, 100); // Ajusta las coordenadas y dimensiones según tu necesidad
		}

		const title = encabezado.titulo;
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		// Agregar el título al encabezado
		doc.text(encabezado.empresa, marginLeft + 120, 40);
		doc.text(encabezado.nit, marginLeft + 120, 60);
		doc.text(encabezado.titulo, marginLeft + 120, 80);
		doc.text(encabezado.fechas, marginLeft + 120, 100);
		doc.text(encabezado.generado, marginLeft + 120, 120);

		doc.autoTable({
			startY: 140,
			head: [headers],
			body: dataRows,
			styles: {
				fontSize: 10,
				cellPadding: 3,
				cellWidth: "auto",
				halign: "left",
				valign: "middle",
			},
		});

		doc.save(encabezado.titulo + ".pdf");
	};
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setFilter,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: numPages },
		},
		useFilters,
		useGroupBy,
		useSortBy,
		usePagination
	);

	const handleFilterChange = (columnId, event) => {
		const value = event.target.value || undefined;
		setFilter(columnId, value);
	};

	const handleToggleRow = (rowId, ban) => {
		var status = "";
		if (ban == 1) {
			status = "none";
		} else {
			status = "";
		}
		const arrayHijos = obtenerIdsHijosDirectosYDescendientes(rowId, data);
		var cambiosEstado = estadosM;
		arrayHijos.forEach((h) => {
			const fila = document.getElementById(h);
			if (fila) {
				console.log(fila);
				console.log(status);
				fila.style.display = status;
			}
		});
		var cambiosEstado = estadosM.map((objeto) =>
			objeto.id === rowId ? { ...objeto, status: !objeto.status } : objeto
		);
		setEstadosM(cambiosEstado);
	};

	const obtenerIdsHijosDirectosYDescendientes = (
		idPadre,
		datos,
		resultado = []
	) => {
		const hijosDirectos = datos.filter((item) => item.madre === idPadre);
		hijosDirectos.forEach((hijoDirecto) => {
			resultado.push(hijoDirecto.id);
			obtenerIdsHijosDirectosYDescendientes(hijoDirecto.id, datos, resultado);
		});
		return resultado;
	};

	function buscarPorId(id) {
		var obj = estadosM.find((item) => item.id === id);
		if (obj) {
			return obj.status;
		} else {
			return null;
		}
	}

	useEffect(() => {
		const filteredArray = data.filter((item) => item.tipo == 1);
		const newArray = filteredArray.map((item) => {
			// Creamos un nuevo objeto con las propiedades del objeto original
			const newItem = {
				id: item.id, // Puedes asignar el valor que desees para el estado
				status: true, // Asignamos 1 por defecto para el status
			};
			return newItem;
		});
		setEstadosM(newArray);
	}, []);

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-start" }}>
				<button
					className="btnInfo"
					onClick={exportToExcel}
					title="Generar Excel"
				>
					<FaFileExcel size={20} />
				</button>
				&nbsp;
				<button className="btnInfo" onClick={exportToPDF} title="Generar PDF">
					<BsFiletypePdf size={20} />
				</button>
				&nbsp;
				<button
					className="btnInfo"
					type="button"
					data-bs-toggle="modal"
					data-bs-target="#modalPdf"
				>
					<MdOutlineFindInPage size={20} />
				</button>
				&nbsp;
				<Select
					isClearable={true}
					//styles={customStyles}
					value={numPagesO}
					//placeholder="Plan presupuestal"
					onChange={(e) => {
						if (e) {
							
							onClickPagination(e.value)
						}
					}}
					options={[
						{ value: 10, label: "10" },
						{ value: 50, label: "50" },
						{ value: 100, label: "100" },
						{ value: 150, label: "150" },
						{ value: 200, label: "200" },
						{ value: 300, label: "300" },
						{ value: 10000, label: "Todos" },
					]}
				/>
			</div>
			<div>
				<table className="reactTables" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
										<div>
											{column.canFilter ? (
												<input
													type="text"
													value={column.filterValue || ""}
													onChange={(e) => handleFilterChange(column.id, e)}
												/>
											) : null}
										</div>
									</th>
								))}
								<th>Opciones</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							const isExpanded = expandedRows.includes(row.id);

							return (
								<React.Fragment key={row.id}>
									<tr {...row.getRowProps()} id={row.original.id}>
										{row.cells.map((cell) => (
											<td
												key={cell.column.id}
												style={{
													backgroundColor: row.original.color,
													paddingLeft:
														cell.column.id === row.cells[0].column.id
															? row.original.codigo.split(".").length * 5
															: 0,
													textAlign:
														cell.column.id === row.cells[4].column.id
															? "right"
															: "left",
												}}
												{...cell.getCellProps()}
											>
												{/* Icono folder o hoja */}
												{cell.column.id === row.cells[0].column.id && (
													<>
														{row.original.tipo === 1 &&
															buscarPorId(row.original.id) === true && (
																<FaFolderOpen
																	className="text-primary"
																	onClick={() =>
																		handleToggleRow(row.original.id, 1)
																	}
																	style={{ cursor: "pointer" }}
																/>
															)}
														{/* ----------------------------------------- */}
														{row.original.tipo === 1 &&
															buscarPorId(row.original.id) === false && (
																<FaFolderClosed
																	className="text-primary"
																	onClick={() =>
																		handleToggleRow(row.original.id, 2)
																	}
																	style={{ cursor: "pointer" }}
																/>
															)}
														{/* -------------------------------------- */}
														{row.original.tipo === 2 && <FaSheetPlastic />}
														&nbsp;&nbsp;
													</>
												)}
												{/* Contenido de la celda */}
												{cell.render("Cell")} {}
											</td>
										))}
										<td style={{ width: "5%", textAlign: "right" }}>
											<>
												{row.original.tipo === 2 && (
													<>
														<button
															className="btnInfo"
															type="button"
															onClick={() => {
																onClickButton(row.original);
															}}
															title="Asociar SIA- SIFSE"
															data-bs-toggle="modal"
															data-bs-target="#modalSIA"
														>
															<MdCurrencyExchange />
														</button>
														&nbsp;
													</>
												)}
												{/* <button
													className="btnEdit"
													type="button"
													disabled={disabled}
													onClick={() => onClickEdit(row.original)}
												>
													<i className="fas fa-pen"></i>
												</button>
												&nbsp;
												<button
													className="btnDelete"
													type="button"
													disabled={disabled}
													onClick={() => onClickDelete(row.original.id)}
												>
													<i className="fas fa-trash-alt"></i>
												</button> */}
											</>
										</td>
									</tr>
								</React.Fragment>
							);
						})}
					</tbody>
				</table>
				<div>
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{"<<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{"<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{">"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{">>"}
					</button>
					&nbsp;
					<span>
						Página{" "}
						<strong>
							{pageIndex + 1} de {pageOptions.length}
						</strong>{" "}
					</span>
				</div>
			</div>
		</>
	);
};

export default MyTable;
