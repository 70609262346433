import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import axios from "axios";
import {
	route_typeid_list,
	route_confiGym_base,
} from "../../../api-routes/RoutesLogin";
import { AuthContext } from "../../context/LogContext";
import Swal from "sweetalert2";
import * as Yup from "yup";

const customStyles = {
	control: (base) => ({
		...base,
		justifyContent: "Left",
		height: 60,
		minHeight: 35,
	}),
	option: (base) => ({
		...base,
		border: `1px dotted blue`,
	}),
};
const RegisterInstructor = () => {
	const { getToken, inputStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [instructors, setInstructors] = useState([]);
	const [instructorDetail, setInstructorDetail] = useState({});
	const buttonRef = useRef(null);
	const formikRef = useRef(null);

	const handleSubmit = (values) => {
		saveInstructor(values);
	};

	const saveInstructor = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(route_confiGym_base, json, config);
			const successMessage = "Instructor creado exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				buttonRef.current.click();
				getInstructors();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						"Verifique que el número de identificación no se encuentre registrado",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};

	const getInstructors = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiGym_base}/getAllInstructors/${establishment.id}`,
			config
		);
		setInstructors(result.data);
	};

	const handleInstructorDetail = (instructorId) => {
		const instructorDet = instructors.find((p) => p.id === instructorId);
		setInstructorDetail(instructorDet);
	};

	const handleInstructorEdit = (instructorId) => {
		const instructorDet = instructors.find((p) => p.id === instructorId);
		if (formikRef.current) {
			formikRef.current.setValues(instructorDet);
		}
	};

	const handleDelete = (instructorId) => {
		Swal.fire({
			title: "Eliminar Instructor?",
			text: "El instructor no estará disponible!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteInstructor(instructorId, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El instructor ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getInstructors();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el instructor",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const handleActive = (instructorId) => {
		Swal.fire({
			title: "Reactivar Instructor?",
			text: "El instructor  estará disponible!",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteInstructor(instructorId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El instructor ha sido reactivado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getInstructors();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el instructor",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteInstructor = async (instructorId, status) => {
		let values = {
			id: instructorId,
			active: status,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_confiGym_base + "/deactive",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		getInstructors();
	}, []);

	const validationSchema = Yup.object().shape({
		nombre1: Yup.string().required("El primer nombre es obligatorio"),
		apellido1: Yup.string().required("El apellido 1 es obligatorio"),
		identificacion: Yup.string().required("La identificación es obligatoria"),
		telefono: Yup.string().matches(
			/^[0-9]+$/,
			"El teléfono debe contener solo números"
		),
		email: Yup.string().email("El correo electrónico debe ser válido"),
	});

	const initialValues = {
		id: "",
		nombre1: "",
		nombre2: "",
		apellido1: "",
		apellido2: "",
		identificacion: "",
		telefono: "",
		email: "",
		idEstablishment: establishment.id,
		active: true,
	};

	return (
		<>
			<div className="containerFormUser">
				<h1>Registro de instructores</h1>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					innerRef={formikRef}
				>
					{(formik, props) => (
						<Form id="formInstrucor">
							<div className="row">
								{/* BLOQUE1 */}
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="nombre1"
											id="nombre1"
											className="form-control"
											placeholder="Primer nombre"
										/>
										<label htmlFor="name" className="form-label">
											Primer nombre
										</label>
										<ErrorMessage
											name="nombre1"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="nombre2"
											id="nombre2"
											className="form-control"
											placeholder="Segundo nombre"
										/>
										<label htmlFor="nombre2" className="form-label">
											Segundo nombre
										</label>
										<ErrorMessage
											name="nombre2"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="apellido1"
											id="apellido1"
											className="form-control"
											placeholder="Primer apellido"
										/>
										<label htmlFor="nombre2" className="form-label">
											Primer apellido
										</label>
										<ErrorMessage
											name="apellido1"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="apellido2"
											id="apellido2"
											className="form-control"
											placeholder="Segundo apellido"
										/>
										<label htmlFor="apellido2" className="form-label">
											Segundo apellido
										</label>
										<ErrorMessage
											name="apellido2"
											component="div"
											className="error"
										/>
									</div>
								</div>
								{/* BLOQUE 2 */}

								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="identificacion"
											id="identificacion"
											className="form-control"
											placeholder="Identificación"
										/>
										<label htmlFor="identificacion" className="form-label">
											Identificación
										</label>
										<ErrorMessage
											name="identificacion"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="telefono"
											id="telefono"
											className="form-control"
											placeholder="telefono"
										/>
										<label htmlFor="telefono" className="form-label">
											Teléfono
										</label>
										<ErrorMessage
											name="telefono"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="email"
											name="email"
											id="email"
											className="form-control"
											placeholder="email"
										/>
										<label htmlFor="email" className="form-label">
											Email
										</label>
										<ErrorMessage
											name="email"
											component="div"
											className="error"
										/>
									</div>
								</div>
							</div>
							<button type="submit" className="btn btn-primary">
								Guardar
							</button>
							&nbsp;
							<button type="reset" ref={buttonRef} className="btn btn-danger">
								Cancelar
							</button>
							<Field
								type="hidden"
								id="idEstablishment"
								name="idEstablishment"
							/>
						</Form>
					)}
				</Formik>
				<br></br>
				<h1>Lista de instructores</h1>
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Identificación</th>
							<th>Nombres</th>
							<th>Apellidos</th>
							<th>Teléfono</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{instructors.map((instructor) => (
							<tr key={instructor.id}>
								<td>{instructor.identificacion}</td>
								<td>
									{instructor.nombre1} {instructor.nombre2}
								</td>
								<td>
									{instructor.apellido1} {instructor.apellido2}
								</td>
								<td>{instructor.telefono}</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									{instructor.active ? (
										<>
											<button
												className="btnDetail"
												type="button"
												data-bs-toggle="modal"
												data-bs-target="#staticBackdrop1"
												onClick={() => handleInstructorDetail(instructor.id)}
											>
												<i className="fas fa-info-circle"></i>
											</button>
											&nbsp;&nbsp;
											<button
												className="btnEdit"
												type="button"
												onClick={(e) => handleInstructorEdit(instructor.id)}
											>
												<i className="fas fa-pen"></i>
											</button>
											&nbsp;&nbsp;
											<button
												className="btnDelete"
												type="button"
												onClick={() => handleDelete(instructor.id)}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</>
									) : (
										<>
											<button
												className="btn btn-success"
												type="button"
												onClick={() => handleActive(instructor.id)}
											>
												<i class="fas fa-check"></i>
											</button>
										</>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{/* modal detalle instructor */}
			<div
				className="modal fade"
				id="staticBackdrop1"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<center>Detalle de instructor</center>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className=" modal-body">
							<center>
								<i
									className="fas fa-user"
									style={{
										textAlign: "center",
										fontSize: "60px",
										color: "#729fcf",
									}}
								></i>
								<h4 className="name">
									{instructorDetail.nombre1} {instructorDetail.nombre2}{" "}
									{instructorDetail.apellido1} {instructorDetail.apellido2}
								</h4>
							</center>

							<table style={{ width: "100%" }} id="tableInfo">
								<tr>
									<th>Identificación</th>
									<td>{instructorDetail.identificacion}</td>
								</tr>
								<tr>
									<th>Teléfono </th>
									<td>{instructorDetail.telefono}</td>
									<th>Email </th>
									<td>{instructorDetail.email}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegisterInstructor;
