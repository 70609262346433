import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import { route_confiPresupuesto_base } from "../../../api-routes/RoutesLogin";
import Select from "react-select";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { BsFiletypeTxt } from "react-icons/bs";

export default function PlanPxPlano() {
	const { getToken, customStyles, inputStyles, establishment } =
		useContext(AuthContext);
	const [cabeceras, setCabeceras] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [errores, setErrores] = useState([]);
	const [estructura, setEstructura] = useState([]);
	const [estructura2, setEstructura2] = useState([]);
	const [file, setFile] = useState(null);
	const [banCarga, setBanCarga] = useState(0);

	const inputRef = useRef(null);

	const getCabeceras = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getcabeceras/${establishment.id}`,
			config
		);

		setCabeceras(result.data);
	};

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleFileRead = () => {
		setErrores([]);
		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			const data = event.target.result;
			const workbook = XLSX.read(data, { type: "binary" });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			if (inputRef.current) {
				inputRef.current.value = "";
			}
			var jsonDataClean = cleanJsonData(jsonData);
			var response = validarXlsx(jsonDataClean);
			if (response) {
				cargarPlano(jsonData);
			} else {
				setBanCarga(0);
			}
		};

		fileReader.readAsBinaryString(file);
	};

	const handleImport = () => {
		setBanCarga(1);
		if (file && selectedOption) {
			var c = cabeceras.find((objeto) => objeto.id === selectedOption.value);
			if (c.proyeccion && c.proyeccion.length == 0) {
				handleFileRead();
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "El plan presupuestal tiene cuentas configuradas",
					showConfirmButton: false,
					timer: 1500,
				});
				setBanCarga(0);
			}
		} else {
			Swal.fire({
				icon: "warning",
				title: "Diligencie los campos requeridos",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0);
		}
	};

	const validarXlsx = (jsonData) => {
		var err = [];
		const ctasMadre = jsonData
			.slice(1)
			.filter((item) => item[2] == "1")
			.map((item) => ({
				nivel: item[0].toString().split(".").length,
				codigo: item[0],
			}));

		jsonData.forEach((linea, indice) => {
			console.log("Linea leida: No " + indice + " - " + linea);
			//definimos la estructura dependiendo de la naturaleza de la cuenta
			var arrEstructura = [];
			if (linea[3] == 1) {
				arrEstructura = estructura;
			} else if (linea[3] == 2) {
				arrEstructura = estructura2;
			}
			if (indice > 0) {
				//salta el encabezado
				// Valida que tenga 5 columnas
				if (linea.length !== 5) {
					err.push(arrayErrores(indice, 0, "Campos faltantes"));
				} else {
					for (let i = 0; i < linea.length; i++) {
						//validacion de campos vacios
						if (linea[i] == null) {
							err.push(arrayErrores(indice, i, "Campo vacio"));
						}
						//validacines  campo 1
						if (i == 0) {
							//valida que contenga numeros y puntos
							const regex = /^[0-9.]*$/;
							if (linea[i] && !regex.test(linea[i])) {
								err.push(
									arrayErrores(indice, i, "Datos invalidos en el codigo")
								);
							}
							// validacion niveles correctos
							var arrayNiveles = linea[0].toString().split(".");

							//valida tamaño general del codigo
							if (arrayNiveles.length > arrEstructura.length) {
								err.push(arrayErrores(indice, i, "Error en tamaño del código"));
							} else {
								for (let j = 0; j < arrayNiveles.length; j++) {
									//valida el tamño de cada nivel con respecto a la estructura
									if (
										arrayNiveles[j].length != arrEstructura[j]["caracteres"]
									) {
										err.push(
											arrayErrores(
												indice,
												i,
												"Error en numero de caracteres del nivel " + (j + 1)
											)
										);
									}
								}
								// valido si la cuenta es de detalle tenga una cuenta de titulo corecto
								if (linea[2] == 2) {
									var arr = linea[0].split(".");
									arr.pop();
									const codigo = arr.join(".");
									const encontrado = ctasMadre.some(
										(item) => item.codigo == codigo
									);
									if (encontrado == false) {
										err.push(
											arrayErrores(
												indice,
												i,
												"Cuenta sin cuenta madre registrada"
											)
										);
									}
								}
							}
							//
						}
						//validacines campo tipo
						if (i == 2) {
							const regex = /^[1-2]$/;
							if (linea[2] && !regex.test(linea[2])) {
								err.push(
									arrayErrores(indice, i, "Datos invalidos en tipo de cuenta")
								);
							}
						}
						//validacines campo naturaleza
						if (i == 3) {
							const regex = /^[1-2]$/;
							if (linea[3] && !regex.test(linea[3])) {
								err.push(
									arrayErrores(
										indice,
										i,
										"Datos invalidos en naturaleza de cuenta"
									)
								);
							}
						}
						// validaciones propiacion
						if (i == 4) {
							const regex = /^[0-9.]*$/;
							if (linea[4] && !regex.test(linea[4])) {
								err.push(
									arrayErrores(indice, i, "Datos invalidos en apropiación")
								);
							} else {
								const apr = parseFloat(linea[4]); // o parseFloat(cadena) si quieres convertir a decimal
								if (!isNaN(apr)) {
									if (apr < 0) {
										err.push(
											arrayErrores(
												indice,
												i,
												"Apropiación no puede ser negativo"
											)
										);
									}
								} else {
									err.push(
										arrayErrores(
											indice,
											i,
											"Apropiación no es un campo numérico"
										)
									);
								}
							}
						}
					}
				}
			}
		});
		//validacino de sumas iguales
		const ingresos = jsonData
			.slice(1)
			.reduce((acc, entry) => {
				return entry[3].toString() == "1" ? acc + parseFloat(entry[4]) : acc;
			}, 0)
			.toFixed(2);

		const gastos = jsonData
			.slice(1)
			.reduce((acc, entry) => {
				return entry[3].toString() == "2" ? acc + parseFloat(entry[4]) : acc;
			}, 0)
			.toFixed(2);

		if (ingresos !== gastos) {
			err.push(
				"Diferencia entre ingresos y gastos: Ingresos: $" +
					ingresos +
					" / Gastos: " +
					gastos
			);
		}

		setErrores(err);
		setFile(null);
		if (err.length > 0) {
			return false;
		} else {
			return true;
		}
	};
	function cleanJsonData(jsonData) {
		// Mapea sobre cada elemento del arreglo y limpia los valores
		const cleanedData = jsonData.map((item) => {
			return item.map((value) => {
				// Convierte el valor a string y elimina los espacios al inicio y final
				return String(value).trim();
			});
		});

		return cleanedData;
	}
	function arrayErrores($linea, $campo, $error) {
		var error =
			"línea " + ($linea + 1) + " Campo No " + ($campo + 1) + " : " + $error;
		return error;
	}
	const handleDownload = () => {
		// Crear datos de ejemplo
		const data = [["Codigo", "Nombre", "Tipo", "Naturaleza", "Apropiación"]];

		// Crear una nueva hoja de cálculo
		const ws = XLSX.utils.aoa_to_sheet(data);

		// Crear un libro de trabajo
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

		// Convertir el libro de trabajo a un archivo binario
		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		// Guardar el archivo
		saveAs(
			new Blob([wbout], { type: "application/octet-stream" }),
			"Plan presupuestal.xlsx"
		);
	};
	const getEstructura = async (idE) => {
		const cab = cabeceras.find((item) => item.id === idE);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		//obtener estructura ingresos
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getconf/${cab["estructura"]}`,
			config
		);
		let estructuraOrder = result.data.slice().sort((a, b) => a.nivel - b.nivel);
		setEstructura(estructuraOrder);
		//ontener estructura gastos
		const result2 = await axios.get(
			`${route_confiPresupuesto_base}/getconf/${cab["estructura2"]}`,
			config
		);
		let estructuraOrder2 = result2.data
			.slice()
			.sort((a, b) => a.nivel - b.nivel);
		setEstructura2(estructuraOrder2);
	};
	const cargarPlano = async (json) => {
		const jsonFormat = json.slice(1).map((item) => ({
			plan: selectedOption.value,
			nombre: item[1].trim(),
			codigo: item[0],
			naturaleza: item[3],
			tipo: item[2],
			apropiacion: item[4],
			apropiacionfinal: item[4],
			saldo: item[4],
			codMadre:
				item[0].toString().split(".").length === 1
					? null
					: item[0].slice(0, item[0].lastIndexOf(".")),
		}));
		console.log("Json final " + JSON.stringify(jsonFormat));
		//------------------------- guardar lista
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/savePlanoProyeccion",
				jsonFormat,
				config
			);
			const successMessage = "Plan cargado correctamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				getCabeceras();
				setBanCarga(0);
			});
		} catch (error) {
			console.log(error);
			setBanCarga(0);
		}
	};

	const downloadTxtFile = () => {
		const element = document.createElement("a");
		const file = new Blob([errores.join("\n")], { type: "text/plain" });
		element.href = URL.createObjectURL(file);
		element.download = "errores.txt";
		document.body.appendChild(element);
		element.click();
	};

	useEffect(() => {
		getCabeceras();
	}, []);

	return (
		<div className="containerFormUser">
			<h1>Carga Plan Presupuestal</h1>
			<div className="row">
				<div className="col-md-6  ">
					<div className="form-floating mb-2  ">
						<Select
							isClearable={true}
							styles={customStyles}
							value={selectedOption}
							placeholder="Plan presupuestal"
							onChange={(e) => {
								if (e) {
									setSelectedOption(e);
									getEstructura(e.value);
								} else {
									setSelectedOption(null);
								}
							}}
							options={cabeceras.map((rol) => ({
								label: rol.nombre,
								value: rol.id,
							}))}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<input
						ref={inputRef}
						onChange={handleFileChange}
						type="file"
						className="form-control"
						id="inputGroupFile04"
						aria-describedby="inputGroupFileAddon04"
						aria-label="Upload"
						accept=".xlsx,.xls"
					></input>
				</div>
				<div className="col-md-12">
					<button
						type="button"
						class="btn btn-info"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal"
					>
						Información
					</button>
					&nbsp;
					<button
						type="button"
						className="btn btn-success"
						onClick={handleDownload}
					>
						Plantilla
					</button>
					&nbsp;
					{banCarga == 0 ? (
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleImport}
						>
							Subir
						</button>
					) : (
						<div className="spinner-overlay">
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
						</div>
						/*--------------- */
					)}
				</div>
			</div>
			{/* modañ de informacion */}
			<div
				class="modal fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="exampleModalLabel">
								Información
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<p
								style={{
									textAlign: "left",
									textAlignLast: "",
									whiteSpace: "preWrap",
								}}
							>
								<b>Codigo:</b> Este ampo almacena el código de la cuenta
								presupuestal, separado por puntos.<br></br>
								<b>Nombre:</b> Detalla el nómbre de la cuenta.<br></br>
								<b>Tipo:</b> Especifica si es una cuenta titulo o detalle asi:
								1=Titulo, 2=Detalle. <br></br>
								<b>Naturaleza:</b> Especifica la naturaleza de la cuenta asi:
								1=Dèbito , 2=Crédito.<br></br>
								<b>Apropiación:</b> Debe ir el valor numérico de la apropiacion,
								solo los decimales debes separarse con punto(.)<br></br>
							</p>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Cerrar
							</button>
						</div>
					</div>
				</div>
			</div>
			{/*---------------------  */}
			<br></br>
			<div style={{ textAlign: "left" }} className="text text-danger">
				{errores && errores.length > 0 && (
					<div>
						<label style={{ display: "flex", alignItems: "center" }}>
							<h3 className="text text-danger">Errores </h3> &nbsp;
							<button className="btnInfo" onClick={downloadTxtFile}>
								<BsFiletypeTxt />
							</button>
						</label>

						{errores.map((error, index) => (
							<p key={index}>* {error}</p>
						))}
					</div>
				)}
			</div>
		</div>
	);
}
