import React,{useEffect} from "react";
import { NavLink, Outlet,useNavigate } from "react-router-dom";
import { GiStrong } from "react-icons/gi";
import { CgGym } from "react-icons/cg";
import { GiGymBag } from "react-icons/gi";
import { FaFunnelDollar } from "react-icons/fa";





export default function ConfiguracionGym() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoGym");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="register-instructors">
									<li className="navInfoAdmin">
                                   <GiStrong/> <span> Instructores</span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="register-service">
									<li className="navInfoAdmin">
										<CgGym/>  <span> Servicios</span>
									</li>
								</NavLink>
                                <NavLink className="navInfo" to="register-plan">
									<li className="navInfoAdmin">
									<GiGymBag/>  <span> Planes</span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="register-formaPago">
									<li className="navInfoAdmin">
									<FaFunnelDollar/> <span> Formas de pago</span>
									</li>
								</NavLink>
                                
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}