import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_gym_suscripciones,
	
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";

import TableCartera from "../../componentsBase/tables/TableBaseSum1";

export default function InformacionUsuarios() {
	const [isLoading, setIsLoading] = useState(false);
	const [mesSelected, setMesSelected] = useState(0);
	const [yearSelected, setYearSelected] = useState(0);
	const [estadoSelected, setEstadoSelected] = useState(0);

	const { establishment, getToken, meses, years,FechaYMDHMS,FechaYMD } = useContext(AuthContext);
	const [dataPersons, setDataPersons] = useState([]);
	
    const sumFields = ["saldo"];

	const columns = [
		{ Header: "Usuario", accessor: "tercero" },
		{ Header: "Plan", accessor: "plan" },
		{ Header: "Fecha inicial", accessor: "fechaIni" },
		{ Header: "Fecha final", accessor: "fechaFin" },
		{ Header: "Estado", accessor: "estado" },
        { Header: "Saldo", accessor: "saldo" },
	// ...otras columnas
	];
	const visualizarInforme = async () => {
		
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_gym_suscripciones}/informeCartera/${establishment.id},${mesSelected},${yearSelected},${estadoSelected}`,
			config
		);
		console.log(result.data);
		setDataPersons(result.data);
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de cartera</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-3">
						<Select
							isSearchable="true"
							placeholder="Selecciones mes"
							isClearable="true"
							isLoading={isLoading}
							options={meses}
							onChange={(e) => {
								setMesSelected(e ? e.value : 0);
							}}
						></Select>
					</div>
					<div className="col-md-3">
						<Select
							isSearchable="true"
							placeholder="Selecciones año"
							isClearable="true"
							isLoading={isLoading}
							options={years}
							onChange={(e) => {
								setYearSelected(e ? e.value : 0);
							}}
						></Select>
					</div>
					<div className="col-md-3">
						<Select
							isSearchable={true}
							placeholder="Seleccione estado"
							isClearable={true}
							isLoading={isLoading}
							options={[
								{ value: 1, label: "Activas" },
								{ value: 2, label: "Vencidas" },
								{ value: 3, label: "Pausadas" },
								
							]}
							onChange={(e) => {
								setEstadoSelected(e ? e.value : 0);
							}}
						></Select>
					</div>
					<div className="col-md-2">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
                <TableCartera
						data={dataPersons}
						columns={columns}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: "Cartera",
							imagen: establishment.image,
							fechas: "Cartera generada el: "+FechaYMD  ,
							generado: "Informe generado: "+FechaYMDHMS
	
						}}
						sumFields={sumFields}
					/>
				</div>
			</div>
		</>
	);
}
