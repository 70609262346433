import React, { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../context/LogContext";
import "../../styles/administration/Dashboard.css";
import { route_persons_getById } from "../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";

export default function DashboardAdmin() {
	const { establishment, getToken } = useContext(AuthContext);
	const [info, setInfo] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const chartRef1 = useRef(null); // Referencia para el primer gráfico
	const chartRef2 = useRef(null); // Referencia para el segundo gráfico

	useEffect(() => {
		loadInfo();
	}, []);

	useEffect(() => {
		if (dataLoaded) {
			var color1 = generateRandomColor();
			var color2 = generateRandomColor();
			var color3 = generateRandomColor();
			var color4 = generateRandomColor();
			var color5 = generateRandomColor();
			var color6 = generateRandomColor();
			// Crear el primer gráfico
			createPieChart(
				chartRef1,
				"Gráfico de Torta: Activos vs Inactivos",
				["Activos", "Inactivos"],
				[info.activos, info.inactivos],
				[color1, color2]
			);

			// Crear el segundo gráfico
			createPieChart(
				chartRef2,
				"Gráfico de Torta: Tipos de terceros",
				["Naturales", "Juridicas", "Consorcio", "Unión temporal"],
				[info.naturales, info.juridicas, info.consorcio, info.uniont],
				[color3, color4, color5, color6]
			);
		}
	}, [dataLoaded]);

	const loadInfo = async () => {
		try {
			const response = await axios.get(
				route_persons_getById + "indfodash/" + establishment.id,
				{
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			setInfo(response.data);
			setDataLoaded(true); // Se establece que los datos están cargados
		} catch (error) {
			console.log(error);
			// Manejo de errores
		}
	};
	function generateRandomColor() {
		// Generar un valor hexadecimal aleatorio
		const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
		return randomColor;
	}

	// Función para crear un gráfico de torta
	const createPieChart = (chartRef, title, labels, data, backgroundColor) => {
		const ctx = chartRef.current.getContext("2d");

		new Chart(ctx, {
			type: "pie",
			data: {
				labels: labels,
				datasets: [
					{
						data: data,
						backgroundColor: backgroundColor,
					},
				],
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: "top",
					},
					title: {
						display: false,
						text: title,
					},
				},
			},
		});
	};

	return (
		<div className="containerFormUser">
			<div
				className="row"
				style={{ display: "flex", justifyContent: "center" }}
			>
				<div className="col-5 containerDashboard">
					<label className="titleDash">Estado terceros</label>
					<div className="InfoDashboard">
						<div className="graphContainer">
							<canvas className="canvasContainer" ref={chartRef1} />
						</div>
						{dataLoaded ? (
							<table
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th>Estado</th>
										<th>Cantidad</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Activos</td>
										<td>{info.activos}</td>
									</tr>
									<tr>
										<td>Inactivos</td>
										<td>{info.inactivos}</td>
									</tr>
								</tbody>
							</table>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
							/*--------------- */
						)}
					</div>
				</div>
				<div className="col-5 containerDashboard">
					<label className="titleDash">Tipos de tercero</label>
					<div className="InfoDashboard">
						<div style={{ width: "350px", height: "350px" }}>
							<canvas ref={chartRef2} />
						</div>
						{dataLoaded ? (
							<table
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th>Tipo</th>
										<th>Cantidad</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Naturales</td>
										<td>{info.naturales}</td>
									</tr>
									<tr>
										<td>Jurídicas</td>
										<td>{info.juridicas}</td>
									</tr>
									<tr>
										<td>Consorcio</td>
										<td>{info.consorcio}</td>
									</tr>
									<tr>
										<td>Unión temporal</td>
										<td>{info.uniont}</td>
									</tr>
								</tbody>
							</table>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
							/*--------------- */
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
