import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";

export default function ProtectedRoutes() {
	const { isAuthenticated } = useContext(AuthContext);

	let isLogged = isAuthenticated;
	if (!isLogged) {
		return <Navigate to="/" />;
	}
	return <Outlet />;
}
