import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import {
	route_configuration_base,
	route_confiPresupuesto_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";

const Estructuras = () => {
	const { getToken } = useContext(AuthContext);
	const { establishment, customStyles, inputStyles } = useContext(AuthContext);
	const [nombres, setNombres] = useState([]);
	const [selectedEstuctura, setSelectedEstuctura] = useState([]);
	const [cabeceras, setCabeceras] = useState([]);
	const [years, setYears] = useState([]);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);

	const btnCancel = useRef(null);
	const formikRef = useRef(null);

	const handleSubmit = (values) => {
		setBtnCarga(1);
		saveCabecera(values);
	};
	const saveCabecera = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/saveCabecera",
				json,
				config
			);
			const successMessage = "Plan creado";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getCabeceras();
				setBtnCarga(0);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getEstructuras = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getNombres/${establishment.id}`,
			config
		);
		setNombres(result.data);
	};
	const getCabeceras = async () => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getcabeceras/${establishment.id}`,
			config
		);
		setCabeceras(result.data);
		setBanCarga(0)
	};

	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Eliminar plan presupuestal?",
			text: "El plan presupuestal se eliminará definitivamente y no debe tener configuración activa!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, false)
							.then((response) => {
								console.log(response);
								if (response) {
									var icon = "success";
									var msg = "Plan eliminado";
								} else {
									var icon = "warning";
									var msg =
										"Error: Verifique que no exista una configuración activa";
								}
								Swal.fire({
									icon: icon,
									title: msg,
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getCabeceras();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el plan",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		try {
			const response = await axios.delete(
				route_confiPresupuesto_base +
					"/deleteCabecera/" +
					servicioId +
					"," +
					localStorage.getItem("idUser"),

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getActiveYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};

	useEffect(() => {
		console.log(establishment);
		getCabeceras();
		getEstructuras();
		getYears();
	}, []);

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Ingrese el nombre del plan presupuestal"),
		year: Yup.number().required("Ingrese el año para el plan presupuestal"),
		estructura: Yup.number().required(
			"Ingrese una estructura para el plan presupuestal"
		),
		estructura2: Yup.number().required(
			"Ingrese una estructura para el plan presupuestal"
		)
	});

	const initialValues = {
		id: "",
		idEstablishment: establishment.id,
		user: localStorage.getItem("idUser"),
		nombre: "",
		year: null,
		estructura: null,
		estado: 0,
		estructura2: null
	};

	const reset = () => {
		setSelectedEstuctura(null);
	};
	return (
		<div className="containerFormUser">
			<h1>Creación de plan presupuestal</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-3  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="nombre"
										id="nombre"
										className="form-control"
										placeholder="Nombre"
									/>
									<label htmlFor="forma" className="form-label">
										Nombre el plan presupuestal
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Estructura Ingreso"
										onChange={(e) => {
											formik.setFieldValue(
												"estructura",
												e.value ? e.value : ""
											);
										}}
										options={nombres.map((rol) => ({
											label: rol.nombre,
											value: rol.id,
										}))}
									/>

									<ErrorMessage
										name="estructura"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Estructura Gasto"
										onChange={(e) => {
											formik.setFieldValue(
												"estructura2",
												e.value ? e.value : ""
											);
										}}
										options={nombres.map((rol) => ({
											label: rol.nombre,
											value: rol.id,
										}))}
									/>

									<ErrorMessage
										name="estructura2"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Año"
										//value={selectedEstuctura}
										onChange={(e) => {
											//setSelectedEstuctura(e.value);
											formik.setFieldValue("year", e.value ? e.value : "");
										}}
										options={years.map((rol) => ({
											label: rol.year,
											value: rol.id,
										}))}
										classNamePrefix="select"
									/>

									<ErrorMessage name="year" component="div" className="error" />
								</div>
							</div>
							<div className="col-md-3  ">
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button
									type="reset"
									ref={btnCancel}
									onClick={reset()}
									className="btn btn-danger"
								>
									Cancelar
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de planes presupuestales</h1>
			{banCarga == 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Año</th>
							<th>Estructura</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{cabeceras.map((cab) => (
							<tr key={cab.id}>
								<td>{cab.nombre}</td>
								<td>{cab.yearObject.year}</td>
								<td>{cab.estructuraObject.nombre}</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									<>
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(cab.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
		</div>
	);
};

export default Estructuras;
