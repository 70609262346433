import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormularioAdmin from "./AdminEstablishment";
import { AuthContext } from "../../context/LogContext";
import {
	route_typeid_list,
	route_establishments_update,
} from "../../../api-routes/RoutesLogin";
import { show_alert } from "../../../functions";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
	idType: Yup.string().required("El tipo de identificación es obligatorio"),
	identification: Yup.string()
		.required("La identificación es obligatoria")
		.matches(/^[0-9]*$/, "La identificación solo puede contener números"),
	name: Yup.string().required("El nombre es obligatorio"),
	phone: Yup.string()
		.required("El teléfono es obligatorio")
		.matches(/^[0-9]*$/, "El teléfono solo puede contener números"),
	phone2: Yup.string().matches(
		/^[0-9]*$/,
		"El teléfono secundario solo puede contener números"
	),
	email: Yup.string()
		.email("Ingrese una dirección de correo electrónico válida")
		.required("El correo electrónico es obligatorio"),
	image: Yup.mixed().notRequired(),
	//password: Yup.string().required("La contraseña es obligatoria"),
	/*password2: Yup.string()
		.oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
		.required("Debe confirmar la contraseña"),*/
	address: Yup.string().required("La dirección es obligatoria"),
});

const Formulario = () => {
	const { establishment, user, getUserByMail, logout, inputStyles } =
		useContext(AuthContext);
	const [userAdmin, setUserAdmin] = useState(false);
	const { getToken } = useContext(AuthContext);
	const [typesId, setTypesId] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const navigate = useNavigate();
	const [btnCarga, setBtnCarga] = useState(0);
	const [institucion, setInstitucion] = useState(false)


	useEffect(() => {
		setInstitucion(establishment.institucion)
		axios
			.get(route_typeid_list, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setTypesId(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
		getUserByMail(user.nombre).then((response) => {
			setUserAdmin(response.admin);
		});
	}, []);

	const initialValues = {
		id: establishment.id,
		idType: establishment.idType,
		identification: establishment.identification,
		name: establishment.name,
		phone: establishment.phone,
		phone2: establishment.phone2,
		email: establishment.email,
		image: null,
		password: "123456",
		password2: "123456",
		address: establishment.address,
		user: localStorage.getItem("idUser"),
	};

	const actualizarEstab = async (json) => {
		const config = {
			headers: {
				//"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.put(route_establishments_update, json, config)
			.then((response) => {
				if (response.data.errorMessage != null) {
					show_alert(response.data.errorMessage, "error");
					return false;
				} else {
					logout();
					Swal.fire({
						icon: "success",
						title:
							"Establecimiento actualizado exitosamente, ingrese nuevamente",
						showConfirmButton: true,
						timer: 3000,
					}).then(() => {
						navigate("/menu/hefestoAdmin/");
					});
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const handleSubmit = (values) => {
		setBtnCarga(1);
		if (selectedImage) {
			values.image = selectedImage;
		}
		values.institucion = institucion
		actualizarEstab(values);
	};
	const handleImageChange = async (event) => {
		const file = event.currentTarget.files[0];

		try {
			const base64Image = await fileToBase64(file);
			setSelectedImage(base64Image);
		} catch (error) {
			console.error("Error converting file to base64:", error);
		}
	};
	const fileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				// Cuando la lectura ha sido completada con éxito, resolvemos la Promesa
				resolve(reader.result);
			};
			reader.onerror = (error) => {
				// En caso de error, rechazamos la Promesa con el error
				reject(error);
			};
			reader.onabort = () => {
				// En caso de abortar la lectura, rechazamos la Promesa con un error
				reject(new Error("File reading aborted"));
			};
			reader.readAsDataURL(file);
			// Manejar el cierre del FileReader cuando ya no es necesario
			return () => {
				reader.abort(); // Abortar la lectura si la Promesa se cancela antes de ser resuelta
			};
		});
	};
	return (
		<div className="containerFormUser">
			<h1>Establecimiento</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isSubmitting }) => (
					<Form>
						<Field
							style={inputStyles}
							type="hidden"
							name="id"
							id="id"
							className="form-control"
							placeholder="Nombre"
						/>
						<div className="row">
							<div className="col-md-12">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="name"
										id="name"
										className="form-control"
										placeholder="Nombre"
									/>
									<label htmlFor="name" className="form-label">
										Nombre
									</label>
									{errors.name && touched.name && (
										<ErrorMessage
											name="name"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										as="select"
										id="idType"
										name="idType"
										className="form-select"
										aria-label="Floating label select example"
									>
										<option selected>Selecciona </option>
										{typesId.map((rol) => (
											<option key={rol.id} value={rol.id}>
												{rol.name}
											</option>
										))}
									</Field>
									<label htmlFor="idType" className="form-label">
										Tipo de identificacion
									</label>
									{errors.idType && touched.idType && (
										<ErrorMessage
											name="idType"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="identification"
										id="identification"
										className="form-control"
										placeholder="Numero de identificacion"
									/>
									<label htmlFor="name" className="form-label">
										Numero de identificacion
									</label>
									{errors.identification && touched.identification && (
										<ErrorMessage
											name="identification"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="phone"
										id="phone"
										className="form-control"
										placeholder="Numero de telefono"
									/>
									<label htmlFor="name" className="form-label">
										Numero de telefono
									</label>
									{errors.phone && touched.phone && (
										<ErrorMessage
											name="phone"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="phone2"
										id="phone2"
										className="form-control"
										placeholder="Numero de telefono secundario"
									/>
									<label htmlFor="phone2" className="form-label">
										Numero de telefono opcional
									</label>
									{errors.phone2 && touched.phone2 && (
										<ErrorMessage
											name="phone2"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="address"
										id="address"
										className="form-control"
										placeholder="Dirección"
									/>
									<label htmlFor="address" className="form-label">
										Dirección
									</label>
									{errors.address && touched.address && (
										<ErrorMessage
											name="address"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="email"
										name="email"
										id="email"
										className="form-control"
										placeholder="Email"
									/>
									<label htmlFor="email" className="form-label">
										Email
									</label>
									{errors.email && touched.email && (
										<ErrorMessage
											name="email"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div>
									<Field
										style={inputStyles}
										type="file"
										id="b64"
										name="b64"
										accept="image/*"
										onChange={handleImageChange}
										className="form-control form-control-lg"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div class="input-group  mb-3">
									<div class="input-group-text">
										<div className="form-check form-switch">
											<input
												className="form-check-input"
												type="checkbox"
												role="switch"
												checked={institucion}
												//name={module.id}
												//id={module.id}
												onChange={(event) => {
													setInstitucion(event.target.checked);
												}}
											></input>
										</div>
									</div>
									<label
										type="text"
										class="form-control"
										aria-label="Text input with checkbox"
									>
										Institución Educativa
									</label>
								</div>
							</div>
							{/* 	<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										type="password"
										name="password"
										id="password"
										className="form-control"
										placeholder="Password"
									/>
									<label htmlFor="password" className="form-label">
										Password
									</label>
									{errors.password && touched.password && (
										<ErrorMessage
											name="password"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										type="password"
										name="password2"
										id="password2"
										className="form-control"
										placeholder="Password"
									/>
									<label htmlFor="password" className="form-label">
										Confirme su Password
									</label>
									{errors.password2 && touched.password2 && (
										<ErrorMessage
											name="password2"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div> */}
						</div>
						<>
							{btnCarga === 0 ? (
								<button type="submit" className="btn btn-primary">
									Actualizar
								</button>
							) : (
								/* spinner carga */
								<button className="btn btn-primary" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										aria-hidden="true"
									></span>
									<span role="status"> Guardando...</span>
								</button>
								/*--------------- */
							)}
							&nbsp;
							<button type="reset" className="btn btn-danger">
								Cancelar
							</button>
						</>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default Formulario;
