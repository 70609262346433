import React, { useEffect, useContext, useState } from "react";
import "../../../styles/administration/LoginEstab.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import {
	route_login_getEstablishmentByIdentification,
	route_establishments_getEstabsxUser,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";

export default function LoginEstab({ user }) {
	const {
		logout,
		getToken,
		setEstablishment,
		setEstablishmentLS,
		customStyles,
		userAct,
		getPermissions,
		setBanVisualizar
	} = useContext(AuthContext);

	const { userId } = useParams();
	const navigate = useNavigate();

	const [listEstabs, setListEstabs] = useState([]);

	const initialValues = {
		identification: "",
		password: "",
	};

	const validationSchema = Yup.object().shape({
		identification: Yup.string()
			.required()
			.typeError("Selecciona un establecimiento"),
	});

	useEffect(() => {
		if (userId != null && userAct && userId == userAct.id) {
			getEstabsxUser(userId);
		}
	}, []);

	
	const functionLogout = () => {
		logout();
		setEstablishment(null);

		navigate("/");
	};

	const handleSubmit = async (values) => {
	
		var msgError = "";
		try {
			console.log(values);
			const response = await axios.post(
				route_login_getEstablishmentByIdentification,
				values
			);
			if (response.data && response.data.licenciaValida==true) {
				var a = response.data;
				console.log(a);
				localStorage.setItem("establishment", JSON.stringify(a));
				setEstablishment(a);
				setBanVisualizar(1)
				getPermissions(userAct);
			
				Swal.fire({
					icon: "success",
					title: "Acceso autorizado a: " + response.data.name,
					text: "Ingrese con un usuario autorizado al establecimiento",
					showConfirmButton: true,
				});
				navigate("/menu");
			}else if(response.data && response.data.licenciaValida==false){
				Swal.fire({
					icon: "warning",
					title: "Establecimiento no autorizado: " + response.data.name,
					text: "El establecimiento no cuenta con una licencia valida",
					showConfirmButton: true,
				});
				navigate("/");
			}else {
				msgError = "Verifique usuario y/o password";
			}
		} catch (error) {
			if (error.code === "ERR_BAD_REQUEST") {
				msgError = "Verifique usuario y/o password";
			} else if (error.code === "ERR_NETWORK") {
				msgError = "Error de red";
			} else {
				msgError = "Error general en log";
			}
		} finally {
		}
	};

	const getEstabsxUser = async (idU) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_establishments_getEstabsxUser}/${idU}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.identification,
			label: item.name,
		}));
		console.log(formattedOptions);
		setListEstabs(formattedOptions);
	};

	return (
		<>
			<div className="containerEstab">
				<h2 className="titleLog">ESTABLECIMIENTO</h2>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{(formik, props) => (
						<Form className="">
							<div className="">
								<div id="" className="">
									<div style={{padding:"20px"}}>
										{/* <div id="login" className="login"> */}
										{/* ------ Select de establecimientos */}
										<div className="row">
											<div className="col-md-12  ">
												<div className="form-floating mb-2  ">
													<Select
														styles={customStyles}
														placeholder="Establecimientos"
														//value={selectedEstab}
														onChange={(e) => {
															formik.setFieldValue(
																"identification",
																e ? e.value : ""
															);
														}}
														options={listEstabs}
														classNamePrefix="select"
													/>
													<ErrorMessage
														name="identification"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>

										<br></br>
										<button
											onClick={() => functionLogout()}
											className="btn btn-danger"
											style={{float:"inline-end"}}
										>
											Salir
										</button>
										<button
											id="btnSubmitEstab"
											type="submit"
											className="btn btn-primary"
										>
											Ingresar
										</button>
										{/* </div> */}

										<div
											id="spinnerEstab"
											style={{ visibility: "hidden" }}
											className="spinner"
										></div>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}
