import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_confiPresupuesto_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const Estructuras = () => {
	const { getToken } = useContext(AuthContext);
	const { establishment, user, inputStyles } = useContext(AuthContext);
	const [nombres, setNombres] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);
	

	const handleSubmit = (values) => {
		setBtnCarga(1);
		saveNombre(values);
	};
	const saveNombre = async (json) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base,
				json,
				config
			);
			const successMessage = "Estructura creada";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getEstructuras();
				setBtnCarga(0);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getEstructuras = async () => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getNombres/${establishment.id}`,
			config
		);
		setNombres(result.data);
		setBanCarga(0)
	};

	const handleEdit = (serviceId) => {
		const nombreDet = nombres.find((p) => p.id === serviceId);
		if (formikRef.current) {
			formikRef.current.setValues(nombreDet);
		}
	};

	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Eliminar estructura?",
			text: "La estructura no estará disponible, y no debe tener configuración!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, false)
							.then((response) => {
								console.log(response);
								if (response) {
									var icon = "success";
									var msg = "Estructura eliminada";
								} else {
									var icon = "warning";
									var msg =
										"Error: Verifique que no exista una configuración activa";
								}
								Swal.fire({
									icon: icon,
									title: msg,
									showConfirmButton: true,
								}).then(() => {
									getEstructuras();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la estructura",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		try {
			const response = await axios.delete(
				route_confiPresupuesto_base +
					"/deleteNombre/" +
					servicioId +
					"," +
					localStorage.getItem("idUser"),

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	};
	useEffect(() => {
		console.log(establishment);
		getEstructuras();
	}, []);

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Ingrese el nombre de la estructura"),
	});

	const initialValues = {
		id: "",
		idEstablishment: establishment.id,
		user: localStorage.getItem("idUser"),
		nombre: "",
	};
	return (
		<div className="containerFormUser">
			<h1>Creación de estructura</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-8  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="nombre"
										id="estructura"
										className="form-control"
										placeholder="Estructura"
									/>
									<label htmlFor="forma" className="form-label">
										Nombre de estructura
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div
								className="col-md-4"
								style={{
									textAlign: "left",
									padding: "10px",
									paddingRight: "30px",
								}}
							>
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button type="reset" ref={btnCancel} className="btn btn-danger">
									Cancelar
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de estructuras</h1>
			{banCarga == 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Nombre</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{nombres.map((servicio) => (
							<tr key={servicio.id}>
								<td>{servicio.nombre}</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									<>
										<button
											className="btnEdit"
											type="button"
											onClick={(e) => handleEdit(servicio.id)}
										>
											<i className="fas fa-pen"></i>
										</button>
										&nbsp;&nbsp;
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(servicio.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
		</div>
	);
};

export default Estructuras;
