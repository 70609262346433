import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useNavigate, useParams, withRouter } from "react-router-dom";
import { parseISO, getMonth, getYear } from "date-fns";
import { Formik, Form, Field, ErrorMessage, useFormik, replace } from "formik";
import { AuthContext } from "../../context/LogContext";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import Select from "react-select";
import * as Yup from "yup";
import {
	route_confiPresupuesto_base,
	route_person_searchPerson,
	route_movimientosPresupuesto,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import { AiOutlineFileSearch } from "react-icons/ai";
import { MdOutlineLibraryAdd } from "react-icons/md";
import Formato1 from "../pdfs/Formato1";
import Swal from "sweetalert2";
import { HiOutlineSaveAs } from "react-icons/hi";
import { isNull } from "util";

export default function NuevoMovimiento(props) {
	const [isLoading, setIsLoading] = useState(false);
	const {
		establishment,
		getToken,
		FechaYMD,
		FechaYMDHMS,
		customStyles,
		dateStyles,
		inputStyles,
	} = useContext(AuthContext);
	const { tipo } = useParams();
	const [optionsPersons, setOptionsPersons] = useState([]);
	const buttonRef = useRef(null);
	const [movimientos, setMovimientos] = useState([]);
	const [movDisminucion, setMovDisminucion] = useState({});
	const [vrReduccion, setVrReduccion] = useState(null);
	const [detalles, setDetalles] = useState([]);
	const [isDisabled, setIsDisabled] = useState(true);
	const [detalleRed, setDetalleRed] = useState("");
	const [disminucionesxMov, setDisminucionesxMov] = useState([]);
	const [movimientoPDF, setMovimientoPDF] = useState({});
	const [tipoMod, setTipoMod] = useState(null);
	const modalRef = useRef();
	const modalRefClose = useRef();
	const [datosR, setDatosR] = useState({});
	const [consecutivoF, setConsecutivoF] = useState(0);
	const [isDisabledR, setIsDisabledR] = useState(true);
	//----- dctos --------------------
	const [vraplicado, setVraplicado] = useState(0);
	const [conceptos, setConceptos] = useState([]);
	const [porcDcto, setPorcDcto] = useState(0);
	const [vr2Dcto, setVr2Dcto] = useState(0);
	const [isDisabledD, setIsDisabledD] = useState(true);
	const [movSelected, setMovSelected] = useState({});
	const [dctoSelected, setDctoSelected] = useState({
		value: null,
		label: "Búsqueda de concepto",
	});
	const [conceptosDcto, setConceptosDcto] = useState([]);
	const [mActivos, setMActivos] = useState([]);
	const [listCtas, setLisCtas] = useState([]);
	const [detalleSelected, setDetalleSelected] = useState(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [listDctosAp, setListaDctosAp] = useState([]);
	const [btnCargaMovs, setBtnCargaMovs] = useState(0);

	//-------------------------------

	useEffect(() => {
		getMesesActivos();
		if (buttonRef.current) {
			buttonRef.current.click();
		}
		if (tipo > 0 && tipo != null) {
			//obtenerMovimientos({ tipo: tipo });
			obtenerReserva(tipo);
		}
	}, [tipo]);

	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const getCurrentMonthDates = () => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);

		return {
			desde: firstDayOfMonth.toISOString().slice(0, 10),
			hasta: lastDayOfMonth.toISOString().slice(0, 10),
		};
	};

	const validationSchema = Yup.object().shape({
		/* tercero: Yup.number().required("Este campo es requerido"),
		desde: Yup.date().required("Este campo es requerido"),
		desde: Yup.date().required("Este campo es requerido"),
		observaciones: Yup.string(), */
	});

	const initialValues = {
		tercero: "",
		desde: getCurrentMonthDates().desde,
		hasta: getCurrentMonthDates().hasta,
		numero: "",
		tipo: tipo,
		establishment: establishment.id,
		estado: 0,
	};

	const initialValuesR = {
		detalle: "",
		fecha: FechaYMD,
		numero: 0,
		establishment: establishment.id,
		crea: localStorage.getItem("idUser"),
		fechacrea: FechaYMDHMS,
	};

	const validationSchemaR = Yup.object().shape({
		fecha: Yup.date().required("Este campo es requerido"),
		detalle: Yup.string().required("Este campo es requerido"),
	});

	const handleSubmit = (values) => {
		obtenerMovimientos(values);
	};

	const handleSubmitR = async (values) => {
		setBtnCarga(1);
		values.tipo = tipo;
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_movimientosPresupuesto + "/saveReserva", values, config)
			.then((response) => {
				if (response.data.errorMessage != null) {
					alert(response.data.errorMessage, "error");
					return false;
				} else {
					Swal.fire({
						icon: "success",
						title: "Documentos reservados exitosamente",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						buttonRef.current.click();
						setBtnCarga(0);
					});
				}
			})
			.catch((error) => {
				setBtnCarga(0);
				console.error(error);
				throw error;
			});
	};

	const obtenerMovimientos = async (values) => {
		setBtnCargaMovs(0);
		setMovimientos([]);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			let url = `${route_movimientosPresupuesto}/listaMovimientos/`;

			if (establishment.id) {
				url += `${establishment.id},`;
			} else {
				url += `null,`;
			}

			if (tipo) {
				url += `${tipo},`;
			} else {
				url += `null,`;
			}

			if (values.tercero) {
				url += `${values.tercero},`;
			} else {
				url += `0,`;
			}

			if (values.numero) {
				url += `${values.numero},`;
			} else {
				url += `-,`;
			}

			if (values.desde) {
				url += `${values.desde},`;
			} else {
				url += `1900-01-01,`;
			}

			if (values.hasta) {
				url += `${values.hasta},`;
			} else {
				url += `3000-01-01,`;
			}
			if(values.estado ){
				url += `${values.estado}`;
			}else{
				url += `0`;
			}
			const response = await axios.get(url, config);
			const data = response.data;
			data.sort((a, b) => b.numero - a.numero);
			data.sort((a, b) => {
				if (a.numero === b.numero) {
					const dateA = new Date(a.fecha);
					const dateB = new Date(b.fecha);
					return dateB - dateA;
				}
				return 0;
			});
			let sumaD = 0;
			let sumaC = 0;
			const newData = data.map((movimiento) => {
				let sumaD = 0;
				let sumaC = 0;

				sumaD = movimiento.disminuciones
					? movimiento.disminuciones
							.filter((disminucion) => disminucion.estado !== 2)
							.reduce((total, disminucion) => total + disminucion.debitos, 0)
					: 0;

				sumaC = movimiento.disminuciones
					? movimiento.disminuciones
							.filter((disminucion) => disminucion.estado !== 2)
							.reduce((total, disminucion) => total + disminucion.creditos, 0)
					: 0;

				return { ...movimiento, sumaD, sumaC };
			});
			
			setMovimientos(newData);
			console.log(newData)
			setBtnCargaMovs(1);
		} catch (error) {
			Swal.fire({
				icon: "warning",
				title: "Error en carga de movimientos",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCargaMovs(1);
			});
		} finally {
			setIsLoading(false);
			setBtnCargaMovs(1);

		}
	};

	const handleDelete = (id) => {
		modalRef.current.click();
		let motivoR = "";
		Swal.fire({
			title: "Esta seguro de anular el movimiento presupuestal?",
			text: "Este proceso es irreversible!",
			icon: "warning",
			input: "text",
			inputLabel: "Motivo de anulación",
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return "Escriba el motivo de anulación";
				} else {
					motivoR = value;
				}
			},
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteMov(id, 2, motivoR)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El movimiento ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									buttonRef.current.click();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el movimiento",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteMov = async (id, estado, motivoR) => {
		let values = {
			id: id,
			estado: estado,
			motivo: motivoR,
			crea: localStorage.getItem("idUser"),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_movimientosPresupuesto + "/deactive",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleReduccion = (mov, tipo) => {
		setMovDisminucion(mov);
		setDisminucionesxMov([]);
		let t = 0;
		// tipo==1 = disminucion
		//tipo = 2 adicion
		if (mov.tipo == 1 && tipo == 1) {
			t = 7;
		} else if (mov.tipo == 1 && tipo == 2) {
			t = 8;
		} else if (mov.tipo == 3 && tipo == 1) {
			t = 9;
		} else if (mov.tipo == 3 && tipo == 2) {
			t = 10;
		} else if (mov.tipo == 4 && tipo == 1) {
			t = 11;
		} else if (mov.tipo == 4 && tipo == 2) {
			t = 12;
		} else if (mov.tipo == 5 && tipo == 1) {
			t = 13;
		} else if (mov.tipo == 5 && tipo == 2) {
			t = 14;
		}
		setTipoMod(t);
		getDisminucionesxid(mov.id, t);
	};

	const handleDetReduccion = (detalle) => {
		if (
			(parseFloat(vrReduccion) >= 0 &&
				vrReduccion <= detalle.cuentaObject.saldo &&
				(tipoMod == 8 || tipoMod == 10)) ||
			(vrReduccion <= detalle.saldo &&
				(tipoMod == 7 || tipoMod == 9 || tipoMod == 11 || tipoMod == 13)) ||
			(detalle.docObject != null &&
				vrReduccion <= detalle.docObject.saldo &&
				(tipoMod == 12 || tipoMod == 14))
		) {
			// Verificar si el detalle ya existe en el array
			const existingIndex = detalles.findIndex(
				(item) => item.id === detalle.id
			);

			if (existingIndex !== -1) {
				// Si el detalle existe, reemplázalo
				detalles[existingIndex] = {
					...detalles[existingIndex],
					ingreso: vrReduccion,
				};
			} else {
				// Si el detalle no existe, agrégalo
				setDetalles([...detalles, { ...detalle, ingreso: vrReduccion }]);
			}
			setIsDisabled(false);
		} else {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Verifique los valores del movimiento",
				showConfirmButton: true,
			});

			if (detalles.length === 0) {
				setIsDisabled(true);
			}
		}
	};

	const handleSaveReduccion = async () => {
		const sumaIngresos = detalles.reduce(
			(total, detalle) => total + detalle.ingreso,
			0
		);
		movDisminucion.detalles = detalles;
		movDisminucion.tipo = tipoMod;
		movDisminucion.id = null;
		movDisminucion.crea = localStorage.getItem("idUser");
		movDisminucion.fechacrea = FechaYMDHMS;
		movDisminucion.detalle = detalleRed;
		movDisminucion.estado = 1;
		movDisminucion.mod = null;
		movDisminucion.fechamod = null;
		if (tipoMod == 7 || tipoMod == 10 || tipoMod == 12) {
			movDisminucion.creditos = sumaIngresos;
			movDisminucion.debitos = 0;
		} else {
			movDisminucion.creditos = 0;
			movDisminucion.debitos = sumaIngresos;
		}

		movDisminucion.fecha = FechaYMD;

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(
				route_movimientosPresupuesto + "/saveMovimiento",
				movDisminucion,
				config
			)
			.then((response) => {
				if (response.data.errorMessage != null) {
					alert(response.data.errorMessage, "error");
					return false;
				} else {
					Swal.fire({
						icon: "success",
						title: "Movimiento registrado ",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						setDetalles([]);
						buttonRef.current.click();
						modalRefClose.current.click();
					});
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const getDisminucionesxid = async (idM, tipo) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			let url = `${route_movimientosPresupuesto}/findMovsDiminucion/${idM},${tipo}`;
			const response = await axios.get(url, config);
			const data = response.data;
			setDisminucionesxMov(data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleNumeroChange = (event, formik) => {
		const inputValue = event.target.value;

		// Validar si el valor ingresado es numérico
		if (!isNaN(inputValue) && inputValue != "" && inputValue > 0) {
			formik.setFieldValue("numero", inputValue);
			setConsecutivoF(parseFloat(consecutivoF) + parseFloat(inputValue));
			setIsDisabledR(false);
		} else {
			formik.setFieldValue("numero", 0);
			setConsecutivoF(datosR.consecutivo);
			setIsDisabledR(true);
		}
	};

	const obtenerReserva = async (tipo) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			let url = `${route_movimientosPresupuesto}/findReserva/${establishment.id},4,${tipo}`;
			const response = await axios.get(url, config);
			const data = response.data;
			setDatosR(data);
			setConsecutivoF(data.consecutivo);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	/* ------ dctos ---------- */
	const handleDctos = (mov) => {
		setMovSelected(mov);
		getConceptosDcto(mov.id);
		var list = [];
		mov.listDetalles.map((cuenta) => {
			var formattedOption = {
				value: cuenta.id,
				label: cuenta.cuentaObject.codigo + " - " + cuenta.cuentaObject.nombre,
				valor: cuenta.credito + cuenta.debito,
			};
			list.push(formattedOption);
		});
		setLisCtas(list);
	};

	const handleChangeConcepto = (selectedOption) => {
		setPorcDcto(selectedOption ? selectedOption.porcentaje : 0);
		if (selectedOption != null) {
			setPorcDcto(selectedOption.porcentaje);
			listCtas.forEach((cta) => {
				calcularDcto(selectedOption.porcentaje, cta.valor, cta.value);
			});
			setDctoSelected(selectedOption);
		} else {
			setListaDctosAp([]);
			//setVr2Dcto(0);
			setIsDisabledD(true);
		}
	};

	const handleChangePorcDcto = (e) => {
		if (e != null && parseFloat(e.target.value) <= 100) {
			setPorcDcto(parseFloat(e.target.value));
			calcularDcto(parseFloat(e.target.value), vraplicado);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique el valor del porcentaje",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setPorcDcto(0);
				setVr2Dcto(0);
				setIsDisabledD(true);
			});
		}
	};

	const handleChangeVraplicado = (base, v, id) => {
		const valor = parseFloat(v.replace(/\./g, "").replace(",", "."));
		if (parseFloat(valor) <= parseFloat(base)) {
			//setVraplicado(isNaN(valor) ? 0 : valor);
			calcularDcto(parseFloat(porcDcto), parseFloat(valor), id);
		} else {
			Swal.fire({
				icon: "warning",
				title: "El valor aplicado es mayor al total de movimiento",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setListaDctosAp((prevList) => {
					const index = prevList.findIndex((item) => item.id === id);
					if (index > -1) {
						// Update the existing item
						const updatedList = [...prevList];
						updatedList[index] = { id: id, base: 0, valor: 0 };
						return updatedList;
					} else {
						// Add the new item
						return [...prevList, { id: id, base: 0, valor: 0 }];
					}
				});
				setIsDisabledD(true);
			});
		}
	};

	const calcularDcto = (porc, vrAplicado, id) => {
		if (
			typeof porc == "number" &&
			porc > 0 &&
			typeof vrAplicado == "number" &&
			vrAplicado > 0
		) {
			const total = vrAplicado * (porc / 100);
			const totalRedondeado = parseFloat(total.toFixed(2));
			const arraR = { id: id, base: vrAplicado, valor: totalRedondeado };
			//setListaDctosAp([...listDctosAp,arraR ])

			setListaDctosAp((prevList) => {
				const index = prevList.findIndex((item) => item.id === arraR.id);
				if (index > -1) {
					// Update the existing item
					const updatedList = [...prevList];
					updatedList[index] = arraR;
					return updatedList;
				} else {
					// Add the new item
					return [...prevList, arraR];
				}
			});

			if (totalRedondeado > 0) {
				setIsDisabledD(false);
			}
		} else {
			setListaDctosAp([]);
			setIsDisabledD(true);
		}
	};
	const handleSubmitDcto = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			// Crear una lista de promesas para todas las solicitudes de guardado
			const promises = listDctosAp.map((element) => {
				const values = {
					dcto: dctoSelected.value,
					porcentaje: porcDcto,
					valorAplicado: element.base,
					valorTotal: element.valor,
					nombre: dctoSelected.label,
					movimiento: movSelected.id,
					detmovimiento: element.id,
					user: 1,
				};

				// Devolver la promesa de la solicitud
				return axios.post(
					route_movimientosPresupuesto + "/saveConceptoD",
					values,
					config
				);
			});

			// Esperar a que todas las solicitudes se completen
			const responses = await Promise.all(promises);

			// Verificar si alguna respuesta contiene un mensaje de error
			const hasError = responses.some(
				(response) => response.data.errorMessage != null
			);

			if (hasError) {
				const errorResponse = responses.find(
					(response) => response.data.errorMessage != null
				);
				alert(errorResponse.data.errorMessage, "error");
			} else {
				Swal.fire({
					icon: "success",
					title: "Concepto aplicado exitosamente",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getConceptosDcto(movSelected.id);
					if (buttonRef.current) {
						buttonRef.current.click();
					}
					setListaDctosAp([]);
				});
			}
		} catch (error) {
			console.error(error);
			alert("Ocurrió un error al guardar los conceptos.", "error");
		}
	};

	const getConceptosDcto = async (movId) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_movimientosPresupuesto}/getConceptos/${movId}`,
			config
		);

		setConceptosDcto(result.data);
	};

	const handleDeleteDcto = (conceptoId) => {
		Swal.fire({
			title: "Eliminar concepto de pago?",
			text: "Este proceso es irreversible!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteServicio(conceptoId);
			}
		});
	};

	const deleteServicio = async (conceptoId) => {
		try {
			const response = await axios.delete(
				route_movimientosPresupuesto +
					"/deleteConcepto/" +
					conceptoId +
					"," +
					localStorage.getItem("idUser"),
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			if (response.data == true) {
				Swal.fire({
					icon: "success",
					title: "El concepto se ha eliminado correctamente",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getConceptosDcto(movSelected.id);
					if (buttonRef.current) {
						buttonRef.current.click();
					}
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Error, Concepto no eliminado",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		} catch (error) {
			throw error;
		}
	};

	const getConceptos = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getConceptos/${establishment.id}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.nombre,
			porcentaje: item.porcentaje,
		}));

		setConceptos(formattedOptions);
	};

	const initialValuesDcto = {
		dcto: null,
		porcentaje: null,
		valorAplicado: null,
		valorTotal: null,
		nombre: "",
		movimiento: null,
		user: localStorage.getItem("idUser"),
		detmovimiento: null,
	};
	/* ---------validacion de periodo de tiempo--------- */
	const getMesesActivos = async () => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_configuration_base}/getMonthsActive/${establishment.id}`,
				config
			);
			setMActivos(result.data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const verificarFecha = (json) => {
		// Obtener el año y mes de la fecha del JSON
		const fechaString = json.fecha;
		const fecha = parseISO(fechaString);
		// Obtener el mes (devuelve un número entre 0 y 11, donde 0 es enero y 11 es diciembre)
		const month = getMonth(fecha) + 1; // Sumamos 1 porque los meses en JavaScript van de 0 a 11
		// Obtener el año
		const year = getYear(fecha);
		// Verificar si el año y el mes están en el array de fechas

		const fechaEnArray = mActivos.find(
			(fecha) => fecha.year == year && fecha.numero == month
		);
		return fechaEnArray ? true : false;
	};

	return (
		<div className="containerFormUser">
			<h1>
				{tipo == 1
					? "Reconocimiento Presupuestal"
					: tipo == 2
					? "Ingreso Presupuestal"
					: tipo == 3
					? "Disponibilidad Presupuestal"
					: tipo == 4
					? "Registro Presupuestal"
					: tipo == 5
					? "Obligación Presupuestal"
					: tipo == 6
					? "Pago"
					: tipo == 15
					? "Adición Presupuestal"
					: tipo == 16
					? "Disminución Presupuestal"
					: tipo == 17
					? "Traslado Presupuestal"
					: tipo == 18
					? "Pago sin afectación presupuestal"
					: null}
			</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/*------------- Bloque 1 --------------------------------*/}
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										isSearchable="true"
										placeholder="Búsqueda de terceros "
										isClearable="true"
										isLoading={isLoading}
										onInputChange={onInputChange}
										options={optionsPersons}
										onChange={(e) => {
											formik.setFieldValue("tercero", e ? e.value : "");
											setOptionsPersons([]);
										}}
									></Select>
									<ErrorMessage
										name="tercero"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="numero"
										id="numero"
										className="form-control"
										placeholder="Numero"
									/>
									<label htmlFor="observaciones" className="form-label">
										Numero
									</label>
									<ErrorMessage
										name="numero"
										component="div"
										className="error"
									/>
								</div>
							</div>

							{/*----------------- Bloque 2 ----------------------------*/}

							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Field
										style={dateStyles}
										type="date"
										name="desde"
										id="desde"
										className="form-control"
										placeholder="Desde "
										onChange={(e) => {
											formik.setFieldValue("desde", e.target.value);
										}}
									/>
									<label htmlFor="desde" className="form-label">
										Desde
									</label>
									<ErrorMessage
										name="desde"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Field
										style={dateStyles}
										type="date"
										name="hasta"
										id="hasta"
										className="form-control"
										placeholder="Hasta "
										onChange={(e) => {
											formik.setFieldValue("hasta", e.target.value);
										}}
									/>
									<label htmlFor="hasta" className="form-label">
										Hasta
									</label>
									<ErrorMessage
										name="hasta"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<Field
								type="hidden"
								id="idEstablishment"
								name="idEstablishment"
							/>
							{/* --------------------------------------------------------- */}
						</div>
						<button ref={buttonRef} type="submit" className="btn btn-success">
							<AiOutlineFileSearch /> Buscar
						</button>
						&nbsp;
						<NavLink
							className="navInfo"
							to={{
								pathname:
									"/menu/hefestoPresupuesto/nuevoMovimiento-Presupuesto/" +
									tipo,
							}}
						>
							<button className="btn btn-primary">
								<MdOutlineLibraryAdd />
								&nbsp;Nuevo
							</button>
						</NavLink>
						&nbsp;
						<button
							className="btn btn-info"
							type="button"
							data-bs-toggle="modal"
							data-bs-target="#modalReserva"
						>
							<HiOutlineSaveAs />
							&nbsp;Reservar docs
						</button>
					</Form>
				)}
			</Formik>
			<h2>Movimientos</h2>
			<div
				style={{
					/*height:"100vh",*/ border: "1px solid #ccc",
					overflow: "auto",
				}}
			>
				<table
					className="table table-striped table-hover"
					style={{ verticalAlign: "bottom", paddingBottom: "1px" }}
				>
					<thead>
						<tr>
							<th style={{ width: "10%", textAlign: "center" }}>Fecha</th>
							<th style={{ width: "15%", textAlign: "center" }}>Número</th>
							<th style={{ width: "35%", textAlign: "center" }}>Tercero</th>
							<th style={{ width: "10%", textAlign: "center" }}>Subtotal</th>
							<th style={{ width: "10%", textAlign: "center" }}>Adiciones</th>
							<th style={{ width: "10%", textAlign: "center" }}>
								Disminuciones
							</th>
							<th style={{ width: "10%", textAlign: "center" }}>Total</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
				</table>
				<div
					style={{
						height: "400px",
						maxHeight: "500px",
						overflowY: "auto",
						paddingTop: "-20px",
						marginTop: "-30px",
					}}
				>
					{btnCargaMovs === 0 ? (
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando...</span>
						</button>
					) : (
						<table id="tableUsers" className=" table-striped table-hover">
							<tbody>
								{movimientos.map((mov) => (
									<tr
										key={mov.id}
										style={{
											backgroundColor:
												mov.estado == 2
													? "rgba(255, 0, 0, 0.5)"
													: "transparent",
										}}
									>
										<td style={{ width: "10%", textAlign: "left" }}>
											{mov.fecha}
										</td>
										<td style={{ width: "15%", textAlign: "left" }}>
											{mov.numero}
										</td>
										<td style={{ width: "35%", textAlign: "left" }}>
											{mov.terceroObject.identification} {" - "}
											{mov.terceroObject.name1} {mov.terceroObject.name2}{" "}
											{mov.terceroObject.lastName1}{" "}
											{mov.terceroObject.lastName2}
											{" - "} {mov.terceroObject.businessName}
										</td>
										<td style={{ width: "10%", textAlign: "right" }}>
											<NumericFormat
												value={
													tipo == 15 || tipo == 16 || tipo == 17
														? mov.debitos
														: mov.debitos + mov.creditos
												} //El número que deseas formatearl número que deseas formatear
												displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
												thousandSeparator="."
												decimalSeparator="," // Agregar separadores de miles
												decimalScale={2} // Especificar el número de decimales
												prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
											/>
										</td>
										<td style={{ width: "10%", textAlign: "right" }}>
											{
												mov.estado != 2 ? (
													<NumericFormat
														value={
															tipo == 1 || tipo == 2 ? mov.sumaD : mov.sumaC
														}
														displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
														thousandSeparator="."
														decimalSeparator="," // Agregar separadores de miles
														decimalScale={2} // Especificar el número de decimales
														prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
													/>
												) : null // O puedes poner un espacio &nbsp; para asegurar el tamaño de la celda
											}
										</td>

										<td style={{ width: "10%", textAlign: "right" }}>
											<NumericFormat
												value={tipo == 1 || tipo == 2 ? mov.sumaC : mov.sumaD}
												displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
												thousandSeparator="."
												decimalSeparator="," // Agregar separadores de miles
												decimalScale={2} // Especificar el número de decimales
												prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
											/>
										</td>
										<td style={{ width: "10%", textAlign: "right" }}>
											<NumericFormat
												value={
													tipo == 15 || tipo == 16 || tipo == 17
														? mov.debitos
														: tipo == 1 || tipo == 2
														? mov.debitos + mov.creditos + mov.sumaD - mov.sumaC
														: mov.debitos + mov.creditos + mov.sumaC - mov.sumaD
												}
												// El número que deseas formatear
												displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
												thousandSeparator="."
												decimalSeparator="," // Agregar separadores de miles
												decimalScale={2} // Especificar el número de decimales
												prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
											/>
										</td>
										<td style={{ width: "20%", textAlign: "right" }}>
											{/* dropdown */}
											<div className="d-flex justify-content-end">
												<button
													className="btn btn-info"
													type="button"
													data-bs-toggle="modal"
													data-bs-target="#modalPdf"
													onClick={() => {
														setMovimientoPDF(mov);
													}}
												>
													<i className="fas fa-file-pdf"></i>
												</button>
												&nbsp;
												{mov.estado != 2 ? (
													<div className="dropdown">
														<button
															className="btn btn-light dropdown-toggle"
															type="button"
															data-bs-toggle="dropdown"
															aria-expanded="false"
															disabled={!verificarFecha(mov)}
														>
															<i className="fas fa-bars"></i>
														</button>
														<ul className="dropdown-menu">
															{tipo == 6 || tipo == 18 ? (
																<button
																	type="button"
																	title="Descuentos"
																	className="dropdown-item"
																	data-bs-toggle="modal"
																	data-bs-target="#modalDctos"
																	onClick={() => {
																		handleDctos(mov);
																		getConceptos();
																	}}
																>
																	<i class="fas fa-funnel-dollar">
																		&nbsp;Descuentos.
																	</i>
																</button>
															) : null}
															{mov.estado == 1 ? (
																<>
																	{mov.listDctos &&
																	mov.listDctos.length == 0 ? (
																		<li>
																			<NavLink
																				className="dropdown-item"
																				to={{
																					pathname:
																						"/menu/hefestoPresupuesto/nuevoMovimiento-Presupuesto/" +
																						tipo +
																						"/" +
																						mov.id,
																				}}
																			>
																				<i className="fas fa-pen">
																					&nbsp;Editar
																				</i>
																			</NavLink>
																		</li>
																	) : (
																		<button
																			className="dropdown-item"
																			onClick={() => {
																				Swal.fire({
																					icon: "warning",
																					title:
																						"Descuentos aplicados  sobre valores existentes",
																					text: "Elimine los descuentos aplicados antes de editar los valores",
																					showConfirmButton: true,
																				});
																			}}
																		>
																			<i className="fas fa-pen">&nbsp;Editar</i>
																		</button>
																	)}
																	<li>
																		<button
																			className="dropdown-item"
																			type="button"
																			onClick={() => handleDelete(mov.id)}
																		>
																			<i className="fas fa-trash-alt">
																				&nbsp;Anular
																			</i>
																		</button>
																	</li>
																</>
															) : null}
															{(mov.tipo == 1 ||
																mov.tipo === 3 ||
																mov.tipo === 4) &&
															/* || mov.tipo === 5 */
															mov.estado == 3 ? (
																<>
																	<li>
																		<button
																			title="Adición Presupuestal"
																			className="dropdown-item"
																			data-bs-toggle="modal"
																			data-bs-target="#modalDisminucion"
																			onClick={() => handleReduccion(mov, 2)}
																		>
																			<i className="fas fa-plus-circle">
																				&nbsp;Adición P.
																			</i>
																		</button>
																	</li>
																	<li>
																		<button
																			title="Disminución Presupuestal"
																			className="dropdown-item"
																			data-bs-toggle="modal"
																			data-bs-target="#modalDisminucion"
																			onClick={() => handleReduccion(mov, 1)}
																		>
																			<i className="fas fa-minus-circle">
																				&nbsp;Disminución P.
																			</i>
																		</button>
																	</li>
																</>
															) : null}
														</ul>
													</div>
												) : null}
											</div>
											{/* -------- */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
			{/* Modal recibo   */}
			<div
				className="modal fade"
				id="modalPdf"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Comprobante
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							<Formato1
								style={{ width: "100%" }}
								establishment={establishment}
								movimiento={movimientoPDF}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* --------------- */}

			{/* Modal disminuciones */}

			<div
				className="modal fade"
				id="modalDisminucion"
				aria-hidden="true"
				aria-labelledby="exampleModalToggleLabel"
				tabindex="-1"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalToggleLabel">
								{tipoMod == 7 || tipoMod == 9 || tipoMod == 11 || tipoMod == 13
									? "Disminución presupuestal"
									: tipoMod == 8 ||
									  tipoMod == 10 ||
									  tipoMod == 12 ||
									  tipoMod == 14
									? "Adición presupuestal"
									: ""}
							</h1>
							<button
								ref={modalRefClose}
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							{movDisminucion.listDetalles &&
								movDisminucion.listDetalles.length > 0 && (
									<table className="table table-hover">
										<tr>
											<td colSpan={4} style={{ width: "100%" }}>
												<input
													style={{ width: "100%" }}
													type="textarea"
													name="detalle"
													id="detalle"
													className="form-control"
													placeholder="Detalle"
													onChange={(e) => {
														setDetalleRed(e.target.value);
													}}
												/>
											</td>
										</tr>
										<tr>
											<th style={{ width: "20%" }}>Cuenta</th>
											<th>Débito</th>
											<th>Crédito</th>
											<th>Saldo</th>
											<th style={{ width: "20%" }}>Valor</th>
										</tr>
										{movDisminucion.listDetalles.map(
											(detalle) =>
												// Agregar una condición para verificar si detalle.saldo > 0
												((detalle.cuentaObject &&
													detalle.cuentaObject.saldo > 0 &&
													(tipoMod == 8 || tipoMod == 10)) ||
													(detalle.saldo > 0 &&
														(tipoMod == 13 ||
															tipoMod == 11 ||
															tipoMod == 7 ||
															tipoMod == 9)) ||
													(detalle.docObject != null &&
														detalle.docObject.saldo > 0 &&
														(tipoMod == 12 || tipoMod == 14))) && (
													<tr key={detalle.id}>
														<td style={{ textAlign: "left" }}>
															{detalle.cuentaObject.codigo}
															{" - "}
															{detalle.cuentaObject.nombre}
														</td>
														<td>
															<NumericFormat
																value={detalle.debito}
																displayType={"text"}
																thousandSeparator="."
																decimalSeparator=","
																decimalScale={2}
																prefix={"$"}
															/>
														</td>
														<td>
															<NumericFormat
																value={detalle.credito}
																displayType={"text"}
																thousandSeparator="."
																decimalSeparator=","
																decimalScale={2}
																prefix={"$"}
															/>
														</td>
														<td>
															<NumericFormat
																value={
																	tipoMod == 7 ||
																	tipoMod == 9 ||
																	tipoMod == 11 ||
																	tipoMod == 13
																		? detalle.saldo
																		: tipoMod == 8 || tipoMod == 10
																		? detalle.cuentaObject.saldo
																		: (tipoMod == 12 || tipoMod == 14) &&
																		  detalle.docObject != null
																		? detalle.docObject.saldo
																		: null
																}
																displayType={"text"}
																thousandSeparator="."
																decimalSeparator=","
																decimalScale={2}
																prefix={"$"}
															/>
														</td>
														<td style={{ width: "20%" }}>
															<NumericFormat
																style={{ textAlign: "right", width: "150px" }}
																className="form-control"
																thousandSeparator="."
																decimalSeparator=","
																onBlur={(e) => {
																	handleDetReduccion(detalle);
																}}
																onChange={(e) => {
																	setVrReduccion(
																		parseFloat(
																			e.target.value
																				.replace(/\./g, "")
																				.replace(",", ".")
																		)
																	);
																}}
															/>
														</td>
													</tr>
												)
										)}
									</table>
								)}
						</div>
						<div className="modal-footer">
							<button
								style={{ float: "right" }}
								className="btn btn-info"
								data-bs-target="#exampleModalToggle2"
								data-bs-toggle="modal"
							>
								<i class="fas fa-list"></i>&nbsp;
								{tipoMod == 7 || tipoMod == 9 || tipoMod == 11 || tipoMod == 13
									? "Disminuciones"
									: tipoMod == 8 ||
									  tipoMod == 10 ||
									  tipoMod == 12 ||
									  tipoMod == 14
									? "Adiciónes"
									: ""}
							</button>
							&nbsp;
							<button
								disabled={isDisabled} // Usamos una expresión para habilitar o deshabilitar el botón
								type="button"
								className="btn btn-primary"
								onClick={(e) => {
									handleSaveReduccion();
								}}
							>
								Guardar
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="exampleModalToggle2"
				aria-hidden="true"
				aria-labelledby="exampleModalToggleLabel2"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
								{tipoMod == 7 || tipoMod == 9 || tipoMod == 11 || tipoMod == 13
									? "Disminuciones ejecutadas"
									: tipoMod == 8 ||
									  tipoMod == 10 ||
									  tipoMod == 12 ||
									  tipoMod == 14
									? "Adiciónes ejecutadas"
									: ""}
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={modalRef}
							></button>
						</div>
						<div className="modal-body">
							{/* lista de reducciones */}
							<div
								style={{
									/*height:"100vh",*/ border: "1px solid #ccc",
									overflow: "auto",
								}}
							>
								<table className="table table-striped table-hover">
									<thead>
										<tr>
											<th style={{ width: "10%", textAlign: "center" }}>
												Fecha
											</th>
											<th style={{ width: "15%", textAlign: "center" }}>
												Número
											</th>
											<th style={{ width: "35%", textAlign: "center" }}>
												Tercero
											</th>
											<th style={{ width: "10%", textAlign: "center" }}>
												Débitos
											</th>
											<th style={{ width: "10%", textAlign: "center" }}>
												Créditos
											</th>
											<th style={{ width: "20%", textAlign: "right" }}>
												Opciones
											</th>
										</tr>
									</thead>
								</table>
								<div style={{ maxHeight: "200px", overflowY: "auto" }}>
									<table className="table table-striped table-hover">
										<tbody>
											{disminucionesxMov.map((mov) => (
												<tr
													key={mov.id}
													style={{
														backgroundColor:
															mov.estado === 2
																? "rgba(255, 0, 0, 0.5)"
																: "transparent",
													}}
												>
													<td style={{ width: "10%", textAlign: "left" }}>
														{mov.fecha}
													</td>
													<td style={{ width: "15%", textAlign: "left" }}>
														{mov.numero}
													</td>
													<td style={{ width: "35%", textAlign: "left" }}>
														{mov.terceroObject.identification} {" - "}
														{mov.terceroObject.name1} {mov.terceroObject.name2}{" "}
														{mov.terceroObject.lastName1}{" "}
														{mov.terceroObject.lastName2}
													</td>
													<td style={{ width: "10%", textAlign: "right" }}>
														<NumericFormat
															value={mov.debitos} // El número que deseas formatear
															displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
															thousandSeparator="."
															decimalSeparator="," // Agregar separadores de miles
															decimalScale={2} // Especificar el número de decimales
															prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
														/>
													</td>
													<td style={{ width: "10%", textAlign: "right" }}>
														<NumericFormat
															value={mov.creditos} // El número que deseas formatear
															displayType={"text"} // Puedes usar 'input' si deseas que sea un campo de entrada
															thousandSeparator="."
															decimalSeparator="," // Agregar separadores de miles
															decimalScale={2} // Especificar el número de decimales
															prefix={"$"} // Agregar un prefijo, como el símbolo de la moneda
														/>
													</td>
													<td style={{ width: "20%", textAlign: "right" }}>
														<button
															className="btnDetail"
															type="button"
															data-bs-toggle="modal"
															data-bs-target="#modalPdf"
															onClick={() => setMovimientoPDF(mov)}
														>
															<i className="fas fa-file-pdf"></i>
														</button>
														&nbsp;
														{mov.estado == 1 ? (
															<>
																{/* <NavLink
																	className="navInfo"
																	to={{
																		pathname:
																			"/menu/hefestoPresupuesto/nuevoMovimiento-Presupuesto/" +
																			tipo +
																			"/" +
																			mov.id,
																	}}
																>
																	<button className="btnEdit" type="button">
																		<i className="fas fa-pen"></i>
																	</button>
																</NavLink>
																&nbsp; */}
																<button
																	className="btnDelete"
																	type="button"
																	onClick={() => handleDelete(mov.id)}
																>
																	<i className="fas fa-trash-alt"></i>
																</button>
															</>
														) : null}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							{/* -------------------- */}
						</div>
						<div className="modal-footer">
							<button
								className="btn btn-primary"
								data-bs-target="#modalDisminucion"
								data-bs-toggle="modal"
							>
								{tipoMod == 7 || tipoMod == 9 || tipoMod == 11 || tipoMod == 13
									? "Nueva Disminución"
									: tipoMod == 8 ||
									  tipoMod == 10 ||
									  tipoMod == 12 ||
									  tipoMod == 14
									? "Nueva Adición"
									: ""}
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* --------------- */}

			{/* -------- modal reserva de documentos */}
			<div
				className="modal fade"
				id="modalReserva"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Reserva de documentos
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							{/* ----------------- formuario reserva----------- */}
							<Formik
								initialValues={initialValuesR}
								validationSchema={validationSchemaR}
								onSubmit={handleSubmitR}
							>
								{(formik, props) => (
									<Form>
										<div className="row">
											{/*------------- Bloque 1 --------------------------------*/}

											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														type="date"
														name="fecha"
														id="fecha"
														className="form-control"
														placeholder="Fecha "
													/>
													<label htmlFor="fecha" className="form-label">
														Fecha
													</label>
													<ErrorMessage
														name="fecha"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-6  ">
												<div className="form-floating mb-2">
													<Field
														type="number"
														name="numero"
														id="numero"
														className="form-control"
														placeholder="Numero de documentos "
														onBlur={(e) => handleNumeroChange(e, formik)} // Asegúrate de tener setFieldValue disponible
													/>
													<label htmlFor="fecha" className="form-label">
														Número de documentos
													</label>
													<ErrorMessage
														name="numero"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-12  ">
												<div className="form-floating mb-2  ">
													<Field
														type="text"
														name="detalle"
														id="detalle"
														className="form-control"
														placeholder="Detalle"
													/>
													<label htmlFor="detalle" className="form-label">
														Detalle
													</label>
													<ErrorMessage
														name="detalle"
														component="div"
														className="error"
													/>
												</div>
											</div>

											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														type="text"
														value={datosR.consecutivo}
														className="form-control"
														placeholder="Numero"
													/>
													<label className="form-label">Número actual</label>
												</div>
											</div>
											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														value={consecutivoF}
														type="text"
														className="form-control"
														placeholder="Numero"
													/>
													<label className="form-label">Número final</label>
												</div>
											</div>

											<Field
												type="hidden"
												id="idEstablishment"
												name="idEstablishment"
											/>
											{/* --------------------------------------------------------- */}
										</div>
										{btnCarga === 0 ? (
											<button
												disabled={isDisabledR}
												type="submit"
												className="btn btn-primary"
											>
												<AiOutlineFileSearch /> Reservar
											</button>
										) : (
											/* spinner carga */
											<button
												className="btn btn-primary"
												type="button"
												disabled
											>
												<span
													className="spinner-border spinner-border-sm"
													aria-hidden="true"
												></span>
												<span role="status"> Guardando...</span>
											</button>
											/*--------------- */
										)}
									</Form>
								)}
							</Formik>
							{/* ---------------------------------------------- */}
						</div>
					</div>
				</div>
			</div>
			{/* -------------------------------------- */}
			{/* ---------- modal descuentos --------------*/}
			<div
				className="modal fade"
				id="modalDctos"
				aria-hidden="true"
				aria-labelledby="exampleModalToggleLabel"
				tabindex="-1"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalToggleLabel">
								Descuentos
							</h1>
							<button
								//ref={modalRefClose}
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<table
								style={{ width: "100%" }}
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<tr>
									<th>Rubro</th>
									<th>
										<Select
											value={dctoSelected}
											styles={customStyles}
											isSearchable="true"
											placeholder="Concepto "
											isClearable="true"
											options={conceptos}
											onChange={(e) => {
												handleChangeConcepto(e);
												setDctoSelected(e);
												//formik.setFieldValue("dcto", e ? e.value : null);
											}}
										></Select>
									</th>
									<th>Porcentaje</th>
									<th>Base</th>
									<th>Total</th>
								</tr>
								{listCtas.map((cta) => {
									return (
										<>
											<tr>
												{/*------------- Bloque 1 --------------------------------*/}
												<td name={"cta" + cta.value} id={"cta" + cta.value}>
													{cta.label}
												</td>
												<td>{dctoSelected ? dctoSelected.label : null}</td>
												<td>
													<NumericFormat
														value={porcDcto}
														style={{ ...inputStyles, textAlign: "right" }}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														placeholder="Porcentaje"
														onChange={handleChangePorcDcto}
														readOnly
													/>
												</td>
												<td>
													<NumericFormat
														value={
															listDctosAp.find((item) => item.id === cta.value)
																?.base || 0
														}
														style={{ ...inputStyles, textAlign: "right" }}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														onBlur={(e) =>
															handleChangeVraplicado(
																cta.valor,
																e.target.value,
																cta.value
															)
														}
													/>
												</td>
												<td>
													<NumericFormat
														value={
															listDctosAp.find((item) => item.id === cta.value)
																?.valor || 0
														}
														id={"vr" + cta}
														style={{ ...inputStyles, textAlign: "right" }}
														className="form-control"
														thousandSeparator="."
														decimalSeparator=","
														readOnly
													/>
												</td>
											</tr>
										</>
									);
								})}
							</table>
							<button
								disabled={isDisabledD}
								className="btn btn-primary"
								onClick={handleSubmitDcto}
								//style={{ width: "100%", height: "50px" }}
							>
								Aplicar
							</button>
							<div
								style={{
									marginTop: "10px",
									height: "350px",
									overflowY: "scroll",
								}}
							>
								<h2>Conceptos aplicados</h2>
								<table
									style={{ width: "100%" }}
									id="tableUsers"
									className="table table-striped table-hover"
								>
									<thead>
										<tr>
											<th style={{ width: "50%", textAlign: "center" }}>
												Nombre
											</th>
											<th style={{ width: "10%", textAlign: "right" }}>
												Porcentaje
											</th>
											<th style={{ width: "10%", textAlign: "right" }}>Base</th>
											<th style={{ width: "10%", textAlign: "right" }}>
												Total
											</th>
											<th style={{ width: "20%", textAlign: "right" }}>
												Opciones
											</th>
										</tr>
									</thead>
									<tbody>
										{conceptosDcto.map((concepto) => (
											<tr key={concepto.id}>
												<td>{concepto.nombre}</td>
												<td style={{ textAlign: "right" }}>
													{concepto.porcentaje.toLocaleString()}
												</td>
												<td style={{ textAlign: "right" }}>
													{concepto.valorAplicado.toLocaleString()}
												</td>
												<td style={{ textAlign: "right" }}>
													{concepto.valorTotal.toLocaleString()}
												</td>
												<td style={{ width: "20%", textAlign: "right" }}>
													<>
														<button
															className="btnDelete"
															type="button"
															onClick={() => handleDeleteDcto(concepto.id)}
														>
															<i className="fas fa-trash-alt"></i>
														</button>
													</>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
