import React, { useState, useContext, useEffect } from "react";
import "../../../styles/administration/NavBar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/react-fontawesome";
import "bootstrap/dist/js/bootstrap.bundle";
import  Login  from "./Login";
import { NavLink } from "react-router-dom";
import logo from "../../../images/logo.png";
import { AuthContext } from "../../context/LogContext";
import InfoLogin from "../home/InfoLogin";
import  LoginEstab  from "../home/LoginEstab";
import { useNavigate } from "react-router-dom";
import { show_alert } from "../../../functions";

export default function NavBar() {
	const {
		isAuthenticated,
		setEstablishment,
		getEstablishment,
		user,
		getUserByMail,
		establishment,
		logout,
	} = useContext(AuthContext);
	const navigate = useNavigate();
	const functionLogout = () => {
		logout();
		setEstablishment(null);
		navigate("/");
	};
	const [userObject, setUserObject] = useState(null);

	const timer = () =>
		setTimeout(() => {
			show_alert(
				"El usuario registrado no tiene permisos para el establecimiento actual",
				"error"
			);
			functionLogout();
		});

	useEffect(() => {
		setEstablishment(getEstablishment());
		if (isAuthenticated) {
			getUserByMail(user.nombre).then((response) => {
				setUserObject(response);

				if (response && response.status === false) {
					timer();
				} else if (response && response.status !== false) {
					localStorage.setItem("idUser", response.id);
				}
			});
		}
	}, [isAuthenticated, InfoLogin]);

	//---------------------------------------------------------------------------------
	return (
		<>
	{/* 	BARRA PRINCIPAL */}
			{/* <nav className="navbar" id="">
				<NavLink to="/home" href="#">
					<img id="logo" src={logo} alt="logo" />
				</NavLink>
				<ul>
					<li>
						<a href="#">
							<i className="fas fa-home"></i>
							Nosotros
						</a>
						<ul>
							<li>
								<NavLink to="us">Quiénes somos</NavLink>
							</li>
							<li>
								<NavLink to="mission">Misión</NavLink>
							</li>
							<li>
								<NavLink to="vision">Visión</NavLink>
							</li>
						</ul>
					</li>
					<li>
						<NavLink to="services">
							<i className="fas fa-handshake"></i>
							Servicios
						</NavLink>
					</li>
					<li>
						<NavLink to="contacts">
							<i className="fas fa-phone-volume"></i>
							Contactos
						</NavLink>
					</li>
				</ul>

				  {establishment ? (
					<>
						<div id="">
							{!isAuthenticated ? (
								<button
									className="btnLogs1"
									data-bs-toggle="modal"
									data-bs-target="#loginModal"
								>
									<i className="fas fa-person-booth"></i>
								</button>
							) : (
								<>
									<button
										className="btnLogs1 "
										type="button"
										data-bs-toggle="offcanvas"
										data-bs-target="#offcanvasRight"
										aria-controls="offcanvasRight"
									>
										<i className="fas fa-user-check"></i>
									</button>
								</>
							)}
						</div>
					</>
				) : (
					<div id="divLogEstab">
						<button
							className="btnLogs1"
							data-bs-toggle="modal"
							data-bs-target="#loginEstabModal"
						>
							<i className="fas fa-laptop-house"></i>
						</button>
					</div>
				)}  
			</nav> */}
			{/* Div que contiene login */}
			<div
				className="modal fade"
				id="loginEstabModal"
				tabIndex="-1"
				aria-labelledby="loginEstabModal"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div id="modalInvisible" className="modal-content">
						<LoginEstab />
					</div>
				</div>
			</div>
			{/* Div que contiene login */}
			<div
				className="modal fade"
				id="loginModal"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div id="modalInvisible" className="modal-content">
						<Login />
					</div>
				</div>
			</div>

			<div
				className="offcanvas offcanvas-end"
				tabIndex="-1"
				id="offcanvasRight"
				aria-labelledby="offcanvasRightLabel"
			>
				<div className="offcanvas-header">
					<h5 className="offcanvas-title" id="offcanvasRightLabel">
						Gestión de usuario
					</h5>
					<button
						id="btnCloseLogInfo"
						type="button"
						className="btn-close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>

				<div id="Loginfo" className="offcanvas-body">
					{userObject ? (
						<InfoLogin user={userObject} />
					) : (
						<center>
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</center>
					)}
				</div>
			</div>
		</>
	);
}
