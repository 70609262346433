import React, { useEffect, useContext } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import { FaSchool } from "react-icons/fa";

export default function Settings() {
	const { userAct, establishment } = useContext(AuthContext);
	const navigate = useNavigate();
	useEffect(() => {
		navigate("/menu/hefestoAdmin");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink to="register-establishment">
									<li className="navInfoAdmin">
										<i className="fas fa-building"></i>
										<span className="spanName"> Establecimientos</span>
									</li>
								</NavLink>
								<NavLink to="register-roles">
									<li className="navInfoAdmin">
										<i className="fas fa-user-tag"></i>
										<span className="spanName"> Roles</span>
									</li>
								</NavLink>
								<NavLink to="register-permissions">
									<li className="navInfoAdmin">
										<i className="fas fa-check"></i>
										<span className="spanName">Permisos</span>
									</li>
								</NavLink>
								{userAct && userAct.admin == true ? (
									<NavLink to="admin-establishment">
										<li className="navInfoAdmin">
											<i className="fas fa-building"></i>
											<span className="spanName">Admin Estabs</span>
										</li>
									</NavLink>
								) : null}
								{establishment && establishment.institucion == true ? (
									<NavLink to="crear-instituciones">
										<li className="navInfoAdmin">
											<FaSchool />
											<span className="spanName">Crear Instituciones</span>
										</li>
									</NavLink>
								) : null}
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
