import React from "react";
import { NavBarAdmin } from "../administration/home/NavBarAdmin";

export default function HomeAAA() {
	return (
		<div>
			<NavBarAdmin  module={4} />
		</div>
	);
}
