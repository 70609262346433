import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import { route_gym_suscripciones } from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";


import TableSuscripciones from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const [isLoading, setIsLoading] = useState(false);
	const [fechaSelected, setFechaSelected] = useState(null);

	const { establishment, getToken, meses, years,FechaYMDHMS } = useContext(AuthContext);
	const [dataPersons, setDataPersons] = useState([]);

	const columns = [
		{ Header: "Usuario", accessor: "tercero" },
		{ Header: "Plan", accessor: "plan" },
		{ Header: "Fecha inicial", accessor: "fechaIni" },
		{ Header: "Fecha final", accessor: "fechaFin" },
		{ Header: "Valor", accessor: "valor" },
		{ Header: "Pagos", accessor: "pagos" },
		{ Header: "Saldo", accessor: "saldo" },
		{ Header: "Estado", accessor: "estado" },
		// ...otras columnas
	];
	const visualizarInforme = async () => {
		if (fechaSelected != null) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_gym_suscripciones}/informeVencimiento/${establishment.id},${fechaSelected}`,
				config
			);
			console.log(result.data);
			setDataPersons(result.data);
		} else {
            Swal.fire({
				icon: "warning",
				title: "Seleccione una fecha",
				showConfirmButton: true,
			})

		}
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de vencimientos</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-9">
						<div className=" input-group input-group-lg">
							<span class="input-group-text" id="inputGroup-sizing-lg">
								Suscripciones activas con vencimiento a:
							</span>
							<input
								type="date"
								class="form-control"
								aria-label="Sizing example input"
								aria-describedby="inputGroup-sizing-lg"
								onChange={(e) => {
									setFechaSelected(e.target.value);
								}}
							></input>
						</div>
					</div>

					<div className="col-md-3">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					<TableSuscripciones
						data={dataPersons}
						columns={columns}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: "Vencimientos",
							imagen: establishment.image,
							fechas: "Vencimientos a: "+fechaSelected,
							generado: "Informe generado: "+FechaYMDHMS
	
						}}
					/>
				</div>
			</div>
		</>
	);
}
