import React from "react";
import "../../../styles/administration/Presentacion1.css";

import Login from "../home/Login";
import Footer from "../home/Footer";
import Barra1 from '../home/Barra1'

const TuComponente = () => {
	return (
		<>
			<Barra1/>
			 <div className="contenedorPantalla">
			
				<div className="div70">
					<div className="background-image"></div>
				</div>
				<div className="div30">
					<Login />
				</div>
			</div> 
			<Footer />
		</>
	);
};

export default TuComponente;
