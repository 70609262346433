import React from "react";
import "./../../../styles/users/Users.css";
import { NavLink, Outlet } from "react-router-dom";

export default function Users() {
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="search-users">
									<li className="navInfoAdmin">
										<i className="fas fa-search"></i> <span> Busqueda</span>
									</li>
								</NavLink>
								<NavLink className="navInfo" to="register-users">
									<li className="navInfoAdmin">
										<i className="fas fa-user-plus"></i> <span> Registro</span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
