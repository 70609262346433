import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { FaHandHoldingUsd } from "react-icons/fa";
export default function SuscripcionesGym() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoGym");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="register-pagoGym">
									<li className="navInfoAdmin">
										<FaHandHoldingUsd /> <span> Pago</span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
