import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import { route_gym_suscripciones } from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";

import TableIngresos from "../../componentsBase/tables/TableBaseSum1";

export default function InformacionUsuarios() {
	const [isLoading, setIsLoading] = useState(false);
	const [fechaIni, setFechaIni] = useState(null);
	const [fechaFin, setFechaFin] = useState(null);

	const { establishment, getToken, meses, years,FechaYMDHMS } = useContext(AuthContext);
	const [data, setData] = useState([]);


	const columns = [
		{ Header: "No.", accessor: "id" },
		{ Header: "Usuario", accessor: "usuario" },
		{ Header: "Plan", accessor: "plan" },
		{ Header: "Fecha pago", accessor: "fecha" },
		{ Header: "Forma de pago", accessor: "formaPago" },
		{ Header: "Usuario recibe", accessor: "usuPago" },
		{ Header: "Valor", accessor: "valor" },
	];

	const sumFields = ["valor"];


	const visualizarInforme = async () => {
		if (fechaIni != null && fechaFin != null ) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_gym_suscripciones}/informePagos/${establishment.id},${fechaIni},${fechaFin},true`,
				config
			);
			setData(result.data);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Seleccione las fechas del periodo a consultar",
				showConfirmButton: true,
			});
		}
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de ingresos</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-5">
						<div className=" input-group input-group-lg">
							<span class="input-group-text" id="inputGroup-sizing-lg">
								Ingresos desde:
							</span>
							<input
								type="date"
								class="form-control"
								aria-label="Sizing example input"
								aria-describedby="inputGroup-sizing-lg"
								onChange={(e) => {
									setFechaIni(e.target.value);
								}}
							></input>
                           
						</div>
					</div>
                    <div className="col-md-5">
						<div className=" input-group input-group-lg">
							<span class="input-group-text" id="inputGroup-sizing-lg">
								Hasta:
							</span>
							<input
								type="date"
								class="form-control"
								aria-label="Sizing example input"
								aria-describedby="inputGroup-sizing-lg"
								onChange={(e) => {
									setFechaFin(e.target.value);
								}}
							></input>
                           
						</div>
					</div>

					<div className="col-md-2">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					<TableIngresos
						data={data}
						columns={columns}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: "Ingresos",
							imagen: establishment.image,
							fechas: "Ingresos de: "+fechaIni+" a: "+fechaFin  ,
							generado: "Informe generado: "+FechaYMDHMS
	
						}}
						sumFields={sumFields}
					/>
				</div>
			</div>
		</>
	);
}
