import React, { useMemo } from "react";
import {
	useTable,
	useSortBy,
	useGroupBy,
	usePagination,
	useFilters,
} from "react-table";
import * as XLSX from "xlsx";
import { BsFiletypePdf } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import numeral from "numeral";
import "../../../styles/tables/table1.css";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // Importa saveAs desde file-saver

const MyTable = ({ data, columns, encabezado, sumFields }) => {
	const exportToPDF = () => {
		const unit = "pt";
		const size = "legal";
		const orientation = "landscape";

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(12);

		// Agregar la imagen al encabezado
		if (encabezado.imagen) {
			doc.addImage(encabezado.imagen, "png", marginLeft, 30, 100, 100); // Ajusta las coordenadas y dimensiones según tu necesidad
		}

		const title = encabezado.titulo;
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		// Agregar el título al encabezado
		doc.text(encabezado.empresa, marginLeft + 120, 40);
		doc.text(encabezado.nit, marginLeft + 120, 60);
		doc.text( encabezado.titulo, marginLeft + 120, 80);
		doc.text(encabezado.fechas, marginLeft + 120, 100);
		doc.text(encabezado.generado, marginLeft + 120, 120);

		doc.autoTable({
			startY: 140,
			head: [headers],
			body: dataRows,
			styles: {
				fontSize: 10,
				cellPadding: 3,
				cellWidth: "auto",
				halign: "left",
				valign: "middle",
			},
		});

		doc.save(encabezado.titulo + ".pdf");
	};

	const exportToExcel = async () => {
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		const result = data.map((item) => {
			const row = {};
			columns.forEach((header) => {
				const fieldName = header.accessor;
				const headerName = header.Header;
				row[headerName] = item[fieldName];
			});
			return row;
		});

		// Crear un nuevo libro de Excel
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("Sheet 1");

		// Establecer el título en la celda A1
		const titleCell = worksheet.getCell("D2");
		titleCell.value = encabezado.empresa;
		titleCell.font = { size: 12, bold: true };
		titleCell.alignment = { vertical: "middle", horizontal: "left" };

		// Establecer otras celdas de encabezado
		const nitCell = worksheet.getCell("D3");
		nitCell.value = encabezado.nit;
		nitCell.font = { size: 9, bold: false };

		const informeCell = worksheet.getCell("D4");
		informeCell.value = encabezado.titulo;
		informeCell.font = { size: 12, bold: true };

		const fechasCell = worksheet.getCell("D5");
		fechasCell.value = encabezado.fechas;
		fechasCell.font = { size: 9, bold: false };

		const generadoCell = worksheet.getCell("D6");
		generadoCell.value = encabezado.generado;
		generadoCell.font = { size: 9, bold: false };

		// Insertar la imagen (si informe.imagen es una URL válida)
		if (encabezado.imagen) {
			const imageId = workbook.addImage({
				base64: encabezado.imagen,
				extension: "png",
			});

			worksheet.addImage(imageId, "A1:B6"); // Ajusta el rango según tu necesidad
		}
		// generamos dos filas de espaio en blanco
		const espacioCell = worksheet.getCell("D7");
		//generadoCell.value = "";
		const espacioCell2 = worksheet.getCell("D8");
		//generadoCell.value = "";

		columns.forEach((column, index) => {
			worksheet.getColumn(index + 1).width = 12; // Index + 1 porque el índice de las columnas comienza en 1
		});

		// Insertar el encabezado de las columnas en la fila 8 (después de las celdas de encabezado)
		const headerRow = worksheet.addRow(headers);
		// Establecer bordes delgados y fondo de color azul claro para las celdas de encabezado
		headerRow.eachCell((cell) => {
			cell.width = 50;
			cell.font = { size: 10, bold: true };
			cell.alignment = { vertical: "middle", horizontal: "center" };
			cell.border = {
				top: { style: "thin" },
				left: { style: "thin" },
				bottom: { style: "thin" },
				right: { style: "thin" },
			};
			cell.fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "83a9f0" }, // Código de color para azul claro
			};
		});

		// Insertar los datos a partir de la fila 9
		result.forEach((row) => worksheet.addRow(Object.values(row)));

		// Guardar el libro de Excel como un archivo
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		saveAs(blob, encabezado.titulo + ".xlsx");
	};
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		rows,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setFilter, // Agregamos la función setFilter para establecer los filtros
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 20 }, // Mostrar 1 dato por página
		},
		useFilters, // Agregamos el hook de filtros
		useGroupBy,
		useSortBy,
		usePagination
	);

	const sumatorias = useMemo(() => {
		const sums = {};
		sumFields.forEach((field) => {
			sums[field] = 0;
		});

		rows.forEach((row) => {
			sumFields.forEach((field) => {
				sums[field] += row.values[field];
			});
		});

		return sums;
	}, [rows, sumFields]);

	const handleFilterChange = (columnId, event) => {
		const value = event.target.value || undefined;
		setFilter(columnId, value);
	};
	function formatNumber(number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-start" }}>
				<button
					className="btnInfo"
					onClick={exportToExcel}
					title="Generar Excel"
				>
					<FaFileExcel size={20} />
				</button>
				&nbsp;
				<button className="btnInfo" onClick={exportToPDF} title="Generar PDF">
					<BsFiletypePdf size={20} />
				</button>
			</div>
			<div>
				<table className="reactTables" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
										{/* Agregamos un campo de filtro para cada columna */}
										<div>
											{column.canFilter ? (
												<input
													type="text"
													value={column.filterValue || ""}
													onChange={(e) => handleFilterChange(column.id, e)}
												/>
											) : null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => (
										<td
											style={{
												backgroundColor: row.original.color,
												textAlign:
													typeof cell.value === "number" ? "right" : "left", // Alineación derecha para números, izquierda para otros tipos
											}}
											{...cell.getCellProps()}
										>
											{typeof cell.value === "number"
												? formatNumber(cell.value)
												: cell.render("Cell")}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							{columns.map((column) => {
								if (sumFields.includes(column.accessor)) {
									return (
										<td style={{textAlign:"right"}} key={column.accessor}>
											$ {column.Header}:
											<br />
											{numeral(sumatorias[column.accessor]).format("0,0")}
											{/* Formatear el valor */}
										</td>
									);
								}
								return <td style={{textAlign:"left"}} key={column.accessor}></td>;
							})}
						</tr>
					</tfoot>
				</table>
				<div>
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{"<<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{"<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{">"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{">>"}
					</button>
					&nbsp;
					<span>
						Página{" "}
						<strong>
							{pageIndex + 1} de {pageOptions.length}
						</strong>{" "}
					</span>
				</div>
			</div>
		</>
	);
};

export default MyTable;
