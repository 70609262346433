import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_informesPresupuesto,
	route_confiPresupuesto_base,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";

import TableIngresos from "../../componentsBase/tables/TableBase1";
import zhCN from "date-fns/locale/zh-CN/index.js";

export default function InformacionUsuarios() {
	//const { naturaleza } = useParams();
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [planp, setPlanp] = useState(null);
	const [year, setYear] = useState(null);
	const { establishment, getToken, FechaYMDHMS } = useContext(AuthContext);
	const [data, setData] = useState([]);
	const [years, setYears] = useState([]);
	const [columnas, setColumnas] = useState([]);
	const [informeSelected, setInformeSelected] = useState(null);
	const [desde, setDesde] = useState("");
	const [hasta, setHasta] = useState("");
	const [tituloInforme, setTituloInforme] = useState("");
	const [listInformes, setListInformes] = useState([]);
	const [naturaleza, setNaturaleza] = useState(null);
	const [banCarga, setBanCarga] = useState(0);

	const meses = [{}];

	const customStyles = {
		control: (base) => ({
			...base,
		}),
		option: (base) => ({
			...base,
			textAlign: `left`,
			border: `1px dotted black`,
			height: 35,
			minHeight: 20,
		}),
	};

	const sumFields = [
		"apropiacioni",
		"adiciones",
		"disminuciones",
		"apropiacionf",
		"reconocimientos",
		"reconocimientossa",
		"ingresos",
		"saldo",
	];
	useEffect(() => {
		var informes = [];
		var options = [
			{
				label: "Ingresos",
				options: [
					{ value: 1, label: "Ejecución presupuestal de ingresos" },
					{
						value: 2,
						label: "Ejecución presupuestal de ingresos semestral y acumulada",
					},
					{ value: 3, label: "Relación de ingresos" },
					{ value: 7, label: "Modificaciones al presupuesto de ingresos" },
					{ value: 9, label: "Relación semestral de ingresos" },
				],
			},
			{
				label: "Gastos",
				options: [
					{ value: 4, label: "Ejecucion presupuestal de gastos" },
					{
						value: 5,
						label: "Ejecucion presupuestal de gastos semestral y acumulada",
					},
					{ value: 6, label: "Relación de compromisos" },
					{
						value: 8,
						label: "Modificaciones al presupuesto de gastos",
					},
					{ value: 10, label: "Relación semestral de compromisos" },
					{ value: 11, label: "Relación de pagos" },
					{ value: 12, label: "Relación mensual de pagos" },
					{ value: 13, label: "Relación mensual de pagos sin afectación pptal" },
					{ value: 14, label: "Relación semestral de pagos sin afectación pptal" },
				],
			},
		];

		setListInformes(options);
		getCabecerasP(0);
		getYears();
	}, []);

	function asignarNaturaleza(informe) {
		if ([1, 2, 3, 7, 9].includes(informe)) {
			setNaturaleza(1);
		} else if ([4, 5, 6, 8, 10, 11,12,13,14].includes(informe)) {
			setNaturaleza(2);
		} else {
			setInformeSelected(null);
		}
	}

	const visualizarInforme = async () => {
		setBanCarga(1);
		let columns = [];
		if (
			planp != null &&
			informeSelected != null &&
			desde != "" &&
			hasta != ""
		) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			var route = "";
			if (informeSelected == 3 || informeSelected == 9) {
				route = `${route_informesPresupuesto}/detallemovs/${planp},2,${desde},${hasta}`;
			} else if (informeSelected == 6 || informeSelected == 10) {
				route = `${route_informesPresupuesto}/registros/${planp},${desde},${hasta}`;
			} else if (informeSelected == 7 || informeSelected == 8) {
				route = `${route_informesPresupuesto}/modificaciones/${planp},${naturaleza},${desde},${hasta}`;
			} else if (informeSelected == 11 || informeSelected == 12 || informeSelected == 13 || informeSelected == 14 ) {
				var tipoDet=6;
				if (informeSelected==13 || informeSelected==14 ){tipoDet=18;}
				route = `${route_informesPresupuesto}/detallepagos/${planp},${desde},${hasta},${tipoDet}`;
			} else {
				route = `${route_informesPresupuesto}/ejecuciones/${establishment.id},${planp},${naturaleza},1,${desde},${hasta}`;
			}
			const result = await axios.get(route, config);
			console.log(result.data);
			const filtrados = result.data.filter(
				(objeto) => objeto.tipo === "Detalle"
			);
			const dataConColor = filtrados.map((item) => {
				const color = item.sia == false && item.tipo=='Detalle' ? "#FFC0CB" : "";
				return { ...item, color };
			});
			setData(dataConColor);
			if (informeSelected == 1) {
				setTituloInforme("EJECUCIÓN PRESUPUESTAL DE INGRESOS");
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Nombre Rubro Presupuestal", accessor: "nombre" },
					{ Header: "Presupuesto Inicial", accessor: "apropiacioni" },
					{ Header: "Adiciones", accessor: "adiciones" },
					{ Header: "Reducciones", accessor: "disminuciones" },
					{ Header: "Recaudos", accessor: "ingresos" },
				];
			} else if (informeSelected == 2) {
				setTituloInforme(
					"EJECUCIÓN PRESUPUESTAL DE INGRESOS SEMESTRAL Y ACUMULADA"
				);
				var mes = obtenerMes(hasta);
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Nombre Rubro Presupuestal", accessor: "nombre" },
					{ Header: "Presupuesto Inicial", accessor: "apropiacioni" },
					{ Header: "Adiciones Periodo", accessor: "adiciones" },
					{ Header: "Adiciones Acumuladas", accessor: "adicionesacum" },
					{ Header: "Reducciones Periodo", accessor: "disminuciones" },
					{ Header: "Reducciones Acumuladas", accessor: "disminucionesacum" },
					{ Header: "Recaudos Periodo", accessor: "ingresos" },
					{ Header: "Recaudos Acumulados", accessor: "ingresosacum" },
					{ Header: "Ultimo Mes Del Semestral Reportado", accessor: "mes" },
				];
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes);
			} else if (informeSelected == 3) {
				setTituloInforme("RELACIÓN DE INGRESOS");
				columns = [
					{ Header: "Codigo Presupuestal", accessor: "codigoSia" },
					{ Header: "Fecha De Recaudo", accessor: "fecha" },
					{ Header: "Numero De Recibo", accessor: "numero" },
					{ Header: "Recibido De", accessor: "tercero" },
					{ Header: "Concepto Recaudo", accessor: "detalle" },
					{ Header: "Valor", accessor: "valor" },
					{ Header: "No.Cuenta Bancaria Destino", accessor: "ctadestino" },
					{ Header: "Banco", accessor: "codbanco" },
				];
			} else if (informeSelected == 4) {
				setTituloInforme("EJECUCIÓN PRESUPUESTAL DE GASTOS");
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Nombre Rubro Presupuestal", accessor: "nombre" },
					{ Header: "Código Del Programa", accessor: "" },
					{ Header: "Apropiación Inicial", accessor: "apropiacioni" },
					{ Header: "Credito", accessor: "trasladosCre" },
					{ Header: "Contracréditos", accessor: "trasladosDeb" },
					{ Header: "Aplazamientos", accessor: "" },
					{ Header: "Desaplazamientos", accessor: "" },
					{ Header: "Reducciones", accessor: "disminuciones" },
					{ Header: "Adiciones", accessor: "adiciones" },
					{
						Header: "Compromisos Registro Presupuestal",
						accessor: "registros",
					},
					{ Header: "Obligaciones", accessor: "obligaciones" },
					{ Header: "Pagos", accessor: "pagos" },
				];
			} else if (informeSelected == 5) {
				setTituloInforme(
					"EJECUCIÓN PRESUPUESTAL DE GASTOS SEMESTRAL Y ACUMULADA"
				);
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Nombre Rubro Presupuestal", accessor: "nombre" },
					{ Header: "Apropiación Inicial", accessor: "apropiacioni" },
					{ Header: "Credito Periodo", accessor: "trasladosCre" },
					{ Header: "Creditos Acumulados", accessor: "trasladosacumCre" },
					{ Header: "Contracredito Periodo", accessor: "trasladosDeb" },
					{ Header: "Contracredito Acumulados", accessor: "trasladoacumDeb" },
					{ Header: "Aplazamientos Periodo", accessor: "" },
					{ Header: "Aplazamientos Acumulados", accessor: "" },
					{ Header: "Desaplazamientos Periodo", accessor: "" },
					{ Header: "Desaplazamientos Acumulados", accessor: "" },
					{ Header: "Reducciones Periodo", accessor: "disminuciones" },
					{ Header: "Reducciones Acumuladas", accessor: "disminucionesacum" },
					{ Header: "Adiciones Periodo", accessor: "adiciones" },
					{ Header: "Adiciones Acumulados", accessor: "adicionesacum" },
					{
						Header: "Compromisos Registro Presupuestal Periodo",
						accessor: "registros",
					},
					{ Header: "Compromisos Acumulados", accessor: "registrosacum" },
					{ Header: "Pagos Periodo", accessor: "pagos" },
					{ Header: "Pagos Acumulados", accessor: "pagosacum" },
					{ Header: "Ultimo Mes Del Periodo Reportado", accessor: "mes" },
				];
				var mes = obtenerMes(hasta);
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes);
			} else if (informeSelected == 6) {
				setTituloInforme("RELACIÓN DE COMPROMISOS");
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Nombre Rubro Presupuestal", accessor: "nombre" },
					{ Header: "Número Del Cdp", accessor: "numerocdp" },
					{ Header: "Fecha Del Cdp", accessor: "fechacdp" },
					{ Header: "Valor Del Cdp", accessor: "valorcdp" },
					{ Header: "Fecha De Registro Presupuestal", accessor: "fecha" },
					{ Header: "Valor Del Registro Presupuestal", accessor: "valor" },
					{ Header: "Beneficiario", accessor: "tercero" },
					{ Header: "Cedula O Nit", accessor: "identificacion" },
					{ Header: "Detalle Del Compromiso", accessor: "detalle" },
				];
			} else if (informeSelected == 7) {
				setTituloInforme("MODIFICACIONES AL PRESUPUESTO DE INGRESOS");
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Acto administrativo", accessor: "noacto" },
					{ Header: "Fecha", accessor: "fecha" },
					{ Header: "Adiciones", accessor: "adiciondb" },
					{ Header: "Reducciones", accessor: "disminuciondb" },
				];
			} else if (informeSelected == 8) {
				setTituloInforme("MODIFICACIONES AL PRESUPUESTO DE GASTOS");
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Acto administrativo", accessor: "noacto" },
					{ Header: "Fecha", accessor: "fecha" },
					{ Header: "Adiciones", accessor: "adicioncr" },
					{ Header: "Reducciones", accessor: "disminucioncr" },
					{ Header: "Credito", accessor: "creditotra" },
					{ Header: "Debito", accessor: "debitotra" },
					{ Header: "Aplazamiento", accessor: "" },
					{ Header: "Desaplazamiento", accessor: "" },
				];
			} else if (informeSelected == 9) {
				setTituloInforme("RELACIÓN SEMESTRAL DE INGRESOS");
				columns = [
					{ Header: "Codigo Presupuestal", accessor: "codigoSia" },
					{ Header: "Fecha De Recaudo", accessor: "fecha" },
					{ Header: "Ultimo Mes Del Periodo Reportado", accessor: "mes" },
					{ Header: "Numero De Recibo", accessor: "numero" },
					{ Header: "Recibido De", accessor: "tercero" },
					{ Header: "Concepto Recaudo", accessor: "detalle" },
					{ Header: "Valor", accessor: "valor" },
					{ Header: "No.Cuenta Bancaria Destino", accessor: "ctadestino" },
					{ Header: "Banco", accessor: "codbanco" },
				];
				var mes = obtenerMes(hasta);
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes);
			} else if (informeSelected == 10) {
				setTituloInforme("RELACIÓN SEMESTRAL DE COMPROMISOS");
				columns = [
					{ Header: "Código Rubro Presupuestal", accessor: "codigoSia" },
					{ Header: "Nombre Rubro Presupuestal", accessor: "nombre" },
					{ Header: "Número Del Cdp", accessor: "numerocdp" },
					{ Header: "Fecha Del Cdp", accessor: "fechacdp" },
					{ Header: "Valor Del Cdp", accessor: "valorcdp" },
					{ Header: "Ultimo Mes Del Periodo Reportado", accessor: "mes" },
					{ Header: "Fecha De Registro Presupuestal", accessor: "fecha" },
					{ Header: "Valor Del Registro Presupuestal", accessor: "valor" },
					{ Header: "Beneficiario", accessor: "tercero" },
					{ Header: "Cedula O Nit", accessor: "identificacion" },
					{ Header: "Detalle Del Compromiso", accessor: "detalle" },
				];
				var mes = obtenerMes(hasta);
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes);
			} else if (informeSelected == 11) {
				setTituloInforme("RELACIÓN DE PAGOS");
				columns = [
					{ Header: "Fecha De Pago", accessor: "fecha" },
					{ Header: "Código Presupuestal", accessor: "codigoSia" },
					{ Header: "Tipo De Pago", accessor: "tpagocod" },
					{ Header: "Fuente De Financiación", accessor: "ffincod" },
					{ Header: "No. De Comprobante", accessor: "numero" },
					{ Header: "Beneficiario", accessor: "tercero" },
					{ Header: "Cedula O Nit", accessor: "identificacion" },
					{ Header: "Detalle De Pago", accessor: "detalle" },
					{ Header: "Valor Comprobante De Pago", accessor: "valor" },
					{ Header: "Descuentos Seg. Social", accessor: "dctosegsocial" },
					{ Header: "Descuentos Retenciones", accessor: "dctosretenciones" },
					{ Header: "Otros Descuentos", accessor: "dctosotros" },
					{ Header: "Neto Pagado", accessor: "netopagar" },
					{ Header: "Banco", accessor: "codbanco" },
					{ Header: "No. De Cuenta", accessor: "cuenta" },
					{ Header: "No. De Cheque O Nd", accessor: "" },
				];
				/* var mes = obtenerMes(hasta);
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes); */
			} else if (informeSelected == 12) {
				setTituloInforme("RELACIÓN MENSUAL DE PAGOS");
				columns = [
					{ Header: "Fecha De Pago", accessor: "fecha" },
					{ Header: "Ultimo Mes Del Periodo Reportado", accessor: "mes" },
					{ Header: "Código Presupuestal", accessor: "codigoSia" },
					{ Header: "Tipo De Pago", accessor: "tpagocod" },
					{ Header: "Fuente De Financiación", accessor: "ffincod" },
					{ Header: "No. De Comprobante", accessor: "numero" },
					{ Header: "Beneficiario", accessor: "tercero" },
					{ Header: "Cedula O Nit", accessor: "identificacion" },
					{ Header: "Detalle De Pago", accessor: "detalle" },
					{ Header: "Valor Comprobante De Pago", accessor: "valor" },
					{ Header: "Descuentos Seg. Social", accessor: "dctosegsocial" },
					{ Header: "Descuentos Retenciones", accessor: "dctosretenciones" },
					{ Header: "Otros Descuentos", accessor: "dctosotros" },
					{ Header: "Neto Pagado", accessor: "netopagar" },
					{ Header: "Banco", accessor: "codbanco" },
					{ Header: "No. De Cuenta", accessor: "cuenta" },
					{ Header: "No. De Cheque O Nd", accessor: "" },
				];
				var mes = obtenerMes(hasta);
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes); 
			} else if (informeSelected == 13) {
				setTituloInforme("RELACIÓN DE PAGOS SIN AFECTACIÓN PPTAL");
				columns = [
					{ Header: "Fecha De Pago", accessor: "fecha" },
					{ Header: "No. De Comprobante", accessor: "numero" },
					{ Header: "Beneficiario", accessor: "tercero" },
					{ Header: "Cedula O Nit", accessor: "identificacion" },
					{ Header: "Detalle De Pago", accessor: "detalle" },
					{ Header: "Valor Comprobante De Pago", accessor: "valor" },
					{ Header: "Descuentos", accessor: "dctostotal" },
					{ Header: "Neto Pagado", accessor: "netopagar" },
					{ Header: "Banco", accessor: "codbanco" },
					{ Header: "No. De Cuenta", accessor: "cuenta" },
					{ Header: "No. De Cheque O Nd", accessor: "" },
					
				];
				setData(dataConColor); 
			} else if (informeSelected == 14) {
				setTituloInforme("RELACIÓN SEMESTRAL DE PAGOS SIN AFECTACIÓN PPTAL.");
				columns = [
					{ Header: "Fecha De Pago", accessor: "fecha" },
					{ Header: "Ultimo Mes Del Periodo Reportado", accessor: "mes" },
					{ Header: "No. De Comprobante", accessor: "numero" },
					{ Header: "Beneficiario", accessor: "tercero" },
					{ Header: "Cedula O Nit", accessor: "identificacion" },
					{ Header: "Detalle De Pago", accessor: "detalle" },
					{ Header: "Valor Comprobante De Pago", accessor: "valor" },
					{ Header: "Descuentos", accessor: "dctostotal" },
					{ Header: "Neto Pagado", accessor: "netopagar" },
					{ Header: "Banco", accessor: "codbanco" },
					{ Header: "No. De Cuenta", accessor: "cuenta" },
					{ Header: "No. De Cheque O Nd", accessor: "" },
					
				];
				var mes = obtenerMes(hasta);
				const dataConMes = dataConColor.map((obj) => ({ ...obj, mes: mes }));
				setData(dataConMes);
			}
			alertSinSia(result.data);
			setColumnas(columns);
			setBanCarga(0);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los datos",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0);
		}
	};

	function alertSinSia(data) {
		const ctaSinSia = data.filter((objeto) => objeto.sia == false && objeto.tipo=='Detalle' );
		if (ctaSinSia.length > 0) {
			const listItems = ctaSinSia.map(
				(item, index) =>
					`<li key=${index} style="text-align: left;">Código: ${item.codigo}, Nombre: ${item.nombre}</li>`
			);
			Swal.fire({
				icon: "warning",
				title: "Cuentas sin homologar SIA",
				html: `<ul  >${listItems.join("")}</ul>`,
				showConfirmButton: true,
				// timer: 1500,
			});
		}
	}
	const obtenerMes = (fecha) => {
		const meses = [
			"ENERO",
			"FEBRERO",
			"MARZO",
			"ABRIL",
			"MAYO",
			"JUNIO",
			"JULIO",
			"AGOSTO",
			"SEPTIEMBRE",
			"OCTUBRE",
			"NOVIEMBRE",
			"DICIEMBRE",
		];

		// Validar el formato de la fecha
		const regexFecha = /^\d{4}-\d{2}-\d{2}$/;
		if (!regexFecha.test(fecha)) {
			return "Formato de fecha inválido";
		}

		// Obtener el mes a partir de la fecha
		const fechaObj = new Date(fecha + "T00:00:00"); // Añadir la hora para evitar el problema de zona horaria
		const numeroMes = fechaObj.getMonth();

		// Validar que el número de mes esté en un rango válido
		if (numeroMes >= 0 && numeroMes <= 11) {
			return meses[numeroMes];
		} else {
			return "Mes inválido";
		}
	};

	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			console.log(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`
			);
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));
			console.log(formattedOptions);

			setCabecerasPlanP(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de ejecuciones SIA</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-6">
						<Select
							styles={customStyles}
							isSearchable="true"
							placeholder="Plan presupuestal"
							isClearable="true"
							options={cabecerasPlanP}
							onChange={(e) => {
								if (e) {
									setPlanp(e.value);
								} else {
									setPlanp(null);
								}
							}}
						></Select>
					</div>

					<div className="col-md-6">
						<Select
							styles={customStyles}
							placeholder="Informe"
							onChange={(e) => {
								if (e) {
									setInformeSelected(e.value);
									asignarNaturaleza(e.value);
								} else {
									setInformeSelected(null);
									asignarNaturaleza(null);
								}
							}}
							options={listInformes}
							classNamePrefix="select"
						/>
					</div>
				</div>

				<div style={{ marginTop: "10px" }} className="row">
					<div className="col-md-6">
						<div className="form-floating  mb-2  ">
							<input
								style={{ fontSize: "15px", height: "50px" }}
								type="date"
								name="desde"
								id="desde"
								value={desde}
								className="form-control"
								placeholder="Desde "
								onChange={(e) => {
									if (e) {
										setDesde(e.target.value);
									} else {
										setDesde("");
									}
								}}
							/>
							<label
								style={{ fontSize: "15px", zIndex:"0" }}
								htmlFor="desde"
								className="form-label"
							>
								Desde
							</label>
						</div>
					</div>
					<div className="col-md-5">
						<div className="form-floating mb-2  ">
							<input
								style={{ fontSize: "15px", height: "50px" }}
								type="date"
								name="hasta"
								id="hasta"
								value={hasta}
								className="form-control"
								placeholder="Hasta "
								onChange={(e) => {
									if (e) {
										setHasta(e.target.value);
									} else {
										setHasta("");
									}
								}}
							/>
							<label style={{zIndex:"0"}} htmlFor="hasta" className="form-label">
								Hasta
							</label>
						</div>
					</div>
					<div className="col-md-1">
						<button
							style={{ height: "45px" }}
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={30} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
							data={data}
							columns={columnas}
							encabezado={{
								empresa: establishment.name,
								nit: establishment.identification,
								titulo: tituloInforme,
								imagen: establishment.image,
								fechas: "Periodo: " + desde + " hasta: " + hasta,
								generado: "Fecha generación: " + FechaYMDHMS,
							}}
						/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
}
