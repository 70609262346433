import React from 'react'

export default function Footer() {
  return (
    <>
    <footer className="site-footer">
    <p>&copy; 2023 Hefesto. Todos los derechos reservados.</p>
    <ul className="redes-sociales">
        <li>
            <a
                href="https://www.facebook.com/TuPaginaDeFacebook"
                target="_blank" rel="noopener noreferrer"
            >
                <i className="fab fa-facebook" />
            </a>
        </li>
        <li>
            <a href="https://twitter.com/TuCuentaDeTwitter" target="_blank" rel="noopener noreferrer" >
                <i className="fab fa-twitter" />
            </a>
        </li>
        <li>
            <a
                href="https://www.instagram.com/TuCuentaDeInstagram/"
                target="_blank" rel="noopener noreferrer"
            >
                <i className="fab fa-instagram" />
            </a>
        </li>
    </ul>
</footer>
</>
  )
}
