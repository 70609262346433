import React, { useContext, useState } from "react";
import "../../../styles/administration/Login.css";
import "../../../styles/administration/LoginEstab.css";
import "../../../styles/administration/Menu1.css";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_users_login } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";

export default function Login() {
	const navigate = useNavigate();
	const [btnIng, setBtnIng] = useState("block");
	const [vdisplay, setVdisplay] = useState("none");
	const [errorText, setErrorText] = useState("none");
	const [errorMsj, setErrorMsj] = useState("none");

	const initialValues = {
		email: "",
		password: "",
	};

	const {
		Functionlogin,
		logout,
		setEstablishment,
		getUserByMail,
		setUserAct,
	} = useContext(AuthContext);

	function mostrarError(mensaje) {
		setErrorText(mensaje);
		setErrorMsj("block");
	}

	function ocultarError() {
		setErrorMsj("none");
	}
	const mostrarSpinner = () => {
		setVdisplay("block");
		ocultarButton();
	};

	const ocultarSpinner = () => {
		setVdisplay("none");
		mostrarButton();
	};
	const mostrarButton = () => {
		setBtnIng("block");
	};

	const ocultarButton = () => {
		setBtnIng("none");
	};

	const functionLogout = () => {
		logout();
		setEstablishment(null);
		//document.getElementById("btnClose").click();
		//btn.click();
		navigate("/");
	};

	const handleSubmit = async (values) => {
		ocultarError();
		mostrarSpinner();
		console.log(values)
		try {
			const response = await axios.post(route_users_login, values);
			console.log(response)
			const token = response.data.jwt;
			var user = Functionlogin(token);
			try {
				var userO = await getUserByMail(user.sub);
			} catch (error) {
				console.error("Hubo un error al obtener el usuario:", error);
			}
			if (userO) {
				//var btn = document.getElementById("btnClose");
				//btn.click();
				//ocultarSpinner()
				setUserAct(userO);
				navigate("/register-estab/" + userO.id);
			}

			//navigate("/menu");
		} catch (error) {
			
			var msgError = "";
			if (error.code === "ERR_BAD_REQUEST") {
				msgError = "Verifique usuario y/o password";
			} else if (error.code === "ERR_NETWORK") {
				msgError = "Error de red";
			} else {
				msgError = "Error general en log";
			}
			mostrarError(msgError);
		} finally {
			ocultarSpinner(); // Ocultar spinner después de recibir la respuesta
		}
	};
	const validate = (values) => {
		const errors = {};

		if (!values.email) {
			errors.email = "El nombre de usuario es requerido";
		}

		if (!values.password) {
			errors.password = "La contraseña es requerida";
		}
		// else if (values.password.length < 3) {
		// 	errors.password = "La contraseña debe tener al menos 8 caracteres";
		// }

		return errors;
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validate}
			>
				{({ errors, touched }) => (
					<Form className="formLogin">
						<div className="containerd">
							<div className="screen">
								<div className="screen__content">
									<h2 className="titleLog">Usuario </h2>
									{/* <button
										id="btnClose"
										//type="button"
										//data-bs-dismiss="modal"
										aria-label="Close"
										style={{visibility:"none"}}
									></button> */}
									<div className="login">
										<div className="login__field">
											<Field
												className="inputLogin login__input"
												id="email"
												name="email"
												placeholder="Email"
											></Field>
											{errors.email && touched.email && (
												<div className="errorLogin">{errors.email}</div>
											)}
										</div>
										<div className="login__field">
											<i className="login__icon fas fa-lock"></i>
											<Field
												className="inputLogin login__input"
												type="password"
												id="password"
												name="password"
												placeholder="Password"
											></Field>
											{errors.password && touched.password && (
												<div className="errorLogin">{errors.password}</div>
											)}
										</div>
										<button
											id="btnSubmit"
											type="submit"
											className=" login__submit"
											style={{ display: btnIng }}
										>
											Ingresar
										</button>
										<center>
											<a
												href="/home"
												style={{ color: "red", fontSize: "10px" }}
											>
												Reestablecer Contraseña
											</a>
										</center>
									</div>
									<center>
										<span
											id="error-msg"
											className="errorLogin"
											style={{ display: errorMsj }}
										>
											{errorText}
										</span>
									</center>
									<div
										id="spinner"
										className="spinner"
										style={{ display: vdisplay }}
									></div>
								</div>

								<div className="screen__background">
									<span className="screen__background__shape screen__background__shape4"></span>
									<span className="screen__background__shape screen__background__shape3"></span>
									<span className="screen__background__shape screen__background__shape2"></span>
									<span className="screen__background__shape screen__background__shape1"></span>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
