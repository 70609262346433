import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import {
	route_gym_suscripciones,
	route_person_searchPerson,
} from "../../../api-routes/RoutesLogin";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import SuscripcionPDF from '../pdfs/SuscripcionPDF';

import "moment/locale/es";

const SearchPersons = (props) => {
	const { establishment, getToken } = useContext(AuthContext);
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [suscripciones, setSuscripciones] = useState([]);
	const { suscripcionId } = useParams();
	const [suscripcionSelected, setSuscripcionSelected]=useState({});
	const getSuscripciones = async (idT) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const result = await axios.get(
				`${route_gym_suscripciones}/getByUser/${idT}`,
				config
			);

			setSuscripciones(result.data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		console.log(establishment)
		verificarVigencias(establishment.id);
		if (suscripcionId) {
			getSuscripciones(suscripcionId);
		}
	}, [verificarVigencias]);

	/* Busqueda de terceros  */
	const onInputChange = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != ""  && inputValue.length >= 3) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson  + establishment.id+","+ inputValue + ",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const filterChange = (filterValue) => {
		if (filterValue) {
			setOptionsPersons([]);
			getSuscripciones(filterValue);
		} else {
			setOptionsPersons([]);
		}
	};

	const handleDelete = (id, tercero) => {
		Swal.fire({
			title: "Eliminar suscripción?",
			text: "La suscripción estará inactiva, este proceso es irreversible ",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteSuscripcion(id, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "La suscripción ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getSuscripciones(tercero);
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la suscripcion",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteSuscripcion = async (id, status) => {
		let values = {
			id: id,
			active: status,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_gym_suscripciones + "/deactive",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleEstadoPausa = (id, tercero) => {
		Swal.fire({
			title: "Pausar suscripción?",
			text:
				"La suscripción estará inactiva, recuerde hacer el proceso de reactivación ",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Pausando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						pausarSuscripcion(id, 3)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "La suscripción ha sido pausada",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getSuscripciones(tercero);
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la suscripcion",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const handleEstadoPlay = (id, tercero) => {
		Swal.fire({
			title: "Reactivar suscripción?",
			text: "La suscripción estará activa nuevamente ",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Pausando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						pausarSuscripcion(id, 1)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "La suscripción ha sido reactivada",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getSuscripciones(tercero);
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la suscripcion",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const pausarSuscripcion = async (id, estado) => {
		let values = {
			id: id,
			estado: estado,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_gym_suscripciones + "/estados",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};
	const verificarVigencias = async (idE) => {
		let values = {
			idEstablishment: idE,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_gym_suscripciones + "/vencidas",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			console.log(response);
			return response;
		} catch (error) {
			throw error;
		}
	};

	return (
		<>
			<div className="containerFormUser">
				<h1>Lista de suscripciones</h1>

				<Select
					isSearchable="true"
					placeholder="Búsqueda de terceros "
					isClearable="true"
					isLoading={isLoading}
					onInputChange={onInputChange}
					options={optionsPersons}
					onChange={(e) => {
						filterChange(e ? e.value : "");
					}}
				></Select>
				<br></br>
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Usuario</th>
							<th>Plan</th>
							<th>Periodo</th>
							<th>Estado</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{suscripciones.map((sus) => {
							let estado, colorC;
							if (sus.estado === 1) {
								estado = "Activo";
								colorC = "green";
							} else if (sus.estado === 2) {
								estado = "Vencido";
								colorC = "red";
							} else if (sus.estado === 3) {
								estado = "Pausa";
								colorC = "blue";
							}

							return (
								<tr key={sus.id}>
									<td>
										{sus.terceroObject.name1} {sus.terceroObject.name2}{" "}
										{sus.terceroObject.lastName1} {sus.terceroObject.lastName2}
									</td>
									<td>{sus.planObject.plan}</td>
									<td>
										{sus.fechaIni} / {sus.fechaFin}
									</td>
									<td style={{ color: colorC }}>{estado}</td>
									<td style={{ width: "20%", textAlign: "right" }}>
										<button
											className="btnDetail"
											type="button"
											data-bs-toggle="modal"
											data-bs-target="#modalPdf"
											onClick={()=>{
												setSuscripcionSelected(sus)}}
										>
											<i class="fas fa-file-pdf"></i>
										</button>

										{sus.estado != 2 ? (
											<>
												{sus.estado === 3 ? (
													<>
														&nbsp;&nbsp;
														<button
															className="btnEdit"
															type="button"
															title="Reactivar"
															onClick={() =>
																handleEstadoPlay(sus.id, sus.tercero)
															}
														>
															<i className="fas fa-play"></i>
														</button>
													</>
												) : (
													<>
														&nbsp;&nbsp;
														<button
															className="btnEdit"
															type="button"
															title="Pausar"
															onClick={() =>
																handleEstadoPausa(sus.id, sus.tercero)
															}
														>
															<i className="fas fa-pause"></i>
														</button>
													</>
												)}
												&nbsp;&nbsp;
												<button
													className="btnDelete"
													type="button"
													title="Anular"
													onClick={() => handleDelete(sus.id, sus.tercero)}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</>
										) : (
											<></>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{/* modal info de suscripcion */}
			{/* Modal de pago   */}
			<div
				className="modal fade"
				id="modalPdf"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div  class="modal-dialog modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Suscripción
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body"><SuscripcionPDF style={{width:"100%"}} establishment={establishment} suscripcion={suscripcionSelected} /></div>
					</div>
				</div>
			</div>
			{/* --------------- */}

			{/* ///////////////////////////// */}
		</>
	);
};

export default SearchPersons;
